import { graphQLConnectionHandler } from '@core/graphql/graphQLConnectionHandler';
import { FETCH_ADMIN_USER_ASSESSMENT_CERTIFICATES } from '@core/graphql/queries/assessment';
import { LOAD_USER_COURSE_ENROLLMENT_SUMMARY } from '@core/graphql/queries/enrollment';
import { CardMembership, CloudDownload, Delete } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { Button, CardMedia, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, Typography } from "@mui/material";
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getRefactoredFilename, showUserNotification } from 'utils';

const rowKeyStytle = { fontWeight: 'bold' };
const rowValueStytle = {};

const UserCourseEnrollmentDetailsModal = (props) => {
    const [courseInfo, setCourseInfo] = useState({});
    const [enrollmentInfo, setEnrollmentInfo] = useState({});
    const [userInfo, setUserInfo] = useState({});
    const [certificateStatus, setCertificateStatus] = useState('NONE');
    const [certificateDetails, setCertificateDetails] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        let gqlVaribles = {
            variables: {
                course_uuid: props.course_uuid,
                username: props.user.username
            }
        };
        let mergedRequest = LOAD_USER_COURSE_ENROLLMENT_SUMMARY(gqlVaribles);
        graphQLConnectionHandler(dispatch, mergedRequest, (error, res) => {
            if (error || res.userCourseEnrollmentDetails == null) {
                showUserNotification("Fail to user course enrollment summary details", "ERROR");
            } else {
                let user = res.userCourseEnrollmentDetails
                if (user?.enrollments?.length > 0) {
                    setEnrollmentInfo(user.enrollments[0]);
                    setCourseInfo(user?.enrollments[0]?.course);
                }
                delete user.enrollments;
                setUserInfo(user);
            }
        });

    }, []);

    const loadUserAssessmentCertificate = () => {
        setCertificateStatus('PENDING')
        let gqlVaribles = {
            variables: {
                course_uuid: enrollmentInfo?.course_uuid,
                user_id: enrollmentInfo?.user_id,
            }
        };
        let queryData = FETCH_ADMIN_USER_ASSESSMENT_CERTIFICATES(gqlVaribles);
        graphQLConnectionHandler(dispatch, queryData, (err, res) => {
            setCertificateStatus('LOADED')
            console.log(err, res);
            if (res?.adminUserAssessmentCertificates == null) {
                showUserNotification(`An error occurred while loading ${props.user.username} user certificate `, 'ERROR');
            } else {
                setCertificateDetails(res.adminUserAssessmentCertificates)
            }
        });
    };

    const downloadCertificate = (certificateData) => {
        var filename = certificateData?.fileName ?? getRefactoredFilename(`${enrollmentInfo?.username.replace('.', '-')} - ${courseInfo?.course_name} - Certificate of Completion.png`);
        var a = document.createElement("a");
        a.href = "data:image/png;base64," + certificateData?.certificate;
        a.hidden = true;
        a.download = filename;
        a.click();

    }
    return (
        <Dialog
            fullWidth={true}
            open={props.showPopup}
            maxWidth="sm"
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                <Typography variant="subtitle1" component="div">
                    Course Enrollment Summary : <b>{`${props.user.username}`}</b>
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={() => {
                        props.onClose();
                    }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Divider></Divider>
            <DialogContent>
                <Grid container spacing={5} alignItems="center" justifyContent={"flex-start"} sx={{ marginBottom: '15px' }}>
                    <Grid item xs={6} sx={rowKeyStytle}>
                        <Typography component={"h2"} sx={rowKeyStytle}>User Organization</Typography>
                    </Grid>
                    <Grid item xs={6} sx={rowValueStytle}>
                        <Typography component={"h2"} >{userInfo?.organization}</Typography>
                    </Grid>
                    <Grid item xs={6} sx={rowKeyStytle}>
                        <Typography component={"h2"} sx={rowKeyStytle}>Course</Typography>
                    </Grid>
                    <Grid item xs={6} sx={rowValueStytle}>
                        <Typography component={"h2"} >{courseInfo?.course_name}</Typography>
                    </Grid>
                    <Grid item xs={6} sx={rowKeyStytle}>
                        <Typography component={"h2"} sx={rowKeyStytle}>Enrollment Status</Typography>
                    </Grid>
                    <Grid item xs={6} sx={rowValueStytle}>
                        <Typography component={"h2"} >{enrollmentInfo?.status}</Typography>
                    </Grid>
                    <Grid item xs={6} sx={rowKeyStytle}>
                        <Typography component={"h2"} sx={rowKeyStytle}>Course Due Date</Typography>
                    </Grid>
                    <Grid item xs={6} sx={rowValueStytle}>
                        <Typography component={"h2"} >{enrollmentInfo?.due_date}</Typography>
                    </Grid>
                    <Grid item xs={6} sx={rowKeyStytle}>
                        <Typography component={"h2"} sx={rowKeyStytle}>Last Attempt Date</Typography>
                    </Grid>
                    <Grid item xs={6} sx={rowValueStytle}>
                        <Typography component={"h2"} >{enrollmentInfo?.updatedAt}</Typography>
                    </Grid>
                </Grid>
                {enrollmentInfo?.status === 'COMPLETED' &&
                    <>
                        {certificateDetails == null &&
                            <LoadingButton
                                style={{ float: 'right' }}
                                loading={certificateStatus === 'PENDING'}
                                loadingPosition="start"
                                startIcon={<CardMembership />}
                                variant="outlined"
                                disabled={certificateStatus === 'LOADED'}
                                onClick={() => {
                                    loadUserAssessmentCertificate();
                                }}
                            >
                                Certificate
                            </LoadingButton>
                        }
                        {certificateDetails != null &&
                            <>
                                <CardMedia component='img' sx={{ height: '30%', width: '80%', margin: 'auto' }} src={`data:image/png;base64, ${certificateDetails?.certificate}`} />
                                <div style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: '10px' }}>
                                    <Button variant="contained" startIcon={<CloudDownload />} onClick={() => downloadCertificate(certificateDetails)}>
                                        Download
                                    </Button>
                                </div>
                            </>
                        }
                    </>
                }
            </DialogContent>
        </Dialog>
    );
}

UserCourseEnrollmentDetailsModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    backdropCloseEnable: PropTypes.bool,
    showPopup: PropTypes.bool,
    user: PropTypes.object.isRequired,
    course_uuid: PropTypes.string.isRequired,
};

UserCourseEnrollmentDetailsModal.defaultProps = {
    backdropCloseEnable: false
};


export default UserCourseEnrollmentDetailsModal;
