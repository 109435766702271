// assets
import { IconSettings, IconUser } from '@tabler/icons';
import { UsersProfileSideMenuItems } from 'utils';

// constant
const icons = {
    IconSettings,
    IconUser
};

// ==============================|| PROFILE MENU ITEMS ||============================== //

const profileSettings = {
    id: UsersProfileSideMenuItems.rootId,
    title: 'Profile',
    type: 'group',
    children: [
        {
            id: UsersProfileSideMenuItems.userAccountSettings,
            title: 'Account Settings',
            type: 'item',
            url: '',
            icon: icons.IconSettings,
            breadcrumbs: false
        },
        {
            id: UsersProfileSideMenuItems.userProfile,
            title: 'My Profile',
            type: 'item',
            url: '',
            icon: icons.IconUser,
            breadcrumbs: false
        },
    ]
};

export default profileSettings;
