import { useState } from 'react';
import Switch from 'ui-component/switch';

const TableRowSwitch = (props) => {

    let eventData = props.eventData;
    let rowDetails =  props.rowDetails;

    const [switchValue, setSwitchValue] = useState((rowDetails[eventData.label] === eventData.value[0])? true : false);

    return (
        <Switch inputLabel={''} triggerChange={switchValue} labelPosition={"start"}
        onChange={() => { 
            rowDetails[eventData.label] = switchValue? eventData.value[1]: eventData.value[0]; 
            props.onTrigger(eventData.action ?? "TOGGLE", rowDetails) 
            setSwitchValue(!switchValue)
        }} />
      )
}

export default TableRowSwitch;
