import React, { Component } from 'react';
import { CreateTypes } from 'canvas-confetti';
import ReactCanvasConfetti from 'react-canvas-confetti';
import "./confetti-fireworks.scss";
import { useEffect } from 'react';

export default function ConfettiFireworks(props) {

  let isAnimationEnabled = false
  let animationInstance = null;
  let intervalId = null;

  function randomInRange(min, max){
    return Math.random() * (max - min) + min;
  }

  function getAnimationSettings(originXA, originXB){
    return {
      startVelocity: 10,
      spread: 500,
      ticks: 100,
      zIndex: 0,
      particleCount: 150,
      origin: {
        x: randomInRange(originXA, originXB),
        y: Math.random() - 0.2
      }
    }
  }

  const nextTickAnimation =()=> {
    animationInstance && animationInstance(getAnimationSettings(0.1, 0.3));
    animationInstance && animationInstance(getAnimationSettings(0.7, 0.9));
  }

  function startAnimation() {
    if (!isAnimationEnabled) {
      isAnimationEnabled = true;
      intervalId = setInterval(nextTickAnimation, 400);
    }
  }

  function stopAnimation() {
    isAnimationEnabled = false;
    animationInstance && animationInstance.reset();
    return intervalId && clearInterval(intervalId);
  }

  useEffect(() => {
      if(props.startConfetti){ 
          startAnimation();
          setTimeout(() => {
            stopAnimation()
          }, 50000);
        }
}, [props.startConfetti]);

  const getInstance = (instance) => {
    animationInstance = instance
  }

    return (
      <>
        <ReactCanvasConfetti
          refConfetti={getInstance}
          className="canvas-fireworks"
        />
      </>
    );
  
}