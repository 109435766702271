const { CourseMenu } = require("./sidemenuRoutes");

export const sideMenuRoutingFactory = {
    courseRoute: (course_uuid = '') => {
        let result = {};
        result[CourseMenu.courseDetails.id] = CourseMenu.courseDetails.getUrl(course_uuid);
        result[CourseMenu.courseContent.id] = CourseMenu.courseContent.getUrl(course_uuid);
        result[CourseMenu.courseAssignment.id] = CourseMenu.courseAssignment.getUrl(course_uuid);
        result[CourseMenu.courseDashboard.id] = CourseMenu.courseDashboard.getUrl(course_uuid);
        result[CourseMenu.courseUserEnrollment.id] = CourseMenu.courseUserEnrollment.getUrl(course_uuid);
        result[CourseMenu.courseReport.id] = CourseMenu.courseReport.getUrl(course_uuid);
        result[CourseMenu.courseEResources.id] = CourseMenu.courseEResources.getUrl(course_uuid);
        return result;
    }
}