// material-ui
import { useTheme } from '@mui/material/styles';
import logoEmapta from 'assets/images/emapta-logo.png';

const Logo = () => {
    const theme = useTheme();

    return (
         <img src={logoEmapta} alt="Emapta" width="300" />
    );
};

const LogoHeader = () => {
    const theme = useTheme();

    return (
         <img src={logoEmapta} alt="Emapta" width="175" />
    );
};

export  { Logo, LogoHeader };
