import { graphQLConnectionHandler } from '@core/graphql/graphQLConnectionHandler';
import { CREATE_COURSE_MODULE, UPDATE_COURSE_MODULE, VALIDATE_MODULE_PUBLISHABILITY } from '@core/graphql/queries/courseModule';
import { useTheme } from '@mui/material/styles';
import { Box, FormControl, FormHelperText, Grid, InputLabel, OutlinedInput, Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import useScriptRef from 'hooks/useScriptRef';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showUserNotification } from 'utils';
import Switch from 'ui-component/switch';
import * as Yup from 'yup';

let initFormData = {
    moduleTitle: '',
};

export default function ModuleModal(props) {

    const theme = useTheme();
    const { courseUUID, action, moduleData } = props;
    const [open, setOpen] = useState(props.open);
    const [actionType, setActionType] = useState(props.actionType);
    const [message, setMessage] = useState(null);
    const [InitFormData, setInitFormData] = useState({
        moduleTitle: action === 'CREATE' ? '' : (moduleData?.title || ''),
        is_active: action === 'CREATE' ? false : (moduleData?.is_active || false)
    });
    const [moduleActivated, setModuleActivate] = useState(action === 'CREATE' ? false : (moduleData?.is_active || false));
    const [pendingRequest, setPendingRequest] = useState(false);
    const [isModulePublishable, setModulePublishable] = useState(false);
    const [unPublishNotification, setUnPublishNotification] = useState("");

    const dispatch = useDispatch();
    const scriptedRef = useScriptRef();

    useEffect(() => {
        setOpen(props.open);
        setTimeout(() => formik.setFieldTouched("moduleTitle", false));
        validateModulePublishability();
    }, [props.open]);


    const handleClose = () => {
        props.onModuleModalClose(false);
        setOpen(false);
    };

    const validateModulePublishability = () => {
        if (action === 'UPDATE') {
            let gqlVaribles = {
                variables: { "module_uuid": moduleData?.module_uuid || '' }
            }
            graphQLConnectionHandler(dispatch, VALIDATE_MODULE_PUBLISHABILITY(gqlVaribles), (err, res) => {
                if (!err && res?.modulePublishable != undefined) {
                    setModulePublishable(res?.modulePublishable.is_publishable);
                    setUnPublishNotification(res?.modulePublishable.message);
                }
            });
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: InitFormData,
        validationSchema: Yup.object({
            moduleTitle: Yup.string().max(255).required('Module Title is required'),
        }),
        onSubmit: (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
            try {
                setPendingRequest(true);


                if (scriptedRef.current) {
                    let gqlVaribles = {
                        variables: {
                            course_uuid: courseUUID,
                            title: values.moduleTitle,
                            module_uuid: moduleData?.module_uuid || '',
                            is_active: moduleActivated
                        }
                    };

                    let actionOperator = action === 'CREATE' ? CREATE_COURSE_MODULE(gqlVaribles) : UPDATE_COURSE_MODULE(gqlVaribles);


                    graphQLConnectionHandler(dispatch, actionOperator, (error, response) => {
                        setPendingRequest(false);
                        if (!error) {
                            showUserNotification(
                                'Course module has been ' + actionType + 'd successfully',
                                'SUCCESS'
                            );
                            props.onModuleRefresh()
                            resetForm();
                        } else {
                            setMessage(error.message);
                        }
                    });
                }
            } catch (err) {
                console.log('eroor ', err);
                showUserNotification('Unexpected error occurred while creating the Course module ', 'ERROR');
            }

        },
    });

    return (
        <div>
            <Dialog open={open}>
                <DialogTitle>{action === 'CREATE' ? 'Add Module' : 'Update Module'}</DialogTitle>
                <form noValidate onSubmit={formik.handleSubmit}>
                    <DialogContent>
                        <DialogContentText>
                            Please enter the name of the module that you wish to add
                        </DialogContentText>
                        <br></br>
                        <FormControl size="medium" fullWidth error={Boolean(formik.touched.moduleTitle && formik.errors.moduleTitle)}>
                            <InputLabel htmlFor="outlined-adornment-moduleTitle-course-page">Module Title</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-moduleTitle-course-page"
                                type="moduleTitle"
                                value={formik.values.moduleTitle}
                                name="moduleTitle"
                                size="medium"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                label="Module Title"
                                inputProps={{}}
                            />
                            {formik.touched.moduleTitle && formik.errors.moduleTitle && (
                                <FormHelperText error id="standard-weight-helper-text-moduleTitle-course-page">
                                    {formik.errors.moduleTitle}
                                </FormHelperText>
                            )}
                        </FormControl>
                        {action === 'UPDATE' &&
                            <Tooltip title={unPublishNotification} placement="top-start">
                                <FormControl
                                    fullWidth
                                    error={Boolean(formik.touched.is_active && formik.errors.is_active)}
                                    sx={{ ...theme.typography.customInput, mt: 3 }}
                                >
                                    <Switch labelPosition="start" inputLabel="Module Activated" color="rgb(101, 196, 102)" triggerChange={moduleActivated} onChange={() => {
                                        if (isModulePublishable)
                                            setModuleActivate(!moduleActivated);
                                    }} />
                                </FormControl>
                            </Tooltip>
                        }
                    </DialogContent>
                    <DialogActions>

                        {formik.errors.submit ||
                            (message && (
                                <Box sx={{ mt: 3 }}>
                                    <FormHelperText error style={{ textAlign: 'center' }}>
                                        {formik.errors.submit ?? message}
                                    </FormHelperText>
                                </Box>
                            ))}


                        <Button disabled={pendingRequest} type="submit"
                            onClick={() => setActionType(action === 'CREATE' ? 'create' : 'update')}>{action === 'CREATE' ? 'Add Module' : 'Update Module'}</Button>
                        <Button onClick={handleClose} color='error'>Close</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
}

ModuleModal.propTypes = {
    action: PropTypes.string.isRequired,
    moduleData: PropTypes.object,
};

ModuleModal.defaultProps = {
    action: 'CREATE'
};
