export const flattenData = (data) => {
    var returnObject = {};

    for (var i in data) {
        if (!data.hasOwnProperty(i)) continue;

        if ((typeof data[i]) == 'object' && data[i] !== null) {
            var flatObject = flattenData(data[i]);
            for (var x in flatObject) {
                if (!flatObject.hasOwnProperty(x)) continue;

                returnObject[i + '.' + x] = flatObject[x];
            }
        } else {
            returnObject[i] = data[i];
        }
    }
    return returnObject;
}

export const flatternArray = (dataList) => {
    return dataList.map((_data) => { return flattenData(_data) });
}