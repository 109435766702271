export const CREATE_ASSIGNMENT_QUIZ = (variables) => {
    let query = {
        query: `mutation CreateAssignmentQuiz($question: String!, $assignment_id: String!, $status: String!,$comment:String!, $answers: [QuizAnswer]!) { 
                         createAssignmentQuiz (
                            quizInput: {
                                question: $question 
                                assignment_id: $assignment_id, 
                                status: $status,
                                answers: $answers
                                comment:$comment
                            }) {
                                quiz_uuid
                        }}`
    };

    let mergeQuery = Object.assign({}, query, variables);

    return JSON.stringify(mergeQuery);
};

export const UPDATE_ASSIGNMENT_QUIZ = (variables) => {
    let query = {
        query: `mutation UpdateAssignmentQuiz($question: String!, $assignment_id: String!, $status: String!, $comment:String!, $answers: [QuizAnswer]!,$quiz_uuid:String!,$deleteAnswers:[String]) { 
                          updateAssignmentQuiz (
                            quizInput: {
                                question: $question 
                                assignment_id: $assignment_id, 
                                status: $status,
                                answers: $answers,
                                comment:$comment
                                deleteAnswers:$deleteAnswers
                            },
                            quiz_uuid:$quiz_uuid
                            ) {
                                quiz_uuid
                        }}`
    };

    let mergeQuery = Object.assign({}, query, variables);

    return JSON.stringify(mergeQuery);
};

export const REARRANGE_ASSESSMENTS_QUIZZES = (variables) => {
    let query = {
        query: `mutation ReorderAssignmentQuizzes($quizzes:[QuizReorder]!) { 
                        reorderAssignmentQuizzes(quizzes:$quizzes) {
                            code
                            reason
        }}`
    };
    let mergeQuery = Object.assign({}, query, variables);

    return JSON.stringify(mergeQuery);
}

export const FETCH_USER_ASSIGNMENT_ANSWER_SUMMARY_PAGINATE = (variables) => {
    let query = {
        query: `query($searchQuery: String, $page: Int!, $limit: Int!, $filter: [UserInputFilterData],$course_uuid: String!,$assignment_uuid:String!,$sortData:[String]!) 
                    { assignmentQuizFilter(searchQuery: $searchQuery, page: $page, limit: $limit, filter: $filter,course_uuid:$course_uuid,assignment_uuid:$assignment_uuid,sortData:$sortData) {
                        count,
                        rows {
                            quiz_uuid
                            assignment_uuid
                            status
                            question
                            quiz_order
                            createdAt
                            updatedAt
                        }
                    }}`
    };
    let mergeQuery = Object.assign({}, query, variables);
    return JSON.stringify(mergeQuery);
};

export const FETCH_USER_ASSIGNMENT_ANSWER_SUMMARY_DETAILS = (variables) => {
    let query = {
        query: `query($assignment_uuid: String!, $quiz_uuid: String!,$course_uuid: String!) 
                    { userQuizAnswerStatisticalSummary(assignment_uuid: $assignment_uuid, quiz_uuid: $quiz_uuid, course_uuid: $course_uuid) {
                        answer_uuid
                        answer
                        isCorrect
                        submitCount
                        submitRate
                    }}`
    };
    let mergeQuery = Object.assign({}, query, variables);
    return JSON.stringify(mergeQuery);
};