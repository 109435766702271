import { gridSpacing } from '@core/actions/constant';
// material-ui
import { Grid, Typography } from '@mui/material';
import useForceUpdate from 'hooks/useForceUpdate';
import { useEffect, useState } from 'react';
import QuizAnswer from 'ui-component/assignment/QuizAnswer';
import * as Yup from 'yup';

// ==============================|| DEFAULT DASHBOARD ||============================== //

const ValidationSchema = Yup.object().shape({
    question: Yup.string().max(2500).required('Question is required'),
});

export default function CourseQuestionView(props) {


    const { quizData } = props;
    const { submittedAnswers } = props;

    const [triggerParent, setTriggerParent] = useState(false);
    const [quizAnswerTemp, setQuizAnswerTemp] = useState({});
    const forceUpdate = useForceUpdate();

    const onUpdateQuizAnswers = (action, eventData) => {

        if (quizData.quiz_uuid in quizAnswerTemp) {
            let answersGiven = [...quizAnswerTemp[quizData.quiz_uuid]]
            if (!answersGiven.includes(eventData)) {
                answersGiven.push(eventData)
            } else {
                answersGiven = answersGiven.filter(item => item !== eventData)
            }
            let quizAnsObj = {}
            quizAnsObj[quizData.quiz_uuid] = [...answersGiven]
 
            setQuizAnswerTemp(quizAnsObj)
            setTriggerParent(true)
        } else {
            let quizAnsObj = {}
            quizAnsObj[quizData.quiz_uuid] = [eventData]
            setQuizAnswerTemp(quizAnsObj)
            setTriggerParent(true)
        }

        forceUpdate();

    }

    useEffect(() => {
        if (triggerParent) {
            props.onAnswerChange("ANSWER", quizAnswerTemp)
            setTriggerParent(false)
        }
    }, [triggerParent]);

    useEffect(() => {

        if (submittedAnswers && submittedAnswers.length > 0) {
            let quizAnsObj = {}
            quizAnsObj[quizData.quiz_uuid] = [...submittedAnswers]
            setQuizAnswerTemp(quizAnsObj)
        }
    }, [submittedAnswers]);


    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>

                <Grid key={quizData.quiz_uuid}>
                    <Typography variant="h3">{quizData.question}</Typography>
                    <br />

                    {
                        quizData?.quiz_answers?.length > 0 && quizData?.quiz_answers.map((quiz_answer) => {

                            return (
                                <Grid key={quiz_answer.answer_id}>
                                    <QuizAnswer items={quiz_answer} enableRemoveIcon={false} markAnswer={quizData.quiz_uuid in quizAnswerTemp && quizAnswerTemp[quizData.quiz_uuid].includes(quiz_answer.answer_id)} onDataChange={(action, data) => onUpdateQuizAnswers(action, data)} />

                                </Grid>
                            )
                        })
                    }

                </Grid>

            </Grid>
        </Grid>
    )
}



