// assets
import { IconDashboard } from '@tabler/icons';
import { CourseMenu } from 'routes/sidemenu';

// constant
const icons = { IconDashboard };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
        CourseMenu.courseDashboard,
    ]
};

export default dashboard;
