import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import CourseAssignments from '../CourseAssignment';

export default function AssessmentQuizModal(props) {

    const handleClose = () => {
        props.onClose(false);
    };

    return (
        <Dialog open={props.open} maxWidth="md" fullWidth={true}>
            <DialogTitle id="responsive-dialog-title">
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                <CourseAssignments action={props.action} questionDetails={props.quizDetails}
                    assignment={props.assignment} course_uuid={props.course_uuid} closeOnSuccess={true} onClose={() => props.onFormSubmissionSuccess()} />
            </DialogContent>
        </Dialog>
    );
}

AssessmentQuizModal.propTypes = {
    action: PropTypes.oneOf(['CREATE', 'UPDATE']).isRequired,
    quizDetails: PropTypes.object,
    course_uuid: PropTypes.string.isRequired,
    assignment: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onFormSubmissionSuccess: PropTypes.func.isRequired,
};

AssessmentQuizModal.defaultProps = {
    action: 'CREATE'
};