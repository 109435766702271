import { gridSpacing } from '@core/actions/constant';
import { setCurrentPage } from '@core/actions/customization';
import { graphQLConnectionHandler } from '@core/graphql/graphQLConnectionHandler';
import { DELETE_ASSESSMENT_QUIZ_ANSWER, FETCH_ASSESSMENT_QUIZ_ANSWERS, VALIDATE_ASSESSMENT_PUBLISHABILITY } from '@core/graphql/queries/assessment';
import { CREATE_ASSIGNMENT_QUIZ, UPDATE_ASSIGNMENT_QUIZ } from '@core/graphql/queries/quiz';
import { useTheme } from '@emotion/react';
import { EditRoadOutlined } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { LoadingButton } from '@mui/lab';
// material-ui
import { Box, FormControl, FormHelperText, Grid, InputLabel, MenuItem, OutlinedInput, Select, Tooltip, Typography } from '@mui/material';
import { useFormik } from 'formik';
import useForceUpdate, { useSelectedCourseChanged } from 'hooks/useForceUpdate';
import useScriptRef from 'hooks/useScriptRef';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { sideMenuRoutingFactory, useSideMenuRoute } from 'routes/sidemenu';
import QuizAnswer from 'ui-component/assignment/QuizAnswer';
import { showUserNotification } from 'utils';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import { COURSE } from '../../@core/helpers/pageRoutings';
import quizAnswerCSS from './CourseContentAssesment.module.css';



// ==============================|| DEFAULT DASHBOARD ||============================== //

const CourseAssignments = (props) => {

    let { action, assignment, questionDetails, course_uuid } = props;

    let initFormData = {
        question: questionDetails?.question ?? '',
        quizAnswer: '',
        questionStatus: questionDetails?.status ?? 'INACTIVE',
        comment: questionDetails?.comment ?? '',
    };

    const onSelectedCourseChanged = useSelectedCourseChanged();

    const [isLoading, setLoading] = useState(action === 'UPDATE');
    const [quizAnswer, setQuizAnswer] = useState('');
    const [message, setMessage] = useState(null);
    const [pendingRequest, setPendingRequest] = useState(false);
    const dispatch = useDispatch();
    const theme = useTheme();
    const forceUpdate = useForceUpdate();
    const scriptedRef = useScriptRef();
    useSideMenuRoute(sideMenuRoutingFactory.courseRoute(course_uuid));
    const [assignmentQuizAnswers, setAssignmentQuizAnswers] = useState([]);
    const [recordedQuizAnswers, setRecordedQuizAnswers] = useState([]);
    const [deletingAnswers, setDeletingAnswers] = useState(new Set());
    const [isAssessmentPublishable, setAssessmentPublishable] = useState(false);
    const [unPublishNotification, setUnPublishNotification] = useState("");

    const onUpdateQuizAnswers = (actionType, eventData) => {

        const updatedAnswers = assignmentQuizAnswers;
        // console.log('Action Items : ', actionType, action, eventData, JSON.stringify(recordedQuizAnswers), recordedQuizAnswers.includes(eventData));

        if (actionType === 'REMOVE_ANSWER') {
            if (action === 'UPDATE' && recordedQuizAnswers.includes(eventData)) {
                let deletesAnswers = deletingAnswers;
                deletesAnswers.add(eventData);
                setDeletingAnswers(deletesAnswers);
            }
            setAssignmentQuizAnswers(updatedAnswers.filter(value => value.answer_id != eventData));
        } else if (actionType === 'MARK_ANSWER') {
            for (var i = 0; i < updatedAnswers.length; i++) {
                var answer = updatedAnswers[i];
                if (answer.answer_id === eventData) {
                    answer.isCorrect = !answer.isCorrect;
                }
                updatedAnswers[i] = answer;
            }
            setMessage(null);
            setAssignmentQuizAnswers(updatedAnswers);

        } else if (actionType === 'ADD_ANSWER') {
            updatedAnswers.push({
                answer_id: uuidv4(),
                answer: eventData,
                isCorrect: false,
                isNewAnswer: true,
            });
            setMessage(null);
            setAssignmentQuizAnswers(updatedAnswers);
            setQuizAnswer('');
        }
        forceUpdate();
    }

    const loadAssessmentQuizAnswers = () => {

        let gqlVaribles = {
            variables: {
                quiz_uuid: questionDetails.quiz_uuid,
            }
        };
        let queryData = FETCH_ASSESSMENT_QUIZ_ANSWERS(gqlVaribles);
        graphQLConnectionHandler(dispatch, queryData, (err, res) => {
            if (res.assignmentQuizAnswers == null) {
                showUserNotification('Assessment quiz answers not found', 'WARN');
            } else {
                let answerIds = [];
                res?.assignmentQuizAnswers.forEach(answer => {
                    answerIds.push(answer.answer_id);
                });
                setRecordedQuizAnswers(answerIds);
                let loadedAnswers = res.assignmentQuizAnswers.map(value => {
                    return { ...value, isNewAnswer: false };
                })
                setAssignmentQuizAnswers(loadedAnswers);
            }
            setLoading(false);
        });
    };

    const deleteAssessmentAnswer = (answer_id) => {
        let gqlVaribles = {
            variables: {
                answer_id: answer_id,
            }
        };
        let queryData = DELETE_ASSESSMENT_QUIZ_ANSWER(gqlVaribles);
        graphQLConnectionHandler(dispatch, queryData, (err, res) => {
            if (res.deleteAssignmentQuizAnswer == null) {
                showUserNotification('Something went wrong!', 'ERROR');
            } else {
                let response = res.deleteAssignmentQuizAnswer;
                showUserNotification(response.isDeleted ? 'Quiz Answer has been successfully deleted!' : 'An error occured while deleting the quiz answer', response.isDeleted ? 'SUCCESS' : 'WARN');
            }
        });
    };

    useEffect(() => {
        onSelectedCourseChanged(course_uuid);
        dispatch(setCurrentPage(COURSE));
        if (action === 'UPDATE') {
            validateAssignmentPublishability()
            loadAssessmentQuizAnswers();
        }

    }, []);

    const getNewAnswerStyle = () => {
        return quizAnswer === '' ? { opacity: 0.40, pointerEvents: 'none' } : { opacity: 1, pointerEvents: 'initial' };
    }

    const formik = useFormik({
        initialValues: initFormData,
        validationSchema: Yup.object({
            question: Yup.string().max(2500).required('Question is required'),
            comment: Yup.string().max(2500).required('Comment is required'),
            questionStatus: Yup.string().max(100).required('Question Status is required'),
        }),
        onSubmit: (values, { setErrors, setStatus, setSubmitting, resetForm }) => {

            try {
                if (assignmentQuizAnswers.length < 2) {
                    setMessage('A questionnaire is required at least 2 answers');
                } else if (assignmentQuizAnswers.filter(q => q.isCorrect).length == 0) {
                    setMessage('Correct answer/s required');
                } else if (scriptedRef.current) {
                    let gqlVaribles = {
                        variables: {
                            question: values.question,
                            assignment_id: assignment.assignment_uuid,
                            status: values.questionStatus,
                            answers: assignmentQuizAnswers,
                            comment: values.comment,
                            quiz_uuid: action === 'UPDATE' ? questionDetails.quiz_uuid : null,
                            deleteAnswers: action === 'UPDATE' ? [...deletingAnswers] : []
                        }
                    };

                    let actionOperator = action === 'CREATE' ? CREATE_ASSIGNMENT_QUIZ(gqlVaribles) : UPDATE_ASSIGNMENT_QUIZ(gqlVaribles);
                    setPendingRequest(true);

                    graphQLConnectionHandler(dispatch, actionOperator, (error, response) => {
                        setPendingRequest(false);
                        if (!error) {
                            showUserNotification('Quiz is ' + action.toLowerCase() + 'd', "SUCCESS");
                            if (props.closeOnSuccess) {
                                props.onClose();
                            }
                        } else {
                            setMessage(error.message);
                        }
                    });
                }

            } catch (err) {
                console.error(err);
            }
        }
    });


    const validateAssignmentPublishability = () => {

        let activeQuestions = assignment?.parentRow.quizzes.filter(function (el) {
            return el.status == "ACTIVE";
        });


        if (activeQuestions != undefined && (activeQuestions.length <= assignment?.parentRow.number_display_questions) && formik.values.questionStatus == "ACTIVE") {
            setAssessmentPublishable(false)
            setMessage("System not allow to update the Quiz status to INACTIVE state due to maintain the minimum number of active questions.")
        } else {
            setAssessmentPublishable(true)
            setMessage(null)
        }
    }

    return (
        <Grid container spacing={gridSpacing}>

            <Grid item xs={12}>
                <Typography variant="h2">{action == 'CREATE' ? 'Course Assignment Question' : 'Update Course Assignment Question'} </Typography>
                <form noValidate onSubmit={formik.handleSubmit}>

                    <FormControl
                        fullWidth
                        error={Boolean(formik.touched.question && formik.errors.question)}
                        sx={{ ...theme.typography.customInput }}
                    >
                        <InputLabel htmlFor="outlined-adornment-question-course-assignment">Question</InputLabel>
                        <Box sx={{ pt: 1, width: 1 }} >
                            <OutlinedInput
                                id="outlined-adornment-question-course-assignment"
                                type="question"
                                value={formik.values.question}
                                name="question"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                label="Question"
                                inputProps={{}}
                                multiline
                                sx={{ width: 1 }}
                                rows={2}
                            />
                            {formik.touched.question && formik.errors.question && (
                                <FormHelperText error id="standard-weight-helper-text-question-course-assignment">
                                    {formik.errors.question}
                                </FormHelperText>
                            )}
                        </Box>
                    </FormControl>
                    <FormControl
                        fullWidth
                        error={Boolean(formik.touched.comment && formik.errors.comment)}
                        sx={{ ...theme.typography.customInput }}
                    >
                        <InputLabel htmlFor="outlined-adornment-question-course-comment">Comment</InputLabel>
                        <Box sx={{ pt: 1, width: 1 }} >
                            <OutlinedInput
                                id="outlined-adornment-question-course-comment"
                                type="text"
                                value={formik.values.comment}
                                name="comment"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                label="Comment"
                                inputProps={{}}
                                multiline
                                sx={{ width: 1 }}
                                rows={2}
                            />
                            {formik.touched.comment && formik.errors.comment && (
                                <FormHelperText error id="standard-weight-helper-text-question-course-comment">
                                    {formik.errors.comment}
                                </FormHelperText>
                            )}
                        </Box>
                    </FormControl>
                    {
                        assignmentQuizAnswers.map((question, index) => {
                            return <QuizAnswer key={question.answer_id} items={question} onDataChange={(action, data) => onUpdateQuizAnswers(action, data)} />
                        })

                    }

                    <div className={quizAnswerCSS.quetionContainer}>
                        <div className={quizAnswerCSS.leftSubContainer} style={getNewAnswerStyle()} onClick={() => { onUpdateQuizAnswers('ADD_ANSWER', quizAnswer) }}> <AddIcon style={{ fill: '#2196F3' }} /></div>
                        <div className={quizAnswerCSS.contentContainer}>
                            <div>
                                <FormControl
                                    fullWidth
                                    error={Boolean(formik.touched.quizAnswer && formik.errors.quizAnswer)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-question-answer-course-assignment">Quiz Answer</InputLabel>
                                    <Box sx={{ pt: 1, width: 1 }} >
                                        <OutlinedInput
                                            id="outlined-adornment-question-answer-course-assignment"
                                            type="quizAnswer"
                                            value={quizAnswer}
                                            name="quizAnswer"
                                            onBlur={formik.handleBlur}
                                            onChange={(event) => {
                                                formik.handleChange(event);
                                                setQuizAnswer(event.target.value);
                                            }}
                                            label="Quiz Answer"
                                            inputProps={{}}
                                            multiline
                                            sx={{ width: 1 }}
                                            rows={2}
                                        />
                                        {formik.touched.quizAnswer && formik.errors.quizAnswer && (
                                            <FormHelperText error id="standard-weight-helper-text-question-answer-course-assignment">
                                                {formik.errors.quizAnswer}
                                            </FormHelperText>
                                        )}
                                    </Box>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                    <Grid container spacing={2} mt={1} mb={2}>
                        <Grid item md={5}>
                            <FormControl size="medium" sx={{ mb: 2 }} fullWidth error={Boolean(formik.touched.questionStatus && formik.errors.questionStatus)}>
                                <InputLabel htmlFor="outlined-adornment-course-question-quiz-status">Quiz Status</InputLabel>
                                <Select
                                    id="outlined-adornment-course-question-quiz-status"
                                    value={formik.values.questionStatus}
                                    name="questionStatus"
                                    size="medium"
                                    onBlur={formik.handleBlur}
                                    onChange={(event) => {
                                        formik.values.questionStatus = event.target.value;
                                        forceUpdate();
                                        validateAssignmentPublishability()
                                    }}
                                    disabled={!isAssessmentPublishable && formik.values.questionStatus == "ACTIVE"}
                                    label="Quiz Status"
                                    inputProps={{}}
                                >
                                    <MenuItem value={"ACTIVE"}>ACTIVE</MenuItem>
                                    <MenuItem value={"INACTIVE"}>INACTIVE</MenuItem>
                                </Select>
                                {formik.touched.questionStatus && formik.errors.questionStatus && (
                                    <FormHelperText error id="standard-weight-helper-text-course-question-quiz-status">
                                        {formik.errors.questionStatus}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item md={3} />
                        <Grid item md={4}>

                            <LoadingButton
                                loading={pendingRequest}
                                loadingPosition="start"
                                disableElevation
                                disabled={pendingRequest || isLoading}
                                fullWidth
                                startIcon={action == 'CREATE' ? <NoteAddIcon /> : <EditRoadOutlined />}
                                size="large"
                                type="submit"
                                variant="contained"
                            >
                                {action == 'CREATE' ? 'Add Question' : 'Update Question'}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                    <Box sx={{ mt: 2 }} />
                    {formik.errors.submit || message && (
                        <Box sx={{ mt: 3 }} >
                            <FormHelperText id="standard-weight-helper-text-course-assessment-message" error style={{ textAlign: "center" }}>{formik.errors.submit ?? message}</FormHelperText>
                        </Box>
                    )}

                </form>

            </Grid>
        </Grid>
    );


};

CourseAssignments.propTypes = {
    action: PropTypes.string.isRequired,
    assignment: PropTypes.object.isRequired,
    course_uuid: PropTypes.string.isRequired,
    questionDetails: PropTypes.object,
    closeOnSuccess: PropTypes.bool.isRequired,
    onClose: PropTypes.func
};

CourseAssignments.defaultProps = {
    action: 'CREATE',
    closeOnSuccess: false,
};
export default CourseAssignments;
