import { invalidSession } from '@core/actions/auth';
import axios from 'axios';
import config from 'config';
import { showUserNotification } from 'utils';
const API_URL = config.serviceUrl + "api";

export const graphQLConnectionHandler = (dispatch, gqlMsg, callback = (error, respose) => { console.log(error, respose) }) => {
    let token = JSON.parse(localStorage.getItem("user"))?.user.token ?? '';
    axios.post(API_URL, gqlMsg, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        }
    }).then(response => {
        callback(null, response.data.data);
        if (response.status === 401 || response.status === 403) {
            dispatch(invalidSession({ code: response.status, }));
            showUserNotification("You do not permission to proceed ", "WARN");
        }
    }).catch(error => {
        callback(error, null);
        showUserNotification(error.message, "ERROR");
        if (error.message?.includes("401") || error.message?.includes("401")) {
            dispatch(invalidSession({ code: error.message?.includes("401") ? 401 : 403, }));
        }
    });
}
