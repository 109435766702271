// Login related events
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_REQUEST_PENDING = "LOGIN_REQUEST_PENDING";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const AUTHENTICATION_FAIL = "AUTHENTICATION_FAIL";
export const CHANGE_USER_ROLE = "CHANGE_USER_ROLE";
export const WAIT_FOR_SSO_LOGIN = "WAIT_FOR_SSO_LOGIN";

// Notification related events
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

// UI related events
export const SET_MENU = '@customization/SET_MENU';
export const SET_CURRENT_PAGE = '@customization/SET_CURRENT_PAGE';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';

// GraphQL related events
export const SENDING_GRAPHQL_REQUEST = 'SENDING_GRAPHQL_REQUEST';
export const RECEIVING_GRAPHQL_RESPONSE = 'RECEIVING_GRAPHQL_RESPONSE';
export const GRAPHQL_REQUEST_FAILED = 'GRAPHQL_REQUEST_FAILED';

// Course related events
export const SET_CURRENT_CONTENT_PAGE = 'SET_CURRENT_CONTENT_PAGE';
export const SET_CURRENT_MODULE = 'SET_CURRENT_MODULE';
export const SET_NAV_COURSE_VIEW = 'SET_NAV_COURSE_VIEW';
export const SET_VISITED_COURSE_PAGES = 'SET_VISITED_COURSE_PAGES';

// Dynamic Side Menu Routings
export const DYNAMIC_SIDE_MENU_NAVIGATION_CHANGE = 'DYNAMIC_SIDE_MENU_NAVIGATION_CHANGE';
export const ON_ACTIVE_SIDE_MENU_COMPONENT_CHANGE = 'ON_ACTIVE_SIDE_MENU_COMPONENT_CHANGE';

// Selected Course Details
export const SET_SELECTED_COURSE = 'SET_SELECTED_COURSE';
export const CLEAR_SELECTED_COURSE = 'CLEAR_SELECTED_COURSE';
export const UPDATE_SELECTED_COURSE = 'UPDATE_SELECTED_COURSE';