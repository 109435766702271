import { graphQLConnectionHandler } from "@core/graphql/graphQLConnectionHandler";
import { FETCH_ASSESSMENT, FETCH_USER_ASSESSMENT_CERTIFICATE, SUBMIT_ASSESSMENT_QUIZ_ANSWERS } from "@core/graphql/queries/assessment";
import { Check, Close, Refresh } from "@mui/icons-material";
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { LoadingButton } from "@mui/lab";
import { Alert, AlertTitle, CardMedia, Divider, Grid, List, ListItem, Skeleton } from "@mui/material";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from "react-redux";
import { getRefactoredFilename, showUserNotification } from "utils";
import DialogAlertBox from "../modal/DialogAlertBox";
import AssessmentAchievement from "./AssessmentAchievement";
import ConfettiFireworks from "./ConfettiFireworks";
import "./course-view.css";
import CourseQuestionView from "./CourseQuestionView";

export default function CourseAssesmentView(props) {

    const theme = useTheme();

    const { course_uuid, assignment_uuid, module_title, assignmentType } = props;
    const selectedCourse = useSelector((state) => state.selectedCourse);
    const {user} = useSelector((state) => state.auth);

    const [activeStep, setActiveStep] = useState(0);
    const [assesmentAnswers, setAssesmentAnswers] = useState({});
    const [assesmentErrors, setAssesmentErrors] = useState([]);

    const [isLoading, setLoading] = useState(false);
    const [maxSteps, setMaxSteps] = useState(0);
    const dispatch = useDispatch();

    const [assignmentResults, setAssignmentResults] = useState({});
    const [quizList, setQuizList] = useState([]);
    const [userAttemptHistory, setUserAttemptHistory] = useState([]);
    // enable result view 0=initial 1=enable 2=disable
    const [enableResultView, setEnableResultView] = useState(0);
    const [awardCertificate, setAwardCertificate] = useState("");

    const [openAlertDetail, setOpenAlertDetail] = useState({ "isOpen": false, "data": {} });

    const handleNext = () => {
        if (validateQuestions()) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };


    const validateQuestions = () => {
        let answeredQuiz = quizList[activeStep]
        if (answeredQuiz.quiz_uuid in assesmentAnswers) {
            if (assesmentAnswers[answeredQuiz.quiz_uuid].length > 0) {
                setAssesmentErrors([])
                return true
            } else {
                setErrors("Please select your answer")
                return false
            }
        } else {
            setErrors("Please select your answer")
            return false
        }
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const setErrors = (errorText) => {

        if (!assesmentErrors.includes(errorText) || assesmentErrors.length == 0) {
            let currentAssesmentAnswers = [];
            if (assesmentErrors.length > 0)
                currentAssesmentAnswers = [...assesmentErrors]
            currentAssesmentAnswers.push(errorText)
            setAssesmentErrors([...currentAssesmentAnswers])
        }

    };

    const onUpdateQuizAnswers = (action, data) => {

        // check if the answers are provided
        if (action == "ANSWER") {
            if (Object.keys(data).length > 0) {

                let dataToMerge = {}

                if (Object.keys(assesmentAnswers).length > 0) {
                    dataToMerge = { ...assesmentAnswers }
                }

                if (Object.keys(data)[0] in assesmentAnswers) {
                    setAssesmentErrors([])
                    dataToMerge[Object.keys(data)[0]] = data[Object.keys(data)[0]]
                } else {
                    dataToMerge = { ...dataToMerge, ...data }
                }
                setAssesmentAnswers(dataToMerge)

                if (data[Object.keys(data)[0]].length == 0) {
                    setErrors("Please select your answer")
                }
            }
        }
    };


    const submitAnswers = () => {

        let dataSet = []
        setLoading(true);
        for (const [key, value] of Object.entries(assesmentAnswers)) {
            dataSet.push({ "quiz_uuid": key, "answer_id_list": value.toString() })
        }

        if (validateQuestions()) {
            let gqlVaribles = {
                variables: {
                    assignment_uuid: assignment_uuid,
                    course_uuid: course_uuid,
                    dataSet: dataSet
                }
            };

            let queryData = SUBMIT_ASSESSMENT_QUIZ_ANSWERS(gqlVaribles);

            graphQLConnectionHandler(dispatch, queryData, (err, res) => {

                if (res?.submitAssignmentQuizAnswers == null) {
                    showUserNotification('Assignment Submission Failed', 'WARN');
                } else {
                    setAssignmentResults(res.submitAssignmentQuizAnswers);
                }
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    };

    const downloadCertificate = (certificate) => {

        var a = document.createElement("a");
        a.href = "data:image/png;base64," + certificate;
        a.hidden = true;
        a.download = getRefactoredFilename(`${user.username} - ${selectedCourse.course_name? selectedCourse.course_name+" - ": ""}Certificate of Completion.png` ?? "Certificate of Completion.png") ;
        a.click();

    }

    const confirmRetakeProcess = (data) => {
        if (data) {
            setEnableResultView(2)
            reInitializeStates()
        }
    }

    const loadAssessment = () => {
        let gqlVaribles = {
            variables: {
                assignment_uuid: assignment_uuid,
            }
        };
        let queryData = FETCH_ASSESSMENT(gqlVaribles);
        graphQLConnectionHandler(dispatch, queryData, (err, res) => {
            if (res?.courseAssignment == null) {
                showUserNotification('Assessment not found', 'WARN');
            } else {
                setQuizList(res?.courseAssignment.quizzes);

                setUserAttemptHistory(res?.courseAssignment?.user_assignments ?? [])
            }
        });
    };

    const loadUserAssessmentCertificate = () => {
        let gqlVaribles = {
            variables: {
                assignment_uuid: assignment_uuid,
                course_uuid: course_uuid
            }
        };
        let queryData = FETCH_USER_ASSESSMENT_CERTIFICATE(gqlVaribles);
        graphQLConnectionHandler(dispatch, queryData, (err, res) => {
            if (res?.userAssessmentCertificate == null) {
                showUserNotification('Assessment not found', 'WARN');
            } else {
                if (assignmentType == "COURSE")
                    setAwardCertificate(res?.userAssessmentCertificate.certificate)
                else if (assignmentType == "MODULE")
                    setAwardCertificate("MODULE")
            }
        });
    };

    const retakeAssessment = () => {
        if (enableResultView == 1) {
            setOpenAlertDetail({
                "isOpen": true, "data": {
                    "title": "Please confirm",
                    "body": "You are not able to revert this change once you submit your results"
                }
            })

        }
    }

    useEffect(() => {
        if (assignment_uuid != "") {
            setEnableResultView(0);
            setUserAttemptHistory([]);
            setOpenAlertDetail({ "isOpen": false, "data": {} })
            reInitializeStates()
            setAwardCertificate("")
        }
    }, [assignment_uuid]);

    const reInitializeStates = () => {
        setAssignmentResults({});
        setAssesmentAnswers({});
        setActiveStep(0);
        setAssesmentErrors([]);
        setMaxSteps(0);
        setQuizList([]);
        loadAssessment();
    }

    useEffect(() => {
        setMaxSteps(quizList.length)
    }, [quizList.length > 0]);

    useEffect(() => {

        if (userAttemptHistory.length > 0 && enableResultView == 0 && userAttemptHistory[0].isSuccess) {
            loadUserAssessmentCertificate()
            setEnableResultView(1)
        }

    }, [userAttemptHistory]);


    let chartOpt = {
        series: [assignmentResults.mark_percentage],
        options: {
            chart: {
                height: 300,
                type: 'radialBar',
            },
            plotOptions: {
                radialBar: {
                    hollow: {
                        size: '50%',
                    }
                },
            },
            labels: ['Success'],
        },
    };



    return (
        <>
            {quizList.length > 0 &&

                <Box sx={{ flexGrow: 1 }}  >

                    <Grid container spacing={1} sx={{
                        justifyContent: "center"
                    }} >

                        {
                            Object.keys(assignmentResults).length > 0 ? (
                                <Grid md={12} item>
                                    <ConfettiFireworks startConfetti={assignmentResults.is_success && assignmentType == "COURSE"} />
                                    <Paper
                                        p={2}
                                        md={8}
                                        square
                                        elevation={0}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            minHeight: "36vw",
                                            maxHeight: "80vw",
                                            minWidth: "50vh",
                                            bgcolor: 'background.default',
                                        }}
                                    >

                                        <Grid container spacing={2}>

                                            <Grid item xs={6}>
                                                {
                                                    assignmentType == "MODULE" || !assignmentResults.is_success ?
                                                        (
                                                            <AssessmentAchievement
                                                                moduleTitle={module_title}
                                                                assignmentResult={assignmentResults.is_success}
                                                                assignmentType={assignmentType}
                                                            />
                                                        ) : (
                                                            <Grid sx={{ textAlign: "center", p: 2 }}>
                                                                <CardMedia component='img' src={`data:image/png;base64, ${assignmentResults.certificate}`} />
                                                                <LoadingButton

                                                                    loading={false}
                                                                    size="small" color="primary"
                                                                    onClick={() => { downloadCertificate(assignmentResults.certificate) }}
                                                                >
                                                                    Download Certificate
                                                                </LoadingButton>
                                                            </Grid>
                                                        )

                                                }
                                                <Grid item xs={12} direction={"row"} container justifyContent={"center"} alignItems={"center"}>
                                                    <Grid item xs={6}>
                                                        <ReactApexChart options={chartOpt.options} series={chartOpt.series} type="radialBar" height={200} />
                                                    </Grid>
                                                    <Grid item xs={6} sx={{ display: "flex", justifyContent: "center" }}>
                                                        <Button onClick={reInitializeStates} sx={{ float: "right" }} variant="outlined" startIcon={<Refresh />}>
                                                            Retake
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={5} >
                                                <PerfectScrollbar
                                                    options={{ suppressScrollY: false, suppressScrollX: true }} >

                                                    <Grid
                                                        container
                                                        spacing={1}
                                                        style={{
                                                            maxHeight: "70vh",
                                                            padding: "10px",
                                                            paddingTop: "20px",
                                                            minWidth: "fit-content",
                                                        }}
                                                    >

                                                        <List dense={true}
                                                            sx={{ borderRadius: 3, width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                                            {assignmentResults.quiz_details.map(function (quiz_detail, quiz_detail_index) {

                                                                let markedQuizObj = quizList.find(o => o.quiz_uuid === quiz_detail.quiz_uuid);
                                                                let givenAnswerList = []
                                                                let correctAnswerList = []
                                                                let correctNote = null

                                                                quiz_detail.answer_id.map(function (given_answer_id, given_answer_id_index) {
                                                                    let markedAnswerQuizObj = markedQuizObj.quiz_answers.find(o => o.answer_id === given_answer_id);
                                                                    givenAnswerList.push(markedAnswerQuizObj.answer)
                                                                })

                                                                if (quiz_detail.note) {
                                                                    correctNote = quiz_detail.note
                                                                }

                                                                if (quiz_detail.correct_ids) {
                                                                    quiz_detail.correct_ids.map(function (correct_answer_id, correct_answer_id_index) {
                                                                        let correctAnswerQuizObj = markedQuizObj.quiz_answers.find(o => o.answer_id === correct_answer_id);
                                                                        correctAnswerList.push(correctAnswerQuizObj.answer)
                                                                    })
                                                                }

                                                                return (
                                                                    quiz_detail.isCorrect ? (
                                                                        <ListItem key={quiz_detail_index}>
                                                                            <Alert icon={<Check fontSize="inherit" />} severity="success" sx={{ marginTop: "10px" }}>
                                                                                <AlertTitle><strong>{markedQuizObj.question}</strong></AlertTitle>
                                                                                {givenAnswerList.map(function (givenAnswer, givenAnswer_index) {
                                                                                    return (<Typography key={givenAnswer_index} variant="body2" gutterBottom>{givenAnswer}</Typography>)
                                                                                })}
                                                                            </Alert>
                                                                        </ListItem>
                                                                    ) : (
                                                                        <ListItem key={quiz_detail_index}>
                                                                            <Alert icon={<Close fontSize="inherit" />} severity="error" sx={{ marginTop: "10px" }}>
                                                                                <AlertTitle><strong>{markedQuizObj.question}</strong></AlertTitle>
                                                                                {givenAnswerList.map(function (givenAnswer, givenAnswer_index) {
                                                                                    return (<Typography key={givenAnswer_index} variant="body2" gutterBottom><span>&#8226;</span> {givenAnswer}</Typography>)
                                                                                })}

                                                                                {correctAnswerList ? (
                                                                                    <Alert severity="false" color="success" sx={{ marginTop: "10px" }}>
                                                                                        <AlertTitle><strong>Correct Answer</strong></AlertTitle>
                                                                                        {correctAnswerList.map(function (correctAnswer, correctAnswer_index) {
                                                                                            return (<Typography sx={{ color: "green" }} key={correctAnswer_index} variant="body2" gutterBottom><span>&#8226;</span> {correctAnswer}</Typography>)
                                                                                        })}
                                                                                        {correctNote ? (
                                                                                            <Typography variant="subtitle1"><strong>Note : </strong>{correctNote}</Typography>
                                                                                        ) : (<></>)
                                                                                        }
                                                                                    </Alert>
                                                                                ) : (<></>)
                                                                                }


                                                                            </Alert>
                                                                        </ListItem>
                                                                    )
                                                                )
                                                            })}
                                                        </List>
                                                    </Grid>
                                                </PerfectScrollbar>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            ) : (
                                <>
                                    {enableResultView == 1 ? (
                                        <>
                                            <Grid md={8} item>
                                                <Paper
                                                    md={8}
                                                    square
                                                    elevation={0}
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        height: "75vh", minHeight: "75vh",
                                                        p: 2,
                                                        bgcolor: 'background.default',
                                                    }}
                                                >
                                                    <Grid sx={{ textAlign: "center", p: 3 }}>
                                                        {awardCertificate != "" ? (
                                                            <>
                                                                {assignmentType == "COURSE" ? (
                                                                    <>
                                                                        <CardMedia component='img' sx={{ width: "40vw" }} src={`data:image/png;base64, ${awardCertificate}`} />
                                                                        <LoadingButton

                                                                            loading={false}
                                                                            size="small" color="primary"
                                                                            onClick={() => { downloadCertificate(awardCertificate) }}
                                                                        >
                                                                            Download Certificate
                                                                        </LoadingButton>
                                                                    </>
                                                                ) : (
                                                                    <AssessmentAchievement
                                                                        moduleTitle={module_title}
                                                                        assignmentResult={userAttemptHistory[0].isSuccess}
                                                                        assignmentType={assignmentType}
                                                                    />
                                                                )}
                                                            </>
                                                        ) : (
                                                            <Skeleton
                                                                variant="rectangular"
                                                                height={"50vh"} width={"40vw"}
                                                            />
                                                        )
                                                        }



                                                    </Grid>


                                                    <Button onClick={retakeAssessment} sx={{ float: "right" }} variant="outlined" startIcon={<Refresh />}>
                                                        Retake
                                                    </Button>

                                                </Paper>
                                            </Grid>
                                        </>
                                    ) : (
                                        <>
                                            <Grid item md={8} >
                                                <Grid sx={{ marginTop: "16px" }}>
                                                    <strong>{quizList[0].title}</strong>
                                                </Grid>
                                                <Grid >
                                                    <MobileStepper
                                                        variant="progress"
                                                        steps={maxSteps}
                                                        position="static"
                                                        activeStep={activeStep}
                                                        nextButton={
                                                            <Button
                                                                size="small"
                                                                onClick={handleNext}
                                                                disabled={activeStep === maxSteps - 1}
                                                            >
                                                                Next
                                                                {theme.direction === 'rtl' ? (
                                                                    <KeyboardArrowLeft />
                                                                ) : (
                                                                    <KeyboardArrowRight />
                                                                )}
                                                            </Button>
                                                        }
                                                        backButton={
                                                            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                                                {theme.direction === 'rtl' ? (
                                                                    <KeyboardArrowRight />
                                                                ) : (
                                                                    <KeyboardArrowLeft />
                                                                )}
                                                                Back
                                                            </Button>
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Divider />
                                            <Grid md={8} item>

                                                <Paper id="content_viewer"
                                                    md={8}
                                                    square
                                                    elevation={0}
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        height: "30vh", minHeight: "30vw",
                                                        p: 2,
                                                        bgcolor: 'background.default',
                                                    }}
                                                >
                                                    <CourseQuestionView
                                                        quizData={quizList[activeStep]}
                                                        submittedAnswers={assesmentAnswers[quizList[activeStep].quiz_uuid]}
                                                        onAnswerChange={(action, data) => onUpdateQuizAnswers(action, data)} />

                                                    <Grid pt={3}>
                                                        {assesmentErrors.length > 0 && assesmentErrors.map((assesmentError, index) => (<Typography key={index} color="error" variant="body1" gutterBottom>
                                                            {assesmentError}
                                                        </Typography>
                                                        ))}
                                                    </Grid>
                                                    {activeStep == maxSteps - 1 &&
                                                        <LoadingButton sx={{ marginTop: "50px" }}
                                                            fullWidth
                                                            loading={isLoading}
                                                            size="small"
                                                            variant="contained" color="primary"
                                                            onClick={submitAnswers}
                                                        >
                                                            Check My Answers
                                                        </LoadingButton>
                                                    }
                                                </Paper>
                                            </Grid>
                                        </>
                                    )
                                    }
                                </>
                            )
                        }

                    </Grid>
                </Box>
            }

            <DialogAlertBox
                openModal={openAlertDetail.isOpen}
                dataModal={openAlertDetail.data}
                isAction={true}
                maxWidth={"md"}
                fullWidth={false}
                confirmAction={confirmRetakeProcess}
                setOpenModal={() => { setOpenAlertDetail({ "isOpen": false, "data": {} }) }}
            />
        </>
    )

}
