import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useState } from 'react';
import { SortableElement } from 'react-sortable-hoc';
import { DraggableDataTable } from 'ui-component/dragableDatatable';
import DraggableExpandableTableRow from 'ui-component/dragableDatatable/tableExpandableRow';



const DraggableExpandableRow = SortableElement(props => {
    return <DraggableExpandableTableRow {...props} />
});


export default function DataTableExpandable(props) {

    let { row } = props;
    const { headers } = props;
    const { rowIndex } = props;
    const { options } = props;

    const [open, setOpen] = useState(false);

    const getHeaderData = () => {
        let data = { ...row };
        return data
    }
    let rowDetails = getHeaderData();
    return (
        <>
            <DraggableExpandableRow
                {...props}
                id={`item-${rowIndex}`}
                key={`item-${rowIndex}`}
                index={rowIndex}
                rowDetails={rowDetails}
                onRowClick={() => { setOpen(!open) }}
                expandedRow={open}
                tableHeaders={headers['parent']}
            />
            <TableRow sx={{"backgroundColor": "#edf2fa"}}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <DraggableDataTable
                                {...props}
                                tableHeaders={headers['child']} tableData={row[options["child"]]}
                                onDataRearrange={data => {
                                    props.onReArrangeChild(data, row);
                                }} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}


DraggableExpandableTableRow.defaultProps = {
    onDataRearrange: (data) => {  },
    tableStyle: {},
    tableClassName: ''
};