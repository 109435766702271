// material-ui
import { Link, Typography, Stack } from '@mui/material';
import config from 'config';
// ==============================|| FOOTER - AUTHENTICATION 2 & 3 ||============================== //

const AuthFooter = () => (
    <Stack direction="row" justifyContent="space-between">
        <Typography variant="subtitle2" component={Link} href="mailto:helpme@emapta.com?subject=Emapta Academy LMS Request for Support" target="_blank" underline="hover">
            Helpme@emapta.com
        </Typography>
        <Typography variant="subtitle2">
            Version {config.app_version}
        </Typography>
    </Stack>
);

export default AuthFooter;
