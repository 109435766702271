import { setVisitedCoursePages } from "@core/actions/course";
import { setCurrentPage } from "@core/actions/customization";
import { viewSideMenuNavigation } from "@core/actions/sideMenuRoute";
import { graphQLConnectionHandler } from "@core/graphql/graphQLConnectionHandler";
import { FETCH_COURSE_PAGES } from "@core/graphql/queries/course";
import { UPDATE_USER_COURSE_ENROLLMENT } from "@core/graphql/queries/enrollment";
import { FETCH_COURSE_REFERENCE_MATERIAL_AVAILABILITY } from "@core/graphql/queries/referenceMaterials";
import { Navigations } from "@core/helpers/Navigations";
import { COURSE } from "@core/helpers/pageRoutings";
import { LocalLibrarySharp } from "@mui/icons-material";
import { Fab, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { showUserNotification } from "utils";
import "./course-view.css";
import CourseContentView from "./CourseContentView";

export default function CourseView() {

  const { course_uuid } = useParams();
  const [courseDeatils, setCourseDeatils] = useState([]);
  const [initialPage, setInitialPage] = useState("");
  const [maxSteps, setMaxSteps] = useState(0);
  const [pageList, setPageList] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [assignmentUUID, setAssignmentUUID] = useState("");
  const [visitedPages, setVisitedPages] = useState(() => new Set());
  const [visibleReferenceMaterial, setVisibleReferenceMaterial] = useState(false);
  const navigate = useNavigate();


  const loadCourseDetails = () => {
    graphQLConnectionHandler(dispatch, FETCH_COURSE_PAGES({ variables: { course_uuid: course_uuid } }), (err, res) => {

      if (res?.coursePages == null) {
        showUserNotification('Course pages not found', 'WARN');
        // navigate(Navigations.PORTAL);
      } else {
        setCourseDeatils(res.coursePages);
      }
      setLoading(false);
    });
  };
  const onUserCourseAttempts = () => {
    graphQLConnectionHandler(dispatch, UPDATE_USER_COURSE_ENROLLMENT({ variables: { course_uuid: course_uuid } }), (err, res) => {
      if (!err) {
        let coursePages = res.userAttemptToCourse?.page_uuid
        let courseAssessment = res.userAttemptToCourse?.assignment_uuid

        let vistedCoursePages = {
          "pages": coursePages ? coursePages : [],
          "assignments": courseAssessment ? courseAssessment : []
        };

        dispatch(setVisitedCoursePages(vistedCoursePages));
      }

    });
  };
  const checkCourseReferenceMaterialAvailability = () => {
    graphQLConnectionHandler(dispatch, FETCH_COURSE_REFERENCE_MATERIAL_AVAILABILITY({ variables: { course_uuid: course_uuid } }), (err, res) => {
      if (!err) {
        setVisibleReferenceMaterial(res.checkCourseReferenceMaterialAvailability.referenceFileAvailable)
      }
    });
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentPage(COURSE));
    onUserCourseAttempts();
    checkCourseReferenceMaterialAvailability();
    loadCourseDetails();
  }, []);

  useEffect(() => {

    if (courseDeatils !== undefined && courseDeatils.length != 0) {
      let countPages = 0
      let moduleDetails = []
      let allPageList = []
      let modules = courseDeatils?.modules

      for (let modIndex = 0; modIndex < modules.length; modIndex++) {
        const module = modules[modIndex];

        let pageDetails = []
        let coursePages = module.course_pages

        for (let cpIndex = 0; cpIndex < coursePages.length; cpIndex++) {

          let coursePage = coursePages[cpIndex];
          coursePage["module_uuid"] = module["module_uuid"]
          coursePage["module_title"] = module["title"]
          countPages++

          allPageList.push(coursePage)
          if (modIndex == 0 && cpIndex == 0) {
            addVisitedPage(coursePage.page_uuid)
            setInitialPage(coursePage.page_uuid)
          }
          let pageDetail = {
            id: coursePage.page_uuid,
            title: coursePage.title,
            navType: "page",
            type: 'item',
            target: true,
            onTrigger: (action, data) => {
              setAssignmentUUID("")
              setInitialPage(data.id)
              addVisitedPage(data.id)
            }
          }
          pageDetails.push(pageDetail)

        }

        let assignmentDetails = []
        let assignments = module.assignments

        for (let assmntIndex = 0; assmntIndex < assignments.length; assmntIndex++) {

          let assignment = assignments[assmntIndex];
          if (assignment.type == "QUIZ") {

            assignment["module_uuid"] = module["module_uuid"]
            assignment["module_title"] = module["title"]
            assignment["assignment_type"] = "MODULE"
            countPages++
            allPageList.push(assignment)
            let assignmentDetail = {
              id: assignment.assignment_uuid,
              title: assignment.title,
              type: 'item',
              target: true,
              onTrigger: (action, data) => {
                setInitialPage("")
                setAssignmentUUID(data.id)
              }
            }
            assignmentDetails.push(assignmentDetail)
          }
        }

        let moduleDetail = {
          id: module.module_uuid,
          navType: "module",
          title: module.title,
          type: module.course_pages.length > 0 ? "collapse" : "item",
        }

        if (moduleDetail.type == "collapse") {
          moduleDetail["children"] = pageDetails.concat(assignmentDetails)
        }

        moduleDetails.push(moduleDetail)

      };

      let assignments = courseDeatils?.assignments

      for (let assmntIndex = 0; assmntIndex < assignments.length; assmntIndex++) {

        let assignment = assignments[assmntIndex];
        if (assignment.type == "ASSESSMENT") {

          assignment["module_uuid"] = courseDeatils["course_uuid"]
          assignment["module_title"] = courseDeatils["course_name"]
          assignment["assignment_type"] = "COURSE"
          countPages++
          allPageList.push(assignment)
          let assignmentDetail = {
            id: assignment.assignment_uuid,
            navType: "assignment",
            title: assignment.title,
            type: 'item',
            target: true,
            onTrigger: (action, data) => {
              setInitialPage("")
              setAssignmentUUID(data.id)
            }
          }
          moduleDetails.push(assignmentDetail)

        }
      }

      setMaxSteps(countPages)

      const groupLevel = [
        {
          id: 'top-group-level',
          type: 'group',
          children: moduleDetails
        }
      ]

      setPageList(allPageList)
      dispatch(viewSideMenuNavigation(groupLevel));

    }
  }, [courseDeatils]);


  const addVisitedPage = item => {
    setVisitedPages(prev => new Set(prev).add(item));
  }

  const removeVisitedPage = item => {
    setVisitedPages(prev => {
      const next = new Set(prev);

      next.delete(item);

      return next;
    });
  }

  return (
    <>
      {(initialPage !== "" || assignmentUUID !== "") &&
        <CourseContentView
          course_uuid={courseDeatils.course_uuid}
          page_uuid={initialPage}
          assignment_uuid={assignmentUUID}
          maxSteps={maxSteps}
          pageList={pageList} />
      }
      {visibleReferenceMaterial &&
        <Tooltip title={`${courseDeatils["course_name"]} reference material`} placement="top">
          <Fab variant="circular" style={{
            margin: 0, top: 'auto', right: 35, bottom: 35,
            left: 'auto', position: 'fixed',
          }} size="small" sx={{ height: 40, width: 40 }} color="secondary" aria-label="library" onClick={() => { navigate(Navigations.LIBRARY, { state: { course_uuid: course_uuid, course_name: courseDeatils["course_name"] } }) }}>
            <LocalLibrarySharp size="small" style={{ fontSize: '25px' }} />
          </Fab>
        </Tooltip>
      }
    </>
  );
}
