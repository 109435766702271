import { Button, Grid, InputAdornment, OutlinedInput, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import PropTypes, { object } from 'prop-types';
import { useCallback, useEffect, useState } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMove } from "utils";
import DraggableTableRow from "./tableRow";
import tableStyle from './dragableDatatable.module.css';
import { IconSearch } from "@tabler/icons";
import { useTheme } from '@mui/material/styles';
import { Add } from "@mui/icons-material";

const DraggableTableContainer = SortableContainer(({ children }) => {
    return <TableBody>{children}</TableBody>;
});

const DraggableDataRow = SortableElement(props => {
    return <DraggableTableRow {...props} />
});

const DraggableDataTable = (props) => {
    const [items, setItems] = useState(props.tableData);
    const [filteredItems, setFilteredItems] = useState(props.tableData);
    const [searchText, setSearchText] = useState('');
    const theme = useTheme();

    const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
        setItems(oldItems => {
            let data = arrayMove(oldItems, oldIndex, newIndex);
            props.onDataRearrange(data);
            return data;
        });
    }, []);

    useEffect(() => {
        setItems(props.tableData)
        setFilteredItems(props.tableData)
    }, [props.tableData])

    const onFilterDataset = (filterQuery = '') => {
        if (filterQuery == '') {
            setFilteredItems(items);
            return;
        }
        let filterData = items.filter(item => {
            let matched = false;
            for (var value of Object.values(item)) {
                if ((typeof value === 'string' || value instanceof String) && value.toLowerCase().includes(filterQuery)) {
                    matched = true;
                    break;
                }
            }
            return matched;
        });
        setFilteredItems(filterData);
    }

    return (
        <>

            <Grid container spacing={2}>
                <Grid item xs={4}>
                    {props.childOption.map((option) => {
                        return (
                            <Button key={option.id} onClick={() =>
                                option.onTrigger(props.row)
                            } startIcon={option.icon ?? <Add />}>{option.label}</Button>
                        )
                    })}

                </Grid>
                <Grid item xs={6}>
                    <OutlinedInput
                        size="small"
                        sx={{ width: '50%', pr: 1, pl: 2, my: 2, float: 'right' }}
                        id="input-search-profile"
                        value={searchText}
                        onChange={(event) => {
                            let filterQuery = event.target.value?.trim().toLowerCase();
                            onFilterDataset(filterQuery);
                            setSearchText(filterQuery);
                        }}
                        placeholder={props.tableDataFilterPlaceholder ?? 'Search course pages'}
                        align="centre|right"
                        startAdornment={
                            <InputAdornment position="start">
                                <IconSearch stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
                            </InputAdornment>
                        }
                        aria-describedby="search-helper-text"
                        inputProps={{
                            'aria-label': ''
                        }}
                    />
                </Grid>
            </Grid>



            <Table className={props.tableClassName}>
                <TableHead>
                    <TableRow>
                        {
                            Object.values(props.tableHeaders).map(value => {
                                return <TableCell key={value.name}>{value.name}</TableCell>
                            })
                        }
                    </TableRow>
                </TableHead>
                <DraggableTableContainer
                    onSortEnd={onSortEnd}
                    axis="y"
                    lockAxis="y"
                    lockToContainerEdges={true}
                    lockOffset={["30%", "50%"]}
                    helperClass={tableStyle.helperContainerClass}
                    useDragHandle={true}
                >
                    {filteredItems.map((value, index) => {
                        return (
                            <DraggableDataRow
                                {...props}
                                id={`item-${index}`}
                                key={`item-${index}`}
                                index={index}
                                rowDetails={value}
                                tableHeaders={props.tableHeaders}
                            />
                        )
                    })}
                </DraggableTableContainer>
            </Table>
        </>
    );
};

DraggableDataTable.propTypes = {
    onDataRearrange: PropTypes.func.isRequired,
    tableHeaders: PropTypes.object.isRequired,
    tableData: PropTypes.arrayOf(object).isRequired,
    tableStyle: PropTypes.object,
    tableClassName: PropTypes.string,
    tableDataFilterPlaceholder: PropTypes.string,
    childOption: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        action: PropTypes.string,
        label: PropTypes.string.isRequired,
        value: PropTypes.any,
        onTrigger: PropTypes.func.isRequired,
        type: PropTypes.oneOf(['BUTTON', 'SWITCH']),
        icon: PropTypes.any,
    }))
};

DraggableTableRow.defaultProps = {
    onDataRearrange: (data) => {  },
    tableStyle: {},
    tableClassName: ''
};

export default DraggableDataTable;
