import { graphQLConnectionHandler } from "@core/graphql/graphQLConnectionHandler";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showUserNotification } from "utils";
import * as React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { FETCH_COURSE_CONTENT } from "@core/graphql/queries/coursePage";
import { Divider, Grid } from "@mui/material";
import "./course-view.css";
import CourseAssesmentView from "./CourseAssesmentView";
import { useMenuItemChanged } from "hooks/utilityHooks";
import "suneditor/dist/css/suneditor.min.css";
import { setDoneCourseNavPage, setVisitedCoursePages } from "@core/actions/course";
import { fi } from "date-fns/locale";
import { UPSERT_USERCOURSE_PAGE } from "@core/graphql/queries/userCoursePage";

export default function CourseContentView(props) {

    const theme = useTheme();
    const onContentPageChanged = useMenuItemChanged();
    let { maxSteps, pageList, page_uuid, assignment_uuid, course_uuid } = props
    const [activeStep, setActiveStep] = useState(0);
    const [assignmentUUID, setAssignmentUUID] = useState("");
    const [enableAssignmentView, setEnableAssignmentView] = useState(false);
    const [pageContent, setPageContent] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const dispatch = useDispatch();
    let courseSelector = useSelector((state) => state.course);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const loadCourseContent = () => {
        graphQLConnectionHandler(dispatch, FETCH_COURSE_CONTENT(
            {
                variables: { page_uuid: page_uuid }
            }), (err, res) => {
                if (res?.courseContent == null) {
                    showUserNotification('Course pages not found', 'WARN');
                } else {
                    setPageContent([res.courseContent])
                }
                setLoading(false);
            });
    };



    useEffect(() => {
        if (page_uuid) {
            setEnableAssignmentView(false)
            setActiveStep(pageList.findIndex(o => o.page_uuid === page_uuid));
            loadCourseContent()
        }
    }, []);


    useEffect(() => {
        if (assignmentUUID) {
            setEnableAssignmentView(true)
            setActiveStep(pageList.findIndex(o => o.assignment_uuid === assignmentUUID));
        } else{
            setActiveStep(pageList.findIndex(o => o.page_uuid === page_uuid));
            setEnableAssignmentView(false)
        }
    }, [assignmentUUID]);

    useEffect(() => {
        if ("page_uuid" in pageList[activeStep]) {
            page_uuid = pageList[activeStep]?.page_uuid
            let moduleID = pageList[activeStep]?.module_uuid
          
            loadCourseContent()
            setEnableAssignmentView(false)
            onContentPageChanged(page_uuid, moduleID);

            if(courseSelector.visitedCoursePages?.pages){
                let vistedCoursePages = courseSelector.visitedCoursePages;
                if(!vistedCoursePages["pages"].includes(page_uuid)){
                    vistedCoursePages["pages"].push(page_uuid)
                    dispatch(setVisitedCoursePages(vistedCoursePages));
                    graphQLConnectionHandler(dispatch, UPSERT_USERCOURSE_PAGE({
                        variables: { 
                            course_uuid: course_uuid, 
                            page_uuid: courseSelector.visitedCoursePages?.pages,
                            assignment_uuid: courseSelector.visitedCoursePages?.assignments
                        }
                    }), (err, res) => {

                    })
                }
            }
            setActiveStep(pageList.findIndex(o => o.page_uuid === page_uuid));
        }
        else if ("assignment_uuid" in pageList[activeStep]) {
            let assignmentID = pageList[activeStep]?.assignment_uuid;
            let moduleID = pageList[activeStep]?.module_uuid
            setEnableAssignmentView(true)
            setAssignmentUUID(assignmentID);
            onContentPageChanged(assignmentID, moduleID);
            if(courseSelector.visitedCoursePages?.assignments){
                let vistedCoursePages = courseSelector.visitedCoursePages;
                if(!vistedCoursePages["assignments"].includes(assignmentID)){
                    vistedCoursePages["assignments"].push(assignmentID)
                    dispatch(setVisitedCoursePages(vistedCoursePages));
                    graphQLConnectionHandler(dispatch, UPSERT_USERCOURSE_PAGE({
                        variables: { 
                            course_uuid: course_uuid, 
                            page_uuid: courseSelector.visitedCoursePages?.pages,
                            assignment_uuid: courseSelector.visitedCoursePages?.assignments
                        }
                    }), (err, res) => {

                    })
                }
            }
        }
    }, [activeStep]);


    useEffect(() => {
        if (page_uuid) {
            loadCourseContent()
            setActiveStep(pageList.findIndex(o => o.page_uuid === page_uuid));
        }
    }, [page_uuid]);

    useEffect(() => {
        if (assignment_uuid) {
            setActiveStep(pageList.findIndex(o => o.assignment_uuid === assignment_uuid));
        }
    }, [assignment_uuid]);

    return (
        <>
            {pageContent.length > 0 &&

                <Box sx={{ flexGrow: 1 }}>
                    <Paper>
                        <Paper
                            square
                            elevation={0}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: 50,
                                pl: 2,
                                bgcolor: 'background.default',
                            }}
                        >

                            <Grid container spacing={1}>
                                <Grid item xs={9} sx={{ marginTop: "16px" }}>
                                    <strong>{pageList[activeStep]?.title}</strong>

                                </Grid>
                                <Grid item xs={3}>
                                    <MobileStepper
                                        variant="text"
                                        steps={maxSteps}
                                        position="static"
                                        activeStep={activeStep}
                                        nextButton={
                                            <Button
                                                size="small"
                                                onClick={handleNext}
                                                disabled={activeStep === maxSteps - 1}
                                            >
                                                Next
                                                {theme.direction === 'rtl' ? (
                                                    <KeyboardArrowLeft />
                                                ) : (
                                                    <KeyboardArrowRight />
                                                )}
                                            </Button>
                                        }
                                        backButton={
                                            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                                {theme.direction === 'rtl' ? (
                                                    <KeyboardArrowRight />
                                                ) : (
                                                    <KeyboardArrowLeft />
                                                )}
                                                Back
                                            </Button>
                                        }
                                    />
                                </Grid>
                            </Grid>

                        </Paper>
                        <Divider />
                        {!enableAssignmentView ? (

                            <Grid sx={{ justifyContent: "center" }} >
                                <Paper id="content_viewer"
                                    square
                                    elevation={0}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: "36vh", minHeight: "36vw",
                                        minWidth: "50vh",
                                        p: 2,
                                        bgcolor: 'background.default',
                                    }}
                                    className={"sun-editor-editable"}
                                    dangerouslySetInnerHTML={{ __html: pageContent[0]?.content }}
                                >
                                </Paper>
                            </Grid>

                        ) : (
                            <CourseAssesmentView
                                course_uuid={course_uuid}
                                assignment_uuid={assignmentUUID}
                                module_title={pageList[activeStep]["module_title"]}
                                assignmentType={pageList[activeStep]["assignment_type"]}
                                 />
                        )}
                    </Paper>
                </Box>
            }
        </>
    )

}