
import { CLEAR_SELECTED_COURSE, SET_CURRENT_CONTENT_PAGE, SET_CURRENT_MODULE, SET_SELECTED_COURSE, SET_VISITED_COURSE_PAGES, UPDATE_SELECTED_COURSE } from "./types";


const publishOpenContentPageEvent = (pageUUID) => {
    return {
        type: SET_CURRENT_CONTENT_PAGE,
        pageUUID: pageUUID,
    }
};

const publishVisitedCoursePagesEvent = (visitedCoursePages) => {
    return {
        type: SET_VISITED_COURSE_PAGES,
        visitedCoursePages: visitedCoursePages,
    }
};

const publishOpenModuleEvent = (moduleUUID) => {
    return {
        type: SET_CURRENT_MODULE,
        moduleUUID: moduleUUID,
    }
};

const publishUserCourseSelectionEvent = (courseData) => {
    return {
        type: SET_SELECTED_COURSE,
        course_name: courseData?.course_name ?? "",
        course_uuid: courseData?.course_uuid ?? "",
        description: courseData?.description ?? "",
        status: courseData?.status ?? "",
        is_autoEnrolled: courseData?.is_autoEnrolled ?? false,
        course_published: courseData?.course_published ?? true,
    }
}

const publishUserCourseSelectionUpdateEvent = (course_uuid) => {
    return {
        type: UPDATE_SELECTED_COURSE,
        course_name: "",
        course_uuid: course_uuid,
        description: "",
        status: "",
        is_autoEnrolled: false,
        course_published: true,
    }
}

const publishUserCourseSelectionClearEvent = () => {
    return {
        type: CLEAR_SELECTED_COURSE,
        course_name: "",
        course_uuid: "",
        description: "",
        status: "",
        is_autoEnrolled: false,
        course_published: true,
    }
}

export const openContentPage = (pageUUID) => (dispatch) => {
    return dispatch(publishOpenContentPageEvent(pageUUID));
};

export const openModule = (moduleUUID) => (dispatch) => {
    return dispatch(publishOpenModuleEvent(moduleUUID));
};

export const setSelectedCourse = (courseData) => (dispatch) => {
    return dispatch(publishUserCourseSelectionEvent(courseData));
}

export const setVisitedCoursePages = (visitedCoursePages) => (dispatch) => {
    return dispatch(publishVisitedCoursePagesEvent(visitedCoursePages));
}

export const updateUserSelectedCourse = (course_uuid) => (dispatch) => {
    return dispatch(publishUserCourseSelectionUpdateEvent(course_uuid));
}

export const clearUserSelectedCourse = () => (dispatch) => {
    return dispatch(publishUserCourseSelectionClearEvent());
}