import { DYNAMIC_SIDE_MENU_NAVIGATION_CHANGE, ON_ACTIVE_SIDE_MENU_COMPONENT_CHANGE, SET_NAV_COURSE_VIEW } from "../actions/types";
import courseSideMenuItems, { adminUserSideMenu } from 'menu-items';
import { SideMenuTypes } from "@core/helpers/Navigations";


function updateDynamicRoutings(data, routerID, newUrl) {

    if (routerID == undefined)
        return data;
    let items = data;
    let updatedRoutes = [];

    for (let i = 0; i < items.length; i++) {
        let newItem = items[i];
        if (newItem?.children) {
            newItem.children = getUpdatedSubRouting(newItem.children, routerID, newUrl);
        } else if (newItem?.id === routerID) {
            newItem.url = newUrl;
        }
        updatedRoutes.push(newItem);
    }
    return updatedRoutes;
}

function getUpdatedSubRouting(subRoutes, routerID, newUrl) {
    let updatedRoutes = [];
    for (let i = 0; i < subRoutes.length; i++) {
        let menuItem = subRoutes[i];
        if (menuItem?.children) {
            menuItem.children = getUpdatedSubRouting(menuItem.children, routerID, newUrl);
        } else if (menuItem?.id === routerID) {
            menuItem.url = newUrl;
        }
        updatedRoutes.push(menuItem);
    }
    return updatedRoutes;
}

export default function (state = {
    activeSideMenu: SideMenuTypes.COURSE,
    items: courseSideMenuItems,
    course: courseSideMenuItems,
    admin_users: adminUserSideMenu,
}, action) {
    const { type, routerID, url, activeSideMenuType, viewNavigation } = action;

    switch (type) {
        case DYNAMIC_SIDE_MENU_NAVIGATION_CHANGE:
            let routeData = state[state.activeSideMenu];
            let updatedRoutes = updateDynamicRoutings(routeData, routerID, url);
            let currentSideMenu = {};
            currentSideMenu[state.activeSideMenu] = updatedRoutes;
            return { ...state, items: updatedRoutes, ...currentSideMenu };
        case ON_ACTIVE_SIDE_MENU_COMPONENT_CHANGE:
            return { ...state, activeSideMenu: activeSideMenuType, items: state[activeSideMenuType] }
        case SET_NAV_COURSE_VIEW:
                return { ...state, items: viewNavigation };
        default:
            return state;
    }
}