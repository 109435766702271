import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useEffect } from 'react';
import { useTheme } from '@emotion/react';
import { Typography } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogAlertBox(props) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [body, setBody] =  React.useState({});

    const handleClose = () => {
        setOpen(false);
        props.setOpenModal(false)
    };

    const handleAgree = () => {
        setOpen(false);
        props.setOpenModal(false)
        props.confirmAction(true)
    };

    useEffect(() => {
        setOpen(props.openModal);
    }, [props.openModal]);

    useEffect(() => {
        setBody(props.dataModal)
    }, [props.dataModal]);

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            maxWidth={props.maxWidth}
            fullWidth={props.fullWidth}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle variant="h4">{body.title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description" sx={{color:"black", textAlign:"justify", textJustify:"inter-word"}} dangerouslySetInnerHTML={{ __html: body.body}} />
            </DialogContent>
            { props.isAction &&
            <DialogActions>
                <Button color={"error"} onClick={handleClose}>Disagree</Button>
                <Button onClick={handleAgree}>Agree</Button>
            </DialogActions>
}
        </Dialog>
    
    );
}