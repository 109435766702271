import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { Navigate } from 'react-router';
import { Navigations } from '@core/helpers/Navigations';
import CourseHome from 'pages/course/CourseHome';
import CourseView from 'pages/course/viewer/CourseView';
import CourseContentPortal from 'pages/course/CourseContentPortal';
import CourseDetail from 'pages/course/CourseDetail';

// dashboard routing
const CoursePortal = Loadable(lazy(() => import('pages/course/CoursePortal')));
const ELibary = Loadable(lazy(() => import('pages/course/ELibrary')));
const CourseContent = Loadable(lazy(() => import('pages/course/CourseContent')));
const CourseReport = Loadable(lazy(() => import('pages/course/CourseReport')));
const CourseAssignment = Loadable(lazy(() => import('pages/course/CourseAssignment')));
const CourseAssignmentPortal = Loadable(lazy(() => import('pages/course/CourseAssignmentPortal')));
const CourseUserEnrollment = Loadable(lazy(() => import('pages/course/CourseUserEnrollment')));
const CourseReferrenceMaterials = Loadable(lazy(() => import('pages/course/CourseReferrenceMaterials')));
const Users = Loadable(lazy(() => import('pages/admin/users')));
const Category = Loadable(lazy(() => import('pages/admin/category')));
const UserCourseEnrollments = Loadable(lazy(() => import('pages/admin/userCourseEnrollments')));
const AdminReferenceMaterials = Loadable(lazy(() => import('pages/admin/referenceMaterials')));
const AdminCourseReports = Loadable(lazy(() => import('pages/admin/adminCourseReports')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = (isLoggedIn, isAdmin) => [{
    path: '/',
    element: isLoggedIn ? <MainLayout /> : <Navigate to="/" />,
    children: [
        {
            path: Navigations.PORTAL,
            element: <CoursePortal />
        },
        {
            path: Navigations.LIBRARY,
            element: <ELibary />
        },
        {
            path: Navigations.COURSE,
            children: [
                {
                    path: '/:course_uuid',
                    element: <CourseHome />
                },
                {
                    path: '/detail/:course_uuid',
                    element: <CourseDetail />
                },
                {
                    path: '/view/:course_uuid',
                    element: <CourseView />
                },
                {
                    path: '/content/:course_uuid',
                    element: <CourseContentPortal />
                },
                {
                    path: '/content/:course_uuid/pages',
                    element: <CourseContent />
                },
                {
                    path: '/assignment/:course_uuid',
                    element: <CourseAssignmentPortal />
                },
                {
                    path: '/report/:course_uuid',
                    element: <CourseReport />
                },
                {
                    path: '/user-enrollment/:course_uuid',
                    element: <CourseUserEnrollment />
                },
                {
                    path: '/e-materials/:course_uuid',
                    element: <CourseReferrenceMaterials />
                },
                {
                    path: '/assignment/:course_uuid/quiz',
                    element: <CourseAssignment />
                },
            ]

        },
        {
            path: Navigations.ADMIN,
            children: [
                {
                    path: Navigations.USERS,
                    element: <Users />
                },
                {
                    path: Navigations.ADMIN_USER_COURSE_ENROLLMENT,
                    element: <UserCourseEnrollments />
                },
                {
                    path: Navigations.ADMIN_USER_REFERENCE_MATERIALS,
                    element: <AdminReferenceMaterials />
                },
                {
                    path: Navigations.CATEGORY,
                    element: <Category />
                },
                {
                    path: Navigations.ADMIN_COURSE_REPORTS,
                    element: <AdminCourseReports />
                },
            ]

        },
        {
            path: "*",
            element:<Navigate to="/" />

        }
    ]
}];

export default MainRoutes;
