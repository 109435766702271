
import { SideMenuTypes } from "@core/helpers/Navigations";
import { DYNAMIC_SIDE_MENU_NAVIGATION_CHANGE, ON_ACTIVE_SIDE_MENU_COMPONENT_CHANGE, SET_NAV_COURSE_VIEW } from "./types";


const publishSubRoutingChangeEvent = (routerID, url) => {
    return {
        type: DYNAMIC_SIDE_MENU_NAVIGATION_CHANGE,
        routerID: routerID,
        url: url,
    }
};

const publishViewSideMenuNavigationEvent = (viewNavigation) => {
    return {
        type: SET_NAV_COURSE_VIEW,
        viewNavigation: viewNavigation,
    }
};



const publishSideMenuTypeChange = (activeSideMenuType) => {
    return {
        type: ON_ACTIVE_SIDE_MENU_COMPONENT_CHANGE,
        activeSideMenuType: activeSideMenuType
    }
};
export const updateSideMenuRouting = (routerID, url) => (dispatch) => {
    return dispatch(publishSubRoutingChangeEvent(routerID, url));
};

export const updateSideMenuType = (activeSideMenuType = SideMenuTypes.COURSE) => (dispatch) => {
    return dispatch(publishSideMenuTypeChange(activeSideMenuType));
};

export const viewSideMenuNavigation = (viewNavigation) => (dispatch) => {
    return dispatch(publishViewSideMenuNavigationEvent(viewNavigation));
};