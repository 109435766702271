import { graphQLConnectionHandler } from '@core/graphql/graphQLConnectionHandler';
import { FETCH_COURSE_REFERENCE_MATERIAL_DOWNLOAD_LINK, UPDATE_COURSE_REFERENCE_MATERIAL } from '@core/graphql/queries/referenceMaterials';
import { CloudDownloadOutlined, NoteAdd } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormHelperText, Grid, IconButton, InputLabel, MenuItem, OutlinedInput, Select, Tooltip, Typography } from "@mui/material";
import { useFormik } from 'formik';
import useForceUpdate from 'hooks/useForceUpdate';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import FileUpload from 'react-material-file-upload';
import { useDispatch } from 'react-redux';
import { convertToBase64, downloadDataFile, showPromiseNotification } from 'utils';
import { capitalizeFirstLetter } from 'utils/stringManupulations';
import * as Yup from 'yup';

const ValidationSchema = Yup.object().shape({
    title: Yup.string().max(300).required('Title is required'),
    status: Yup.string().max(100).required('Status is required'),
    version: Yup.string().max(100).required('Version is required'),
    attachment: Yup.string().required('Attachment is required'),
    description: Yup.string().max(1000, "Description should be less than 3000 characters").min(10, "Description should be more than 10 characters").required('Description is required')
});

const CourseReferenceMaterialModal = (props) => {
    const dispatch = useDispatch();
    const [files, setFiles] = useState([]);
    const [message, setMessage] = useState("");
    const [pendingRequest, setPendingRequest] = useState(false);
    const [showDownloadLink, setDownloadLinkVisibility] = useState(false);
    const [downloadLink, setDownloadLink] = useState(null)

    const forceUpdate = useForceUpdate();

    const [initFormData, setInitFormData] = useState({
        title: props.referenceMaterial?.title || '',
        status: props.referenceMaterial?.status || '',
        version: props.referenceMaterial?.version || '',
        description: props.referenceMaterial?.description || '',
        attachment: props.referenceMaterial?.resource_path || ''
    });

    useEffect(() => {
        if (props.action != 'ADD') {
            getDownloadUrl();
        }
    }, []);

    const getUploadFile = async () => {
        if (files.length > 0) {
            return await convertToBase64(files[0]);
        }
        return null;
    }

    const getDownloadUrl = () => {
        let gqlVaribles = {
            variables: {
                "filename": props.referenceMaterial?.resource_path,
            }
        }
        graphQLConnectionHandler(dispatch, FETCH_COURSE_REFERENCE_MATERIAL_DOWNLOAD_LINK(gqlVaribles), (error, response) => {
            if (!error && response.referenceMaterialsDownloadLink.status == 200) {
                setDownloadLink(response.referenceMaterialsDownloadLink);
                setDownloadLinkVisibility(true);
            }
        });
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initFormData,
        validationSchema: ValidationSchema,
        onSubmit: async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
            try {
                setPendingRequest(true);
                let attachment = await getUploadFile();
                let gqlVaribles = {
                    variables: {
                        "courseReferenceMaterial": {
                            action: props.action,
                            reference_uuid: props.referenceMaterial?.reference_uuid || '',
                            title: values.title,
                            description: values.description,
                            version: values.version,
                            status: values.status,
                            course_uuid: props.course_uuid || props.referenceMaterial?.course_uuid ,
                            attachment: attachment
                        },
                    }
                }

                let referenceMaterialUploadRequest = new Promise((resolve, reject) => {
                    graphQLConnectionHandler(dispatch, UPDATE_COURSE_REFERENCE_MATERIAL(gqlVaribles), (error, response) => {
                        setPendingRequest(false);
                        if (error != null) {
                            reject('Reference Material upload failed');
                        } else {
                            if (response.updateCourseReferenceMaterial.id == 200)
                                resolve('Reference Material successfully ' + (props.action === 'ADD' ? 'attached' : 'updated'));
                            else {
                                reject("Reference material attachment failed due to " + response.updateCourseReferenceMaterial.response);
                            }
                        }
                    });
                });
                let notification = props.action === 'ADD' ? ' Uploading course referece material ...' : ' Updating course reference material';
                showPromiseNotification(referenceMaterialUploadRequest, notification, 'Internal error occurred while processing course reference materials. Please try again later.');

            } catch (err) {
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
            }
        },
    });

    return (
        <Dialog
            fullWidth={true}
            open={props.showPopup}
            onClose={() => {
                if (props.backdropCloseEnable) {
                    props.onClose();
                }
            }}
            maxWidth="sm"
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                <div>
                    <Typography variant="subtitle1">
                        <b>{capitalizeFirstLetter(props.action)}</b> course reference material [<b> {props.course?.course_name || props.referenceMaterial?.course_uuid} </b>]
                    </Typography>
                </div>
                <IconButton
                    aria-label="close"
                    onClick={() => {
                        props.onClose();
                    }}
                    disabled={pendingRequest}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <Divider></Divider>
            <DialogContent>
                <form noValidate onSubmit={formik.handleSubmit} >
                    <FormControl
                        fullWidth
                        size="medium" sx={{ mb: 2 }}
                        error={Boolean(formik.touched.title && formik.errors.title)}
                    >
                        <InputLabel htmlFor="outlined-adornment-course-material-title">Title</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-course-material-title"
                            type="text"
                            value={formik.values.title}
                            name="title"
                            size="medium"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            label="Title"
                            inputProps={{}}
                        />
                        {formik.touched.title && formik.errors.title && (
                            <FormHelperText error id="standard-weight-helper-text-course-material-title">
                                {formik.errors.title}
                            </FormHelperText>
                        )}
                    </FormControl>
                    <FormControl
                        fullWidth
                        size="medium" sx={{ mb: 2 }}
                        error={Boolean(formik.touched.description && formik.errors.description)}
                    >
                        <InputLabel htmlFor="outlined-adornment-course-material-description">Description</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-course-material-description"
                            type="text"
                            size="medium"
                            value={formik.values.description}
                            name="description"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            label="Description"
                            multiline={true}
                            rows={4}
                            inputProps={{}}
                        />
                        {formik.touched.description && formik.errors.description && (
                            <FormHelperText error id="standard-weight-helper-text-course-material-description">
                                {formik.errors.description}
                            </FormHelperText>
                        )}
                    </FormControl>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <FormControl size="medium" sx={{ mb: 2 }} fullWidth error={Boolean(formik.touched.status && formik.errors.status)}>
                                <InputLabel htmlFor="outlined-adornment-course-material-status">Status</InputLabel>
                                <Select
                                    id="outlined-adornment-course-material-status"
                                    type="text"
                                    size="medium"
                                    value={formik.values.status}
                                    name="status"
                                    onBlur={formik.handleBlur}
                                    onChange={(event) => {
                                        formik.values.status = event.target.value;
                                        forceUpdate();
                                    }}
                                    label="Status"
                                    inputProps={{}}
                                >
                                    <MenuItem value={"ACTIVE"}>ACTIVE</MenuItem>
                                    <MenuItem value={"INACTIVE"}>INACTIVE</MenuItem>
                                </Select>
                                {formik.touched.status && formik.errors.status && (
                                    <FormHelperText error id="standard-weight-helper-text-course-material-status">
                                        {formik.errors.status}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl
                                fullWidth size="medium" sx={{ mb: 2 }}
                                error={Boolean(formik.touched.version && formik.errors.version)}
                            >
                                <InputLabel htmlFor="outlined-adornment-course-material-version">Version</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-course-material-version"
                                    type="text"
                                    value={formik.values.version}
                                    name="version"
                                    size="medium"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    label="Version"
                                    inputProps={{}}
                                />
                                {formik.touched.version && formik.errors.version && (
                                    <FormHelperText error id="standard-weight-helper-text-course-material-version">
                                        {formik.errors.version}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                    </Grid>

                    <FileUpload value={files} accept={[".pdf"]} title="Drag 'n' drop pdf file here, or click to select files" onChange={(files) => {
                        setFiles(files);
                        formik.setFieldValue('attachment', files.length > 0 ? files[0].path : "");
                    }} />
                    {formik.touched.attachment && formik.errors.attachment && (
                        <FormHelperText error id="standard-weight-helper-text-course-material-attachment">
                            {formik.errors.attachment}
                        </FormHelperText>
                    )}

                    {formik.errors.submit || message && (
                        <Box sx={{ mt: 1 }} >
                            <FormHelperText error style={{ textAlign: "center" }}>{formik.errors.submit ?? message}</FormHelperText>
                        </Box>
                    )}
                    <DialogActions>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Box>
                                <LoadingButton
                                    loading={pendingRequest}
                                    loadingPosition="start"
                                    disableElevation
                                    disabled={pendingRequest}
                                    fullWidth
                                    startIcon={<NoteAdd />}
                                    size="medium"
                                    type="submit"
                                    variant="contained"
                                >
                                    {capitalizeFirstLetter(props.action)}
                                </LoadingButton>
                            </Box>
                            {showDownloadLink &&
                                <Tooltip title="Download Existing Attachment" placement="top">
                                    <IconButton sx={{ mt: 1, ml: 2 }} color="primary" aria-label="download reference material" component="span" onClick={() => {
                                        downloadDataFile(downloadLink.url,downloadLink.filename);
                                    }}>
                                        <CloudDownloadOutlined sx={{ fontSize: 35 }} />
                                    </IconButton>
                                </Tooltip>
                            }
                        </div>
                    </DialogActions>
                </form>
            </DialogContent>
        </Dialog>
    );
}

CourseReferenceMaterialModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    backdropCloseEnable: PropTypes.bool,
    showPopup: PropTypes.bool,
    referenceMaterial: PropTypes.object.isRequired,
    course_uuid: PropTypes.string.isRequired,
    course: PropTypes.object.isRequired,
    action: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

CourseReferenceMaterialModal.defaultProps = {
    backdropCloseEnable: false,
    action: 'ADD'
};
export default CourseReferenceMaterialModal;