import * as React from 'react';
import { styled } from '@mui/system';
import SwitchUnstyled, { switchUnstyledClasses } from '@mui/base/SwitchUnstyled';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';

const grey = {
    400: '#BFC7CF',
    500: '#AAB4BE'
};

const UnstyledSwitches = (props) => {

    let color = {
        500: props.color ? props.color : "#007FFF"
    };

    const Root = styled('span')`
        font-size: 0;
        position: relative;
        display: inline-block;
        width: 40px;
        height: 20px;
        margin: 10px;
        cursor: pointer;

        &.${switchUnstyledClasses.disabled} {
            opacity: 0.4;
            cursor: not-allowed;
        }

        & .${switchUnstyledClasses.track} {
            background: ${grey[400]};
            border-radius: 10px;
            display: block;
            height: 100%;
            width: 100%;
            position: absolute;
        }

        & .${switchUnstyledClasses.thumb} {
            display: block;
            width: 14px;
            height: 14px;
            top: 3px;
            left: 3px;
            border-radius: 16px;
            background-color: #fff;
            position: relative;
            transition: all 200ms ease;
        }

        &.${switchUnstyledClasses.focusVisible} .${switchUnstyledClasses.thumb} {
            background-color: ${grey[500]};
            box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
        }

        &.${switchUnstyledClasses.checked} {
            .${switchUnstyledClasses.thumb} {
                left: 22px;
                top: 3px;
                background-color: #fff;
            }

            .${switchUnstyledClasses.track} {
                background: ${color[500]};
            }
        }

        & .${switchUnstyledClasses.input} {
            cursor: inherit;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0;
            z-index: 1;
            margin: 0;
        }
    `;
    const label = { componentsProps: { input: { 'aria-label': props.inputLabel } } };

    const getFormattedSwitch = () => {
        switch (props.labelPosition) {
            case 'start':
                return (
                    <>
                        <Typography>{props.inputLabel}</Typography>
                        <SwitchUnstyled component={Root} {...label} checked={props.triggerChange} onChange={() => props.onChange()} />
                    </>);
            default:
                return (
                    <>
                        <SwitchUnstyled component={Root} {...label} checked={props.triggerChange} onChange={() => props.onChange()} />
                        <Typography>{props.inputLabel}</Typography>
                    </>);
        }
    }

    return (
        <>
            <Stack direction="row" spacing={1} alignItems="center">
                {getFormattedSwitch()}
            </Stack>
        </>
    );
};

UnstyledSwitches.propTypes = {
    onChange: PropTypes.func.isRequired,
    enableEdit: PropTypes.bool,
    labelPosition: PropTypes.oneOf(['start', 'end']).isRequired,
};

UnstyledSwitches.defaultProps = {
    enableEdit: false,
    labelPosition: 'end',
};

export default UnstyledSwitches;
