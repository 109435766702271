import { CLEAR_SELECTED_COURSE, SET_SELECTED_COURSE, UPDATE_SELECTED_COURSE } from "../actions/types";

const initialState = {
    'course_name': "",
    'course_uuid': "",
    'description': "",
    'status': "",
    'is_autoEnrolled': "",
    'course_published': true,
}

export default function (state = initialState, action) {
    const { type, course_name, course_uuid, description, status, is_autoEnrolled, course_published } = action;

    switch (type) {
        case SET_SELECTED_COURSE:
            return {
                ...state, course_name: course_name, course_uuid: course_uuid,
                description: description, status: status, is_autoEnrolled: is_autoEnrolled, course_published: course_published
            };
        case UPDATE_SELECTED_COURSE:
            return {
                ...state, course_name: '', course_uuid: course_uuid,
                description: '', status: '', is_autoEnrolled: false, course_published: true
            };
        case CLEAR_SELECTED_COURSE:
            return initialState;
        default:
            return state;
    }
}