export const UPLOAD_BULK_USER_COURSE_ENROLLMENT = (variables) => {
    let query = {
        query: `mutation UserBulkCourseEnrollment($file: Upload!,$course_uuid: String!,$dueDate: String) { 
                         userBulkCourseEnrollment (
                            file: $file,course_uuid:$course_uuid,dueDate:$dueDate) {
                             id
                             response
                        }}`
    };

    let mergeQuery = Object.assign({}, query, variables);

    return JSON.stringify(mergeQuery);
};

export const USER_COURSE_ENROLLMENT = (variables) => {
    let query = {
        query: `mutation UserCourseEnrollment($user_id:Int!, $userName:String!, $course_uuid: String!, $dueDate: String) { 
                          userCourseEnrollment (
                            user_id: $user_id,userName:$userName,course_uuid:$course_uuid,dueDate:$dueDate) {
                                course_uuid,
                                user_id,
                                username,
                                due_date,
                        }}`
    };

    let mergeQuery = Object.assign({}, query, variables);

    return JSON.stringify(mergeQuery);
};

export const FETCH_COURSE_ENROLLMENT_PAGINATE = (variables) => {
    let query = {
        query: `query($searchQuery: String, $page: Int!, $limit: Int!, $filter: [UserInputFilterData],$course_uuid: String,$sortData:[String]!) 
                    { enrollments(searchQuery: $searchQuery, page: $page, limit: $limit, filter: $filter,course_uuid:$course_uuid,sortData:$sortData) {
                        count,
                        rows {
                            course_uuid,
                            user_id,
                            username,
                            due_date,
                            completed_pages,
                            completed_rate,
                            createdAt,
                            updatedAt,
                            user{
                                 email,
                                 employee_id,
                                 name, 
                                 last_name,
                                 first_name,
                                 organization
                            }
                        }
                    }}`
    };

    let mergeQuery = Object.assign({}, query, variables);

    return JSON.stringify(mergeQuery);
};

export const UPDATE_USER_COURSE_ENROLLMENT = (variables) => {
    let query = {
        query: `mutation UserAttemptToCourse($course_uuid: String!) { 
                            userAttemptToCourse (course_uuid:$course_uuid) {
                                assignment_uuid
                                page_uuid
                        }}`
    };

    let mergeQuery = Object.assign({}, query, variables);
    return JSON.stringify(mergeQuery);
};

export const LOAD_USER_COURSE_ENROLLMENT_SUMMARY = (variables) => {
    let query = {
        query: `query($course_uuid: String!,$username: String!) 
                    { userCourseEnrollmentDetails(course_uuid:$course_uuid,username:$username) {
                        user_id
                        username
                        email
                        employee_id
                        name
                        first_name
                        last_name
                        job_title
                        organization
                        account_active
                        enrollments
                    }}`
    };

    let mergeQuery = Object.assign({}, query, variables);

    return JSON.stringify(mergeQuery);
};

export const REVOKE_USER_COURSE_ENROLLMENT = (variables) => {
    let query = {
        query: `mutation UserUnenrollFromCourse($user_id:Int!,$course_uuid: String!) { 
            userUnenrollFromCourse (user_id:$user_id,course_uuid:$course_uuid) {
                                id
                                response
                        }}`
    };
    let mergeQuery = Object.assign({}, query, variables);
    return JSON.stringify(mergeQuery);
};