import { updateSideMenuRouting, updateSideMenuType } from '@core/actions/sideMenuRoute';
import { SideMenuTypes } from '@core/helpers/Navigations';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { CourseMenu } from './sidemenuRoutes';
import { sideMenuRoutingFactory } from './sidemenuRoutingFactory';


const useSideMenuRoute = (routings = {}) => {
    const dispatch = useDispatch();
    useEffect(() => {
        for (const [key, value] of Object.entries(routings)) {
            dispatch(updateSideMenuRouting(key, value));
        }
    }, []);
}

const useSideMenuTypeChange = (activeSideMenuType = SideMenuTypes.COURSE) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateSideMenuType(activeSideMenuType));
    }, []);
}



export {
    CourseMenu,
    useSideMenuRoute,
    useSideMenuTypeChange,
    sideMenuRoutingFactory,
}