import axios from "axios";
import config from "config";
import { CLEAR_MESSAGE, LOGIN_FAIL, LOGIN_REQUEST_PENDING, LOGIN_SUCCESS, LOGOUT, SET_MESSAGE } from '../actions/types';
const API_URL = config.serviceUrl;
import UserService from 'services/iamService';

const onUserLoginSuccess = (users) => {
  return {
    type: LOGIN_SUCCESS,
    payload: users
  }
};

const showUserLoginNotification = (message) => {
  return {
    type: SET_MESSAGE,
    payload: message
  }
};

const clearUserLoginNotification = () => {
  return {
    type: CLEAR_MESSAGE,
    payload: ""
  }
};

const sendingLoginRequest = () => {
  return {
    type: LOGIN_REQUEST_PENDING,
    payload: ''
  }
};

const onUserLoginFailure = (err) => {
  return {
    type: LOGIN_FAIL,
    payload: err
  }
};
const onUserLogout = (data) => {
  return {
    type: LOGOUT,
    payload: data
  }
};


const login = (username, password, dispatch) => {
  dispatch(sendingLoginRequest());
  axios.post(API_URL + "login", {
    username,
    password,
  })
    .then(response => {
      if (response.data.user) {
        dispatch(onUserLoginSuccess(response.data));
        dispatch(clearUserLoginNotification());
      } else {
        dispatch(onUserLoginFailure(response.data));
        dispatch(showUserLoginNotification(response.data.error));
        const errMsg = response.data.error ?? ''
        if(errMsg.toLowerCase().includes("failed to authenticate")){
          UserService.doLogout();
        }
      }
    }).catch(error => {
      dispatch(onUserLoginFailure(error.message));
      dispatch(showUserLoginNotification(error.message));
    });
};

const logout = (dispatch,selfLogout) => {
  dispatch(onUserLogout({selfLogout}));
};

export default {
  login,
  logout,
};
