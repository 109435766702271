export const CREATE_COURSE_PAGE = (variables) => {
    let query = {
        query: `mutation CreateCoursePage($title: String!, $content: String!, $status: String!, $course_uuid: String!, $module_uuid: String!) { 
                        createCoursePage (
                            coursePageInput: {
                                course_uuid: $course_uuid 
                                title: $title, 
                                content: $content,
                                module_uuid: $module_uuid
                                status: $status
                            }) {
                            page_uuid,
                            title,
                            content,
                            page_order,
                            status,
                            course_uuid,
                            createdAt,
                            updatedAt,
                        }}`
    };

    let mergeQuery = Object.assign({}, query, variables);

    return JSON.stringify(mergeQuery);
};

export const FETCH_COURSE_CONTENT = (variables) => {
    let query = {
        query: `query($page_uuid: String!) 
                    { courseContent(page_uuid: $page_uuid) {
                        page_uuid
                        module_uuid
                        title
                        content
                        page_order
                        status
                    }}`
    };

    let mergeQuery = Object.assign({}, query, variables);

    return JSON.stringify(mergeQuery);
};



export const UPDATE_COURSE_PAGE = (variables) => {
    let query = {
        query: `mutation UpdateCoursePage($title: String!, $content: String!, $status: String!, $course_uuid: String!, $page_uuid: String!, $module_uuid: String!) { 
                        updateCoursePage (
                            coursePageInput: {
                                course_uuid: $course_uuid 
                                title: $title, 
                                content: $content,
                                module_uuid: $module_uuid,
                                status: $status
                            }, page_uuid: $page_uuid ) {
                            page_uuid,
                            title,
                            content,
                            page_order,
                            status,
                            course_uuid,
                            createdAt,
                            updatedAt,
                        }}`
    };

    let mergeQuery = Object.assign({}, query, variables);

    return JSON.stringify(mergeQuery);
};

export const UPDATE_COURSE_PAGE_STATUS = (variables) => {
    let query = {
        query: `mutation UpdateCoursePage($status: String!, $page_uuid: String!) { 
                            updateCoursePageStatus (
                                coursePageInput: {
                                    status: $status
                                }, page_uuid: $page_uuid ) {
                            page_uuid,
                            title,
                            content,
                            page_order,
                            status,
                            course_uuid,
                            createdAt,
                            updatedAt,
                            }
                        }`
    };

    let mergeQuery = Object.assign({}, query, variables);

    return JSON.stringify(mergeQuery);
};


export const REARRANGE_COURSE_CONTENT_PAGES = (variables) => {
    let query = {
        query: `mutation ReorderCourseContentPages($course_pages:[CoursePageReorder]!) { 
                  reorderCourseContentPages(course_pages:$course_pages) {
                      code
                      reason
        }}`
    };
    let mergeQuery = Object.assign({}, query, variables);

    return JSON.stringify(mergeQuery);
}