import AuthService from "../services/auth.service";
import { AUTHENTICATION_FAIL, CHANGE_USER_ROLE } from "./types";

const publishSessionInvalidEvent = (data = { code: 403, reason: undefined }) => {
  return {
    type: AUTHENTICATION_FAIL,
    rejectCode: data?.code ?? 403,
    rejectReason: data?.reason ?? 'UnAuthorized Request',
  }
};

const onChangeUserMode = (role) => {
  return {
    type: CHANGE_USER_ROLE,
    payload: role
  }
};

export const login = (username, password) => (dispatch) => {
  return AuthService.login(username, password, dispatch);
};

export const logout = (selfLogout = false) => (dispatch) => {
  return AuthService.logout(dispatch, selfLogout);
};


export const invalidSession = (data) => (dispatch) => {
  return dispatch(publishSessionInvalidEvent(data));
};

export const changeRole = (role) => (dispatch) => {
  return dispatch(onChangeUserMode(role));
};


