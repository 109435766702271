import base64 from 'base64-js';
import sha256 from 'js-sha256';
import { jwtDecode } from "jwt-decode";

if (typeof Promise === 'undefined') {
    throw Error('IAM requires an environment that supports Promises. Make sure that you include the appropriate polyfill.');
}

function EmaptaIAM(config) {
    if (!(this instanceof EmaptaIAM)) {
        throw new Error("The 'EmaptaIAM' constructor must be invoked with 'new'.")
    }

    var iam = this;
    var adapter;
    var refreshQueue = [];
    var callbackStorage;

    var loginIframe = {
        enable: false,
        callbackList: [],
        interval: 5
    };

    var scripts = document.getElementsByTagName('script');
    for (var i = 0; i < scripts.length; i++) {
        if ((scripts[i].src.indexOf('keycloak.js') !== -1 || scripts[i].src.indexOf('keycloak.min.js') !== -1) && scripts[i].src.indexOf('version=') !== -1) {
            iam.iframeVersion = scripts[i].src.substring(scripts[i].src.indexOf('version=') + 8).split('&')[0];
        }
    }

    var useNonce = false;
    var logInfo = createLogger(console.info);
    var logWarn = createLogger(console.warn);

    iam.init = function(initOptions) {
        if (iam.didInitialize) {
            throw new Error("A 'EmaptaIAM' instance can only be initialized once.");
        }

        iam.didInitialize = true;

        iam.authenticated = false;

        callbackStorage = createCallbackStorage();
        var adapters = ['default'];

        if (initOptions && adapters.indexOf(initOptions.adapter) > -1) {
            adapter = loadAdapter(initOptions.adapter);
        } else if (initOptions && typeof initOptions.adapter === "object") {
            adapter = initOptions.adapter;
        } else {
            adapter = loadAdapter();
        }

        if (initOptions) {
            if (typeof initOptions.useNonce !== 'undefined') {
                useNonce = initOptions.useNonce;
            }

            if (typeof initOptions.checkLoginIframe !== 'undefined') {
                loginIframe.enable = initOptions.checkLoginIframe;
            }

            if (initOptions.checkLoginIframeInterval) {
                loginIframe.interval = initOptions.checkLoginIframeInterval;
            }



            if (initOptions.responseMode) {
                if (initOptions.responseMode === 'query' || initOptions.responseMode === 'fragment') {
                    iam.responseMode = initOptions.responseMode;
                } else {
                    throw 'Invalid value for responseMode';
                }
            }

            if (initOptions.flow) {
                switch (initOptions.flow) {
                    case 'standard':
                        iam.responseType = 'code';
                        break;
                    case 'implicit':
                        iam.responseType = 'id_token token';
                        break;
                    case 'hybrid':
                        iam.responseType = 'code id_token token';
                        break;
                    default:
                        throw 'Invalid value for flow';
                }
                iam.flow = initOptions.flow;
            }

            if (initOptions.timeSkew != null) {
                iam.timeSkew = initOptions.timeSkew;
            }

            if (initOptions.redirectUri) {
                iam.redirectUri = initOptions.redirectUri;
            }

            if (initOptions.silentCheckSsoRedirectUri) {
                iam.silentCheckSsoRedirectUri = initOptions.silentCheckSsoRedirectUri;
            }

            if (typeof initOptions.silentCheckSsoFallback === 'boolean') {
                iam.silentCheckSsoFallback = initOptions.silentCheckSsoFallback;
            } else {
                iam.silentCheckSsoFallback = true;
            }

            if (initOptions.pkceMethod) {
                if (initOptions.pkceMethod !== "S256") {
                    throw 'Invalid value for pkceMethod';
                }
                iam.pkceMethod = initOptions.pkceMethod;
            }

            if (typeof initOptions.enableLogging === 'boolean') {
                iam.enableLogging = initOptions.enableLogging;
            } else {
                iam.enableLogging = false;
            }

            if (typeof initOptions.scope === 'string') {
                iam.scope = initOptions.scope;
            }

            if (typeof initOptions.messageReceiveTimeout === 'number' && initOptions.messageReceiveTimeout > 0) {
                iam.messageReceiveTimeout = initOptions.messageReceiveTimeout;
            } else {
                iam.messageReceiveTimeout = 10000;
            }
        }

        if (!iam.responseMode) {
            iam.responseMode = 'fragment';
        }
        if (!iam.responseType) {
            iam.responseType = 'code';
            iam.flow = 'standard';
        }

        var promise = createPromise();

        var initPromise = createPromise();
        initPromise.promise.then(function() {
            iam.onReady && iam.onReady(iam.authenticated);
            promise.setSuccess(iam.authenticated);
        }).catch(function(error) {
            promise.setError(error);
        });

        var configPromise = loadConfig(config);

        function onLoad() {
            var doLogin = function(prompt) {
                if (!prompt) {
                    options.prompt = 'none';
                }

                if (initOptions && initOptions.locale) {
                    options.locale = initOptions.locale;
                }
                iam.login(options).then(function() {
                    initPromise.setSuccess();
                }).catch(function(error) {
                    initPromise.setError(error);
                });
            }

            var options = {};
            switch (initOptions.onLoad) {
                case 'login-required':
                    // doLogin(true);
                    break;
                default:
                    throw 'Invalid value for onLoad';
            }
        }

        function processInit() {
            var callback = parseCallback(window.location.href);

            if (callback) {
                window.history.replaceState(window.history.state, null, callback.newUrl);
            }

            if (callback && callback.valid) {
                return setupCheckLoginIframe().then(function() {
                    processCallback(callback, initPromise);
                }).catch(function(error) {
                    initPromise.setError(error);
                });
            } else if (initOptions) {
                if (initOptions.token && initOptions.refreshToken) {
                    setToken(initOptions.token, initOptions.refreshToken, initOptions.idToken);

                    if (loginIframe.enable) {
                        setupCheckLoginIframe().then(function() {
                            checkLoginIframe().then(function(unchanged) {
                                if (unchanged) {
                                    iam.onAuthSuccess && iam.onAuthSuccess();
                                    initPromise.setSuccess();
                                    scheduleCheckIframe();
                                } else {
                                    initPromise.setSuccess();
                                }
                            }).catch(function(error) {
                                initPromise.setError(error);
                            });
                        });
                    } else {
                        iam.updateToken(-1).then(function() {
                            iam.onAuthSuccess && iam.onAuthSuccess();
                            initPromise.setSuccess();
                        }).catch(function(error) {
                            iam.onAuthError && iam.onAuthError();
                            if (initOptions.onLoad) {
                                onLoad();
                            } else {
                                initPromise.setError(error);
                            }
                        });
                    }
                } else if (initOptions.onLoad) {
                    onLoad();
                } else {
                    initPromise.setSuccess();
                }
            } else {
                initPromise.setSuccess();
            }
        }

        function domReady() {
            var promise = createPromise();

            var checkReadyState = function() {
                if (document.readyState === 'interactive' || document.readyState === 'complete') {
                    document.removeEventListener('readystatechange', checkReadyState);
                    promise.setSuccess();
                }
            }
            document.addEventListener('readystatechange', checkReadyState);

            checkReadyState(); // just in case the event was already fired and we missed it (in case the init is done later than at the load time, i.e. it's done from code)

            return promise.promise;
        }

        configPromise.then(function() {
            domReady()
                .then(processInit)
                .catch(function(error) {
                    promise.setError(error);
                });
        });
        configPromise.catch(function(error) {
            promise.setError(error);
        });

        return promise.promise;
    }

    iam.login = function(options) {
        return adapter.login(options);
    }

    function generateRandomData(len) {
        // use web crypto APIs if possible
        var array = null;
        var crypto = window.crypto || window.msCrypto;
        if (crypto && crypto.getRandomValues && window.Uint8Array) {
            array = new Uint8Array(len);
            crypto.getRandomValues(array);
            return array;
        }

        // fallback to Math random
        array = new Array(len);
        for (var j = 0; j < array.length; j++) {
            array[j] = Math.floor(256 * Math.random());
        }
        return array;
    }

    function generateCodeVerifier(len) {
        return generateRandomString(len, 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789');
    }

    function generateRandomString(len, alphabet) {
        var randomData = generateRandomData(len);
        var chars = new Array(len);
        for (var i = 0; i < len; i++) {
            chars[i] = alphabet.charCodeAt(randomData[i] % alphabet.length);
        }
        return String.fromCharCode.apply(null, chars);
    }

    function generatePkceChallenge(pkceMethod, codeVerifier) {
        switch (pkceMethod) {
            // The use of the "plain" method is considered insecure and therefore not supported.
            case "S256":
                // hash codeVerifier, then encode as url-safe base64 without padding
                var hashBytes = new Uint8Array(sha256.arrayBuffer(codeVerifier));
                var encodedHash = base64.fromByteArray(hashBytes)
                    .replace(/\+/g, '-')
                    .replace(/\//g, '_')
                    .replace(/\=/g, '');
                return encodedHash;
            default:
                throw 'Invalid value for pkceMethod';
        }
    }

    function buildClaimsParameter(requestedAcr) {
        var claims = {
            id_token: {
                acr: requestedAcr
            }
        }
        return JSON.stringify(claims);
    }

    iam.createLoginUrl = function(options) {
        var state = createUUID();
        var nonce = createUUID();

        var redirectUri = adapter.redirectUri(options);

        var callbackState = {
            state: state,
            nonce: nonce,
            redirectUri: encodeURIComponent(redirectUri)
        };

        if (options && options.prompt) {
            callbackState.prompt = options.prompt;
        }

        var baseUrl;
        if (options && options.action == 'register') {
            baseUrl = iam.endpoints.register();
        } else {
            baseUrl = iam.endpoints.authorize();
        }

        var scope = options && options.scope || iam.scope;
        if (!scope) {
            // if scope is not set, default to "openid"
            scope = "openid";
        } else if (scope.indexOf("openid") === -1) {
            // if openid scope is missing, prefix the given scopes with it
            scope = "openid " + scope;
        }

        var url = baseUrl +
            '?client_id=' + encodeURIComponent(iam.clientId) +
            '&redirect_uri=' + encodeURIComponent(redirectUri) +
            '&state=' + encodeURIComponent(state) +
            '&response_mode=' + encodeURIComponent(iam.responseMode) +
            '&response_type=' + encodeURIComponent(iam.responseType) +
            '&scope=' + encodeURIComponent(scope);
        if (useNonce) {
            url = url + '&nonce=' + encodeURIComponent(nonce);
        }

        if (options && options.prompt) {
            url += '&prompt=' + encodeURIComponent(options.prompt);
        }

        if (options && options.maxAge) {
            url += '&max_age=' + encodeURIComponent(options.maxAge);
        }

        if (options && options.loginHint) {
            url += '&login_hint=' + encodeURIComponent(options.loginHint);
        }

        if (options && options.idpHint) {
            url += '&kc_idp_hint=' + encodeURIComponent(options.idpHint);
        }

        if (options && options.action && options.action != 'register') {
            url += '&kc_action=' + encodeURIComponent(options.action);
        }

        if (options && options.locale) {
            url += '&ui_locales=' + encodeURIComponent(options.locale);
        }

        if (options && options.acr) {
            var claimsParameter = buildClaimsParameter(options.acr);
            url += '&claims=' + encodeURIComponent(claimsParameter);
        }

        if (iam.pkceMethod) {
            var codeVerifier = generateCodeVerifier(96);
            callbackState.pkceCodeVerifier = codeVerifier;
            var pkceChallenge = generatePkceChallenge(iam.pkceMethod, codeVerifier);
            url += '&code_challenge=' + pkceChallenge;
            url += '&code_challenge_method=' + iam.pkceMethod;
        }

        callbackStorage.add(callbackState);

        console.info("AUTH URL : ", url);

        return url;
    }

    iam.logout = function(options) {
        return adapter.logout(options);
    }

    iam.createLogoutUrl = function(options) {


        return iam.endpoints.logout();
    }

    iam.register = function(options) {
        return adapter.register(options);
    }

    iam.createRegisterUrl = function(options) {
        if (!options) {
            options = {};
        }
        options.action = 'register';
        return iam.createLoginUrl(options);
    }

    iam.createAccountUrl = function(options) {
        var realm = getRealmUrl();
        var url = undefined;
        if (typeof realm !== 'undefined') {
            url = realm +
                '/account' +
                '?referrer=' + encodeURIComponent(iam.clientId) +
                '&referrer_uri=' + encodeURIComponent(adapter.redirectUri(options));
        }
        return url;
    }

    iam.accountManagement = function() {
        return adapter.accountManagement();
    }

    iam.hasRealmRole = function(role) {
        var access = iam.realmAccess;
        return !!access && access.roles.indexOf(role) >= 0;
    }

    iam.hasResourceRole = function(role, resource) {
        if (!iam.resourceAccess) {
            return false;
        }

        var access = iam.resourceAccess[resource || iam.clientId];
        return !!access && access.roles.indexOf(role) >= 0;
    }

    iam.loadUserProfile = function() {
        var url = getRealmUrl() + '/account';
        var req = new XMLHttpRequest();
        req.open('GET', url, true);
        req.setRequestHeader('Accept', 'application/json');
        req.setRequestHeader('Authorization', 'bearer ' + iam.token);

        var promise = createPromise();

        req.onreadystatechange = function() {
            if (req.readyState == 4) {
                if (req.status == 200) {
                    iam.profile = JSON.parse(req.responseText);
                    promise.setSuccess(iam.profile);
                } else {
                    promise.setError();
                }
            }
        }

        req.send();

        return promise.promise;
    }

    iam.loadUserInfo = function() {
        var url = iam.endpoints.userinfo();
        var req = new XMLHttpRequest();
        req.open('GET', url, true);
        req.setRequestHeader('Accept', 'application/json');
        req.setRequestHeader('Authorization', 'bearer ' + iam.token);

        var promise = createPromise();

        req.onreadystatechange = function() {
            if (req.readyState == 4) {
                if (req.status == 200) {
                    iam.userInfo = JSON.parse(req.responseText);
                    promise.setSuccess(iam.userInfo);
                } else {
                    promise.setError();
                }
            }
        }

        req.send();

        return promise.promise;
    }

    iam.isTokenExpired = function(minValidity) {
        if (!iam.tokenParsed || (!iam.refreshToken && iam.flow != 'implicit')) {
            throw 'Not authenticated';
        }

        if (iam.timeSkew == null) {
            logInfo('[EMAPTIAM] Unable to determine if token is expired as time is not set');
            return true;
        }

        var expiresIn = iam.tokenParsed['exp'] - Math.ceil(new Date().getTime() / 1000) + iam.timeSkew;
        if (minValidity) {
            if (isNaN(minValidity)) {
                throw 'Invalid minValidity';
            }
            expiresIn -= minValidity;
        }
        return expiresIn < 0;
    }

    iam.updateToken = function(minValidity) {
        var promise = createPromise();

        if (!iam.refreshToken) {
            promise.setError();
            return promise.promise;
        }

        minValidity = minValidity || 5;

        var exec = function() {
            var refreshToken = false;
            if (minValidity == -1) {
                refreshToken = true;
                logInfo('[EMAPTIAM] Refreshing token: forced refresh');
            } else if (!iam.tokenParsed || iam.isTokenExpired(minValidity)) {
                refreshToken = true;
                logInfo('[EMAPTIAM] Refreshing token: token expired');
            }

            if (!refreshToken) {
                promise.setSuccess(false);
            } else {
                var params = 'grant_type=refresh_token&' + 'refresh_token=' + iam.refreshToken;
                var url = iam.endpoints.token();

                refreshQueue.push(promise);

                if (refreshQueue.length == 1) {
                    var req = new XMLHttpRequest();
                    req.open('POST', url, true);
                    req.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                    req.withCredentials = true;

                    params += '&client_id=' + encodeURIComponent(iam.clientId);

                    var timeLocal = new Date().getTime();

                    req.onreadystatechange = function() {
                        if (req.readyState == 4) {
                            if (req.status == 200) {
                                logInfo('[EMAPTIAM] Token refreshed');

                                timeLocal = (timeLocal + new Date().getTime()) / 2;

                                var tokenResponse = JSON.parse(req.responseText)['result'];

                                setToken(tokenResponse['access_token'], tokenResponse['refresh_token'], tokenResponse['id_token'], timeLocal);

                                iam.onAuthRefreshSuccess && iam.onAuthRefreshSuccess();
                                for (var p = refreshQueue.pop(); p != null; p = refreshQueue.pop()) {
                                    p.setSuccess(true);
                                }
                            } else {
                                logWarn('[EMAPTIAM] Failed to refresh token');

                                if (req.status == 400) {
                                    iam.clearToken();
                                }

                                iam.onAuthRefreshError && iam.onAuthRefreshError();
                                for (var p = refreshQueue.pop(); p != null; p = refreshQueue.pop()) {
                                    p.setError(true);
                                }
                            }
                        }
                    };

                    req.send(params);
                }
            }
        }

        if (loginIframe.enable) {
            var iframePromise = checkLoginIframe();
            iframePromise.then(function() {
                exec();
            }).catch(function(error) {
                promise.setError(error);
            });
        } else {
            exec();
        }

        return promise.promise;
    }

    iam.clearToken = function() {
        if (iam.token) {
            setToken(null, null, null);
            iam.onAuthLogout && iam.onAuthLogout();
        }
    }

    function getRealmUrl() {
        if (typeof iam.authServerUrl !== 'undefined') {
            return iam.authServerUrl;
        } else {
            return undefined;
        }
    }

    function getOrigin() {
        if (!window.location.origin) {
            return window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
        } else {
            return window.location.origin;
        }
    }

    function processCallback(oauth, promise) {
        var code = oauth.code;
        var error = oauth.error;
        var prompt = oauth.prompt;

        var timeLocal = new Date().getTime();

        if (oauth['kc_action_status']) {
            iam.onActionUpdate && iam.onActionUpdate(oauth['kc_action_status']);
        }

        if (error) {
            if (prompt != 'none') {
                var errorData = {
                    error: error,
                    error_description: oauth.error_description
                };
                iam.onAuthError && iam.onAuthError(errorData);
                promise && promise.setError(errorData);
            } else {
                promise && promise.setSuccess();
            }
            return;
        } else if ((iam.flow != 'standard') && (oauth.access_token || oauth.id_token)) {
            authSuccess(oauth.access_token, null, oauth.id_token, true);
        }

        console.info("iam.flow ", iam.flow)

        if ((iam.flow != 'implicit') && code) {
            var params = 'code=' + code + '&grant_type=authorization_code';
            var url = iam.endpoints.token();

            var req = new XMLHttpRequest();
            req.open('POST', url, true);
            req.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');

            params += '&client_id=' + encodeURIComponent(iam.clientId);
            params += '&redirect_uri=' + oauth.redirectUri;

            if (oauth.pkceCodeVerifier) {
                params += '&code_verifier=' + oauth.pkceCodeVerifier;
            }

            req.withCredentials = true;

            req.onreadystatechange = function() {
                if (req.readyState == 4) {
                    if (req.status == 200) {

                        var tokenResponse = JSON.parse(req.responseText)['result'];
                        console.log("tokenResponse ", tokenResponse)
                        authSuccess(tokenResponse['access_token'], tokenResponse['refresh_token'], tokenResponse['id_token'], iam.flow === 'standard');
                        scheduleCheckIframe();
                    } else {
                        iam.onAuthError && iam.onAuthError();
                        promise && promise.setError();
                    }
                }
            };

            req.send(params);
        }

        function authSuccess(accessToken, refreshToken, idToken, fulfillPromise) {
            timeLocal = (timeLocal + new Date().getTime()) / 2;

            setToken(accessToken, refreshToken, idToken, timeLocal);

            if (useNonce && ((iam.tokenParsed && iam.tokenParsed.nonce != oauth.storedNonce) ||
                    (iam.refreshTokenParsed && iam.refreshTokenParsed.nonce != oauth.storedNonce) ||
                    (iam.idTokenParsed && iam.idTokenParsed.nonce != oauth.storedNonce))) {

                logInfo('[EMAPTIAM] Invalid nonce, clearing token');
                iam.clearToken();
                promise && promise.setError();
            } else {
                if (fulfillPromise) {
                    iam.onAuthSuccess && iam.onAuthSuccess();
                    promise && promise.setSuccess();
                }
            }
        }

    }

    function loadConfig(url) {
        var promise = createPromise();
        var configUrl;

        if (!config) {
            configUrl = 'EMAPTIAM.json';
        } else if (typeof config === 'string') {
            configUrl = config;
        }

        function setupOidcEndoints(oidcConfiguration) {
            if (!oidcConfiguration) {
                iam.endpoints = {
                    authorize: function() {
                        return getRealmUrl() + '/auth/protocol/openid-connect/auth';
                    },
                    token: function() {
                        return getRealmUrl() + '/auth/protocol/openid-connect/token';
                    },
                    logout: function() {
                        return getRealmUrl() + '/auth/protocol/openid-connect/logout';
                    },
                    checkSessionIframe: function() {
                        var src = getRealmUrl() + '/auth/protocol/openid-connect/login-status-iframe.html';
                        if (iam.iframeVersion) {
                            src = src + '?version=' + iam.iframeVersion;
                        }
                        return src;
                    },
                    thirdPartyCookiesIframe: function() {
                        var src = getRealmUrl() + '/auth/protocol/openid-connect/3p-cookies/step1.html';
                        if (iam.iframeVersion) {
                            src = src + '?version=' + iam.iframeVersion;
                        }
                        return src;
                    },
                    register: function() {
                        return getRealmUrl() + '/auth/protocol/openid-connect/registrations';
                    },
                    userinfo: function() {
                        return getRealmUrl() + '/auth/protocol/openid-connect/userinfo';
                    }
                };
            } else {
                iam.endpoints = {
                    authorize: function() {
                        return oidcConfiguration.authorization_endpoint;
                    },
                    token: function() {
                        return oidcConfiguration.token_endpoint;
                    },
                    logout: function() {
                        if (!oidcConfiguration.end_session_endpoint) {
                            throw "Not supported by the OIDC server";
                        }
                        return oidcConfiguration.end_session_endpoint;
                    },
                    checkSessionIframe: function() {
                        if (!oidcConfiguration.check_session_iframe) {
                            throw "Not supported by the OIDC server";
                        }
                        return oidcConfiguration.check_session_iframe;
                    },
                    register: function() {
                        throw 'Redirection to "Register user" page not supported in standard OIDC mode';
                    },
                    userinfo: function() {
                        if (!oidcConfiguration.userinfo_endpoint) {
                            throw "Not supported by the OIDC server";
                        }
                        return oidcConfiguration.userinfo_endpoint;
                    }
                }
            }
        }

        if (configUrl) {
            var req = new XMLHttpRequest();
            req.open('GET', configUrl, true);
            req.setRequestHeader('Accept', 'application/json');

            req.onreadystatechange = function() {
                if (req.readyState == 4) {
                    if (req.status == 200 || fileLoaded(req)) {
                        var config = JSON.parse(req.responseText);

                        iam.authServerUrl = config['auth-server-url'];
                        iam.redirectUri = config['redirect_uri'];
                        iam.settings = config;
                        iam.clientId = config['resource'];
                        setupOidcEndoints(null);
                        promise.setSuccess();
                    } else {
                        promise.setError();
                    }
                }
            };

            req.send();
        } else {
            if (!config.clientId) {
                throw 'clientId missing';
            }

            iam.clientId = config.clientId;

            var oidcProvider = config['oidcProvider'];
            if (!oidcProvider) {
                if (!config['url']) {
                    var scripts = document.getElementsByTagName('script');
                    for (var i = 0; i < scripts.length; i++) {
                        if (scripts[i].src.match(/.*EMAPTIAM\.js/)) {
                            config.url = scripts[i].src.substr(0, scripts[i].src.indexOf('/js/EMAPTIAM.js'));
                            break;
                        }
                    }
                }
                if (!config.realm) {
                    throw 'realm missing';
                }

                iam.authServerUrl = config.url;
                iam.realm = config.realm;
                setupOidcEndoints(null);
                promise.setSuccess();
            } else {
                if (typeof oidcProvider === 'string') {
                    var oidcProviderConfigUrl;
                    if (oidcProvider.charAt(oidcProvider.length - 1) == '/') {
                        oidcProviderConfigUrl = oidcProvider + '.well-known/openid-configuration';
                    } else {
                        oidcProviderConfigUrl = oidcProvider + '/.well-known/openid-configuration';
                    }
                    var req = new XMLHttpRequest();
                    req.open('GET', oidcProviderConfigUrl, true);
                    req.setRequestHeader('Accept', 'application/json');

                    req.onreadystatechange = function() {
                        if (req.readyState == 4) {
                            if (req.status == 200 || fileLoaded(req)) {
                                var oidcProviderConfig = JSON.parse(req.responseText);
                                setupOidcEndoints(oidcProviderConfig);
                                promise.setSuccess();
                            } else {
                                promise.setError();
                            }
                        }
                    };

                    req.send();
                } else {
                    setupOidcEndoints(oidcProvider);
                    promise.setSuccess();
                }
            }
        }

        return promise.promise;
    }

    function fileLoaded(xhr) {
        return xhr.status == 0 && xhr.responseText && xhr.responseURL.startsWith('file:');
    }

    function setToken(token, refreshToken, idToken, timeLocal) {
        if (iam.tokenTimeoutHandle) {
            clearTimeout(iam.tokenTimeoutHandle);
            iam.tokenTimeoutHandle = null;
        }

        if (refreshToken) {
            iam.refreshToken = refreshToken;
            iam.refreshTokenParsed = jwtDecode(refreshToken);
        } else {
            delete iam.refreshToken;
            delete iam.refreshTokenParsed;
        }

        if (idToken) {
            iam.idToken = idToken;
            iam.idTokenParsed = jwtDecode(idToken);
        } else {
            delete iam.idToken;
            delete iam.idTokenParsed;
        }

        if (token) {
            iam.token = token;
            iam.tokenParsed = jwtDecode(token);
            iam.sessionId = iam.tokenParsed.session_state;
            iam.authenticated = true;
            iam.subject = iam.tokenParsed.sub;
            iam.realmAccess = iam.tokenParsed.realm_access;
            iam.resourceAccess = iam.tokenParsed.resource_access;

            if (timeLocal) {
                iam.timeSkew = Math.floor(timeLocal / 1000) - iam.tokenParsed.iat;
            }

            if (iam.timeSkew != null) {
                logInfo('[EMAPTIAM] Estimated time difference between browser and server is ' + iam.timeSkew + ' seconds');

                if (iam.onTokenExpired) {
                    var expiresIn = (iam.tokenParsed['exp'] - (new Date().getTime() / 1000) + iam.timeSkew) * 1000;
                    logInfo('[EMAPTIAM] Token expires in ' + Math.round(expiresIn / 1000) + ' s');
                    if (expiresIn <= 0) {
                        iam.onTokenExpired();
                    } else {
                        iam.tokenTimeoutHandle = setTimeout(iam.onTokenExpired, expiresIn);
                    }
                }
            }
        } else {
            delete iam.token;
            delete iam.tokenParsed;
            delete iam.subject;
            delete iam.realmAccess;
            delete iam.resourceAccess;

            iam.authenticated = false;
        }
    }

    function createUUID() {
        var hexDigits = '0123456789abcdef';
        var s = generateRandomString(36, hexDigits).split("");
        s[14] = '4';
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
        s[8] = s[13] = s[18] = s[23] = '-';
        var uuid = s.join('');
        return uuid;
    }

    function parseCallback(url) {
        var oauth = parseCallbackUrl(url);
        if (!oauth) {
            return;
        }

        var oauthState = callbackStorage.get(oauth.state);

        if (oauthState) {
            oauth.valid = true;
            oauth.redirectUri = oauthState.redirectUri;
            oauth.storedNonce = oauthState.nonce;
            oauth.prompt = oauthState.prompt;
            oauth.pkceCodeVerifier = oauthState.pkceCodeVerifier;
        }

        return oauth;
    }

    function parseCallbackUrl(url) {
        var supportedParams;
        switch (iam.flow) {
            case 'standard':
                supportedParams = ['code', 'state', 'session_state', 'kc_action_status', 'iss'];
                break;
            case 'implicit':
                supportedParams = ['access_token', 'token_type', 'id_token', 'state', 'session_state', 'expires_in', 'kc_action_status', 'iss'];
                break;
            case 'hybrid':
                supportedParams = ['access_token', 'token_type', 'id_token', 'code', 'state', 'session_state', 'expires_in', 'kc_action_status', 'iss'];
                break;
        }

        supportedParams.push('error');
        supportedParams.push('error_description');
        supportedParams.push('error_uri');

        var queryIndex = url.indexOf('?');
        var fragmentIndex = url.indexOf('#');

        var newUrl;
        var parsed;

        if (iam.responseMode === 'query' && queryIndex !== -1) {
            newUrl = url.substring(0, queryIndex);
            parsed = parseCallbackParams(url.substring(queryIndex + 1, fragmentIndex !== -1 ? fragmentIndex : url.length), supportedParams);
            if (parsed.paramsString !== '') {
                newUrl += '?' + parsed.paramsString;
            }
            if (fragmentIndex !== -1) {
                newUrl += url.substring(fragmentIndex);
            }
        } else if (iam.responseMode === 'fragment' && fragmentIndex !== -1) {
            newUrl = url.substring(0, fragmentIndex);
            parsed = parseCallbackParams(url.substring(fragmentIndex + 1), supportedParams);
            if (parsed.paramsString !== '') {
                newUrl += '#' + parsed.paramsString;
            }
        }

        if (parsed && parsed.oauthParams) {
            if (iam.flow === 'standard' || iam.flow === 'hybrid') {
                if ((parsed.oauthParams.code || parsed.oauthParams.error) && parsed.oauthParams.state) {
                    parsed.oauthParams.newUrl = newUrl;
                    return parsed.oauthParams;
                }
            } else if (iam.flow === 'implicit') {
                if ((parsed.oauthParams.access_token || parsed.oauthParams.error) && parsed.oauthParams.state) {
                    parsed.oauthParams.newUrl = newUrl;
                    return parsed.oauthParams;
                }
            }
        }
    }

    function parseCallbackParams(paramsString, supportedParams) {
        var p = paramsString.split('&');
        var result = {
            paramsString: '',
            oauthParams: {}
        }
        for (var i = 0; i < p.length; i++) {
            var split = p[i].indexOf("=");
            var key = p[i].slice(0, split);
            if (supportedParams.indexOf(key) !== -1) {
                result.oauthParams[key] = p[i].slice(split + 1);
            } else {
                if (result.paramsString !== '') {
                    result.paramsString += '&';
                }
                result.paramsString += p[i];
            }
        }
        return result;
    }

    function createPromise() {
        // Need to create a native Promise which also preserves the
        // interface of the custom promise type previously used by the API
        var p = {
            setSuccess: function(result) {
                p.resolve(result);
            },

            setError: function(result) {
                p.reject(result);
            }
        };
        p.promise = new Promise(function(resolve, reject) {
            p.resolve = resolve;
            p.reject = reject;
        });

        return p;
    }

    // Function to extend existing native Promise with timeout
    function applyTimeoutToPromise(promise, timeout, errorMessage) {
        var timeoutHandle = null;
        var timeoutPromise = new Promise(function(resolve, reject) {
            timeoutHandle = setTimeout(function() {
                reject({
                    "error": errorMessage || "Promise is not settled within timeout of " + timeout + "ms"
                });
            }, timeout);
        });

        return Promise.race([promise, timeoutPromise]).finally(function() {
            clearTimeout(timeoutHandle);
        });
    }

    function setupCheckLoginIframe() {
        var promise = createPromise();

        if (!loginIframe.enable) {
            promise.setSuccess();
            return promise.promise;
        }

        if (loginIframe.iframe) {
            promise.setSuccess();
            return promise.promise;
        }

        var iframe = document.createElement('iframe');
        loginIframe.iframe = iframe;

        iframe.onload = function() {
            var authUrl = iam.endpoints.authorize();
            if (authUrl.charAt(0) === '/') {
                loginIframe.iframeOrigin = getOrigin();
            } else {
                loginIframe.iframeOrigin = authUrl.substring(0, authUrl.indexOf('/', 8));
            }
            promise.setSuccess();
        }

        var src = iam.endpoints.checkSessionIframe();
        iframe.setAttribute('src', src);
        iframe.setAttribute('sandbox', 'allow-scripts allow-same-origin');
        iframe.setAttribute('title', 'EMAPTIAM-session-iframe');
        iframe.style.display = 'none';
        document.body.appendChild(iframe);

        var messageCallback = function(event) {
            if ((event.origin !== loginIframe.iframeOrigin) || (loginIframe.iframe.contentWindow !== event.source)) {
                return;
            }

            if (!(event.data == 'unchanged' || event.data == 'changed' || event.data == 'error')) {
                return;
            }


            if (event.data != 'unchanged') {
                iam.clearToken();
            }

            var callbacks = loginIframe.callbackList.splice(0, loginIframe.callbackList.length);

            for (var i = callbacks.length - 1; i >= 0; --i) {
                var promise = callbacks[i];
                if (event.data == 'error') {
                    promise.setError();
                } else {
                    promise.setSuccess(event.data == 'unchanged');
                }
            }
        };

        window.addEventListener('message', messageCallback, false);

        return promise.promise;
    }

    function scheduleCheckIframe() {
        if (loginIframe.enable) {
            if (iam.token) {
                setTimeout(function() {
                    checkLoginIframe().then(function(unchanged) {
                        if (unchanged) {
                            scheduleCheckIframe();
                        }
                    });
                }, loginIframe.interval * 1000);
            }
        }
    }

    function checkLoginIframe() {
        var promise = createPromise();

        if (loginIframe.iframe && loginIframe.iframeOrigin) {
            var msg = iam.clientId + ' ' + (iam.sessionId ? iam.sessionId : '');
            loginIframe.callbackList.push(promise);
            var origin = loginIframe.iframeOrigin;
            if (loginIframe.callbackList.length == 1) {
                loginIframe.iframe.contentWindow.postMessage(msg, origin);
            }
        } else {
            promise.setSuccess();
        }

        return promise.promise;
    }

    function loadAdapter(type) {
        if (!type || type == 'default') {
            return {
                login: function(options) {
                    window.location.assign(iam.createLoginUrl(options));
                    return createPromise().promise;
                },

                logout: function(options) {
                    const url = iam.createLogoutUrl(options);
                    var req = new XMLHttpRequest();
                    req.open('POST', url, true);
                    req.setRequestHeader('Accept', 'application/json');
                    req.setRequestHeader('Content-Type', 'application/json');

                    var promise = createPromise();

                    req.onreadystatechange = function() {
                        if (req.readyState == 4) {
                            console.log("User logout response ", req.status, JSON.parse(req.responseText))
                            promise.setSuccess({});
                        }
                    }

                    req.send(JSON.stringify({
                      client_id: iam.clientId,
                      refresh_token: iam.refreshToken
                    }));
                    iam.clearToken();

                    return promise.promise;
                },

                register: function(options) {
                    window.location.assign(iam.createRegisterUrl(options));
                    return createPromise().promise;
                },

                accountManagement: function() {
                    var accountUrl = iam.createAccountUrl();
                    if (typeof accountUrl !== 'undefined') {
                        window.location.href = accountUrl;
                    } else {
                        throw "Not supported by the OIDC server";
                    }
                    return createPromise().promise;
                },

                redirectUri: function(options, encodeHash) {
                    if (arguments.length == 1) {
                        encodeHash = true;
                    }

                    if (options && options.redirectUri) {
                        return options.redirectUri;
                    } else if (iam.redirectUri) {
                        return iam.redirectUri;
                    } else {
                        return location.href;
                    }
                }
            };
        }

        throw 'invalid adapter type: ' + type;
    }

    var LocalStorage = function() {
        if (!(this instanceof LocalStorage)) {
            return new LocalStorage();
        }

        localStorage.setItem('emapta-iam-test', 'test');
        localStorage.removeItem('emapta-iam-test');

        var cs = this;

        function clearExpired() {
            var time = new Date().getTime();
            for (var i = 0; i < localStorage.length; i++) {
                var key = localStorage.key(i);
                if (key && key.indexOf('emapta-iam-callback-') == 0) {
                    var value = localStorage.getItem(key);
                    if (value) {
                        try {
                            var expires = JSON.parse(value).expires;
                            if (!expires || expires < time) {
                                localStorage.removeItem(key);
                            }
                        } catch (err) {
                            localStorage.removeItem(key);
                        }
                    }
                }
            }
        }

        cs.get = function(state) {
            if (!state) {
                return;
            }

            var key = 'emapta-iam-callback-' + state;
            var value = localStorage.getItem(key);
            if (value) {
                localStorage.removeItem(key);
                value = JSON.parse(value);
            }

            clearExpired();
            return value;
        };

        cs.add = function(state) {
            clearExpired();

            var key = 'emapta-iam-callback-' + state.state;
            state.expires = new Date().getTime() + (60 * 60 * 1000);
            localStorage.setItem(key, JSON.stringify(state));
        };
    };

    var CookieStorage = function() {
        if (!(this instanceof CookieStorage)) {
            return new CookieStorage();
        }

        var cs = this;

        cs.get = function(state) {
            if (!state) {
                return;
            }

            var value = getCookie('emapta-iam-callback-' + state);
            setCookie('emapta-iam-callback-' + state, '', cookieExpiration(-100));
            if (value) {
                return JSON.parse(value);
            }
        };

        cs.add = function(state) {
            setCookie('emapta-iam-callback-' + state.state, JSON.stringify(state), cookieExpiration(60));
        };

        cs.removeItem = function(key) {
            setCookie(key, '', cookieExpiration(-100));
        };

        var cookieExpiration = function(minutes) {
            var exp = new Date();
            exp.setTime(exp.getTime() + (minutes * 60 * 1000));
            return exp;
        };

        var getCookie = function(key) {
            var name = key + '=';
            var ca = document.cookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return '';
        };

        var setCookie = function(key, value, expirationDate) {
            var cookie = key + '=' + value + '; ' +
                'expires=' + expirationDate.toUTCString() + '; ';
            document.cookie = cookie;
        }
    };

    function createCallbackStorage() {
        try {
            return new LocalStorage();
        } catch (err) {}

        return new CookieStorage();
    }

    function createLogger(fn) {
        return function() {
            if (iam.enableLogging) {
                fn.apply(console, Array.prototype.slice.call(arguments));
            }
        };
    }
}

export default EmaptaIAM;
