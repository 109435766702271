export const FETCH_COURSE_MODULES = (variables) => {
    let query = {
        query: `query($course_uuid: String!) 
                    { courseModules(course_uuid: $course_uuid) {
                        title
                        module_uuid
                    }}`
    };

    let mergeQuery = Object.assign({}, query, variables);

    return JSON.stringify(mergeQuery);
};


export const CREATE_COURSE_MODULE = (variables) => {
    let query = {
        query: `mutation CreateCourseModule($title: String!, $course_uuid: String!,$is_active:Boolean!) { 
                        createCourseModule (
                            courseModuleInput: {
                                course_uuid: $course_uuid 
                                title: $title, 
                                is_active:$is_active
                            }) {
                                module_uuid,
                            course_uuid,
                            title
                        }}`
    };

    let mergeQuery = Object.assign({}, query, variables);

    return JSON.stringify(mergeQuery);
};

export const UPDATE_COURSE_MODULE = (variables) => {
    let query = {
        query: `mutation UpdateCourseModule($title: String!, $course_uuid: String!,$module_uuid:String!,$is_active:Boolean!) { 
                         updateCourseModule (
                            courseModuleInput: {
                                course_uuid: $course_uuid 
                                title: $title, 
                                is_active: $is_active
                            },module_uuid:$module_uuid) {
                                module_uuid,
                            course_uuid,
                            title
                        }}`
    };

    let mergeQuery = Object.assign({}, query, variables);

    return JSON.stringify(mergeQuery);
};

export const REARRANGE_COURSE_MODULES = (variables) => {
    let query = {
        query: `mutation ReorderCourseModule($modules:[ModuleReorder]!) { 
                    reorderCourseModule(modules:$modules) {
                      code
                      reason
        }}`
    };
    let mergeQuery = Object.assign({}, query, variables);

    return JSON.stringify(mergeQuery);
}

export const VALIDATE_MODULE_PUBLISHABILITY = (variables) => {
    let query = {
        query: `query($module_uuid: String!) 
                    { modulePublishable(module_uuid: $module_uuid) {
                        is_publishable
                        message
                    }}`
    };
    let mergeQuery = Object.assign({}, query, variables);
    return JSON.stringify(mergeQuery);
};