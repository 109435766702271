export const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
            resolve(fileReader.result);
        }
        fileReader.onerror = (error) => {
            reject(error);
        }
    });
}

export const downloadBase64File = (binaryData, exportFileName = 'download.csv', mediaType = "application/csv") => {
    const downloadURL = document.createElement('a');
    downloadURL.href = `data:${mediaType};base64,${binaryData}`;
    downloadURL.download = exportFileName;
    document.body.appendChild(downloadURL);
    downloadURL.click();
    document.body.removeChild(downloadURL);
}

export const downloadDataFile = (url, exportFileName) => {
    window.open(url, '_blank', "toolbar=no,statusbar=no,menubar=no,scrollbars=yes,resizable=yes,top=500,left=500");
}