import { SET_CURRENT_CONTENT_PAGE, SET_CURRENT_MODULE, SET_VISITED_COURSE_PAGES } from "../actions/types";

const initialState = {
    "openContentPage": "",
    "openModule": "",
    "visitedCoursePages": {"pages":[], "assignments":[]}
}

export default function (state = initialState, action) {
    const { type, pageUUID, moduleUUID, visitedCoursePages } = action;

    switch (type) {
        case SET_CURRENT_CONTENT_PAGE:
            return { ...state, openContentPage: pageUUID };
        case SET_CURRENT_MODULE:
            return { ...state, openModule: moduleUUID };
        case SET_VISITED_COURSE_PAGES:
            return { ...state, visitedCoursePages: visitedCoursePages };
        default:
            return state;
    }
}