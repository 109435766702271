import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  LOGIN_REQUEST_PENDING,
  AUTHENTICATION_FAIL,
  CHANGE_USER_ROLE,
  WAIT_FOR_SSO_LOGIN
} from "../actions/types";

const user = JSON.parse(localStorage.getItem("user"));
const selfLogout = JSON.parse(localStorage.getItem("self_logout")) ?? false;

const initialState = user
  ? { isLoggedIn: true, user: user.user, pendingRequest: false, isSessionValid: true, originalRole: user.user.role, selfLogout, }
  : { isLoggedIn: false, user: null, pendingRequest: false, isSessionValid: false, originalRole: '', selfLogout };

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      };
    case LOGIN_SUCCESS:
      localStorage.setItem("user", JSON.stringify(payload));
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
        pendingRequest: false,
        isSessionValid: true,
        originalRole: payload.user.role,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        pendingRequest: false,
        user: null,
        isSessionValid: false,
        originalRole: '',
      };
    case LOGOUT:
      localStorage.setItem("self_logout", JSON.stringify(payload?.selfLogout));
      localStorage.removeItem("user");
      return {
        ...state,
        isLoggedIn: false,
        pendingRequest: false,
        isSessionValid: false,
        user: null,
        originalRole: '',
        selfLogout: payload?.selfLogout
      };
    case LOGIN_REQUEST_PENDING:
      return {
        ...state,
        isLoggedIn: false,
        pendingRequest: true,
        user: null,
        originalRole: '',
      };
    case AUTHENTICATION_FAIL:
      return {
        ...state,
        isSessionValid: false,
      };
    case CHANGE_USER_ROLE:
      let user = { ...state.user };
      user.role = payload;
      return {
        ...state,
        user: user,
      };
    case WAIT_FOR_SSO_LOGIN:
      localStorage.setItem("self_logout", JSON.stringify(true));
      return {
        ...state,
        selfLogout: true
      };
    default:
      return { ...state };
  }
}