export const FETCH_COURSES = JSON.stringify({
    query: `{ courses {
                    course_uuid,
                    course_name,
                    description,
                    status,
                    category
            }}`
});

export const FETCH_COURSE = (course_uuid) => {
    return JSON.stringify({
        query: `{ course(course_uuid: "${course_uuid}")  {
                        course_uuid,
                        course_name,
                        image,
                        short_description,
                        description,
                        status,
                        category,
                        is_autoEnrolled,
                        course_published,
                        course_owner,
                        course_owner_designation,
                }}`
    });
};

export const FETCH_COURSE_DESCRIPTION = (course_uuid) => {
    return JSON.stringify({
        query: `{ courseDescription(course_uuid: "${course_uuid}")  {
                        description
                }}`
    });
};

export const FETCH_COURSE_PAGES = (variables) => {
    let query = {
        query: `query($course_uuid: String!,$showArchives: Boolean) 
                    { coursePages(course_uuid: $course_uuid,showArchives: $showArchives) {
                        course_name
                        course_uuid
                        category
                        modules {
                            module_uuid
                            title
                            order
                            is_active
                            course_pages {
                                    page_uuid
                                    module_uuid
                                    title
                                    page_order
                                    status
                                }
                            assignments {
                                    assignment_uuid
                                    title
                                    type
                                    batch_number
                                }
                            }
                        assignments {
                            assignment_uuid
                            title
                            type
                            batch_number
                        }
                    }}`
    };

    let mergeQuery = Object.assign({}, query, variables);

    return JSON.stringify(mergeQuery);
};


export const CREATE_COURSE = (variables) => {
    let query = {
        query: `mutation CreateCourse($course_name: String!, $short_description: String!){
                createCourse (
                    courseInput: {
                        status: "INACTIVE",course_name:$course_name, short_description:$short_description, is_autoEnrolled:false
                    }) {
                        course_name,
                        course_uuid,
                        status,
                        category
                        description,
                        short_description,
                        is_autoEnrolled
                    }
                }`
    };
    let mergeQuery = Object.assign({}, query, variables);

    return JSON.stringify(mergeQuery);
};

export const UPDATE_COURSE = (variables) => {
    let query = {
        query: `mutation UpdateCourse($course_name: String!, $short_description: String!, $image: String!, $description: String!, $status: String!, $course_uuid: String!,$is_autoEnrolled: Boolean!,$category: String!,$course_owner: String, $course_owner_designation: String) { 
                        updateCourse (
                            courseInput: {
                                course_name: $course_name,
                                image: $image,
                                short_description: $short_description,
                                status: $status , 
                                description: $description, 
                                is_autoEnrolled: $is_autoEnrolled,
                                category: $category,
                                course_owner: $course_owner,
                                course_owner_designation: $course_owner_designation,
                            }, course_uuid:$course_uuid ) {
                                course_name,
                                course_uuid,
                                image,
                                status,
                                category,
                                short_description,
                                description,
                                is_autoEnrolled,
                                course_published,
                                course_owner,
                                course_owner_designation
                            }
                    }`
    };

    let mergeQuery = Object.assign({}, query, variables);

    return JSON.stringify(mergeQuery);
};


export const FETCH_COURSES_PAGINATE = (variables) => {
    let query = {
        query: `query($search: String, $page: Int!, $limit: Int!, $status: String!, $category: String, $requestID: String) 
                    { courses(search: $search, page: $page, limit: $limit, status: $status,category:$category, requestID:$requestID) {
                        count,
                        rows {
                            course_uuid
                            course_name
                            category
                            short_description
                            image
                            status
                            enrollments{
                                due_date
                                status
                            }
                        },
                        requestID
                    }}`
    };

    let mergeQuery = Object.assign({}, query, variables);

    return JSON.stringify(mergeQuery);
};

export const FETCH_COURSES_STATISTICS = (variables) => {
    let query = {
        query: `query($course_uuid: String!) 
                    { courseStats(course_uuid: $course_uuid) {
                        total_enrollment
                        total_users
                        total_attempt
                        total_complete
                        complete_rate
                    }}`
    };

    let mergeQuery = Object.assign({}, query, variables);

    return JSON.stringify(mergeQuery);
};

export const VALIDATE_COURSE_PUBLISHABILITY = (variables) => {
    let query = {
        query: `query($course_uuid: String!) 
                    { coursePublishable(course_uuid: $course_uuid) {
                        is_publishable
                        message
                    }}`
    };
    let mergeQuery = Object.assign({}, query, variables);
    return JSON.stringify(mergeQuery);
};
