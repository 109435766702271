// assets
import { CourseMenu } from 'routes/sidemenu';
import { AdminCoursesSideMenuItems } from 'utils';

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const courseInfo = {
    id: AdminCoursesSideMenuItems.rootId,
    title: 'Course',
    caption: '',
    type: 'group',
    children: [
        CourseMenu.courseDetails,
        CourseMenu.courseContent,
        CourseMenu.courseAssignment,
        CourseMenu.courseUserEnrollment,
        CourseMenu.courseEResources,
        CourseMenu.courseReport,
    ]
};

export default courseInfo;
