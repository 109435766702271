import { graphQLConnectionHandler } from '@core/graphql/graphQLConnectionHandler';
import { FETCH_COURSE_ASSESSMENT_CERTIFICATE, UPLOAD_COURSE_ASSESSMENT_CERTIFICATE } from '@core/graphql/queries/assessment';
import { Add, Close, InfoOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Divider, FormControl, FormHelperText, IconButton, InputLabel, OutlinedInput, Tooltip } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import FileUpload from 'react-material-file-upload';
import { useDispatch } from 'react-redux';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { convertToBase64, showUserNotification } from 'utils';
import * as Yup from 'yup';

const certificateTemplateGuide = `Following key words are read by the template engine.
$_RECEIVER_NAME : <Name of the recepient>
$_COURSE_NAME: <Name of the Course>
$_CERTIFICATE_DESCRIPTION : <Certificate Description>
$_ISSUE_YEAR : <yyyy>
$_ISSUE_DATE : <MM-DD-YYYY>
$_ISSUE_DAY : <1st of January 2022>\n
*Defined the requied key word in the template

`;

export default function AssessmentCertificateTemplateModal(props) {

    const { course_uuid } = props;
    const [open, setOpen] = useState(props.open);
    const [actionType, setActionType] = useState(props.actionType);
    const [message, setMessage] = useState(null);
    const [InitFormData, setInitFormData] = useState({ certificateDescription: '', template: '' });
    const [pendingRequest, setPendingRequest] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [files, setFiles] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    useEffect(() => {
        loadCertificateDetails();
    }, []);

    const loadCertificateDetails = () => {
        setLoading(true);
        let gqlVaribles = {
            variables: {
                course_uuid: course_uuid,
            }
        };
        let mergedRequest = FETCH_COURSE_ASSESSMENT_CERTIFICATE(gqlVaribles);
        graphQLConnectionHandler(dispatch, mergedRequest, (error, res) => {
            setLoading(false);
            if (error == null && res?.courseCertificateTemplate) {
                setInitFormData({
                    ...InitFormData,
                    certificateDescription: res.courseCertificateTemplate.certificate_description
                });
            }
        });
    }

    const handleClose = () => {
        props.onClose();
    };

    const submitAssessmentCertificates = async (description) => {
        setLoading(true);
        let gqlVaribles = {
            variables: {
                file: await convertToBase64(files[0]),
                course_uuid: course_uuid,
                certificate_description: description,
                action: 'CERTIFICATE_UPLOAD',
            }
        };
        let mergedRequest = UPLOAD_COURSE_ASSESSMENT_CERTIFICATE(gqlVaribles);
        graphQLConnectionHandler(dispatch, mergedRequest, (error, res) => {
            setLoading(false);
            setPendingRequest(false);
            if (!error && res?.courseAssessmentCertificateImport && res.courseAssessmentCertificateImport.id == 200) {
                showUserNotification("Course Assessment certificate successfully added", "SUCCESS");
                handleClose();
            } else {
                showUserNotification("Fail to upload course assessment certificate", "ERROR");
            }
        });
    }


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: InitFormData,
        validationSchema: Yup.object({
            certificateDescription: Yup.string().max(750).required('Certificate Description is required'),
            template: Yup.string().required('Certificate template is required')
        }),
        onSubmit: (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
            try {
                setPendingRequest(true);
                submitAssessmentCertificates(values.certificateDescription);
            } catch (err) {
                console.log('eroor ', err);
                showUserNotification('Unexpected error occurred while creating the Course module ', 'ERROR');
            }

        },
    });

    return (
        <Dialog open={open} onClose={handleClose} >
            <DialogTitle sx={{ fontSize: '16px', textAlign: 'center' }}>Assessment Certificate Details
                <IconButton
                    aria-label="close"
                    onClick={() => {
                        handleClose();
                    }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <Divider></Divider>
            <form noValidate onSubmit={formik.handleSubmit} >
                <DialogContent>
                    <FormControl size="medium" sx={{ mb: 2 }} fullWidth error={Boolean(formik.touched.assessmentTitle && formik.errors.assessmentTitle)}>
                        <InputLabel htmlFor="outlined-adornment-course-assessment-certificate-description">Certificate Description</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-course-assessment-certificate-description"
                            type="certificateDescription"
                            value={formik.values.certificateDescription}
                            name="certificateDescription"
                            size="medium"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            label="Certificate Description"
                            multiline
                            rows={3}
                            inputProps={{}}
                        />
                        {formik.touched.certificateDescription && formik.errors.certificateDescription && (
                            <FormHelperText error id="standard-weight-helper-text-course-assessment-certificate-description">
                                {formik.errors.certificateDescription}
                            </FormHelperText>
                        )}
                    </FormControl>
                    <FileUpload value={files} accept={".svg"} title="Drag 'n' drop svg formatted cerrificate template file here, or click to select files" onChange={(files) => {
                        setFiles(files);
                        formik.values.template = files[0];
                    }} />
                    {formik.touched.template && formik.errors.template && (
                        <FormHelperText sx={{ ml: 2 }} error id="standard-weight-helper-text-course-assessment-certificate-template">
                            {formik.errors.template}
                        </FormHelperText>
                    )}
                    <Tooltip sx={{ maxWidth: 480 }} title={<span style={{ whiteSpace: 'pre-line' }}>{certificateTemplateGuide}</span>}>
                        <InfoOutlined sx={{ float: 'left', mt: 1.5 }} />
                    </Tooltip>

                </DialogContent>
                <DialogActions sx={{ mb: 3, mr: 2 }}>
                    <AnimateButton>
                        <LoadingButton
                            loading={isLoading}
                            loadingPosition="start"
                            disableElevation
                            disabled={pendingRequest || isLoading}
                            fullWidth
                            startIcon={<Add />}
                            size="large"
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={() => { }}
                        >
                            Add Certificate
                        </LoadingButton>
                    </AnimateButton>
                </DialogActions>
            </form>
        </Dialog>
    );
}