
export const UPSERT_USERCOURSE_PAGE = (variables) => {
    let query = {
        query: `mutation UpsertUserCoursePage($course_uuid:String!, $assignment_uuid:[String], $page_uuid:[String]) { 
                            upsertUserCoursePage (
                                userCoursePageInput: {
                                    assignment_uuid: $assignment_uuid,
                                    page_uuid: $page_uuid,
                                }, course_uuid: $course_uuid 
                                ) {
                                    course_uuid,
                                    user_id,
                                    assignment_uuid,
                                    page_uuid
                                }
                }`
    };

    let mergeQuery = Object.assign({}, query, variables);

    return JSON.stringify(mergeQuery);
};