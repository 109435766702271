const getFormattedDay = (date) => {
    const currentDate = date ? date : new Date();
    const month = (currentDate.getUTCMonth() + 1).toString().padStart(2, "0");
    const day = currentDate.getUTCDate().toString().padStart(2, "0");
    return day + "/" + month + "/" + currentDate.getFullYear();
};

const getDateFromEpoch = (epoch = 0) => {
    return new Date(parseInt(epoch));
}

const getFormattedDayFromEpoch = (epoch = 0) => {
    return getFormattedDay(getDateFromEpoch(epoch));
}

export {
    getFormattedDay,
    getDateFromEpoch,
    getFormattedDayFromEpoch,
}