import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import customizationReducer from "./customizationReducer";
import sideMenuNavigation from "./sideMenuNavigation";
import course from "./course";
import courseSelection from "./courseSelectionReducer";

export default combineReducers({
  auth,
  message,
  customization: customizationReducer,
  sideMenu: sideMenuNavigation,
  course: course,
  selectedCourse: courseSelection,
});