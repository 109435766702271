import { drawerWidth } from '@core/actions/constant';
import { clearUserSelectedCourse } from '@core/actions/course';
import { SET_MENU } from '@core/actions/types';
import { HOME } from '@core/helpers/pageRoutings';
import { AppBar, Box, CssBaseline, Grid, Toolbar, useMediaQuery } from '@mui/material';
// material-ui
import { styled, useTheme } from '@mui/material/styles';
// assets
import { IconChevronRight } from '@tabler/icons';
import { useSelectedCourse } from 'hooks/useForceUpdate';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthFooter from 'ui-component/cards/AuthFooter';
// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Customization from '../Customization';
import Header from './Header';
import Sidebar from './Sidebar';





// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - 20),
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
    const navigation = useSelector((state) => state.sideMenu);
    const selectedCourse = useSelectedCourse();

    // Handle left drawer
    const leftDrawerOpened = useSelector((state) => state.customization.opened);

    const currentOpenPage = useSelector((state) => state.customization.currentOpenPage);

    const dispatch = useDispatch();
    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    };

    const visibleSideMenu = () => {
        switch (currentOpenPage) {
            case HOME:
                return false;
            default:
                return true;
        }
    }
    useEffect(() => {
        dispatch({ type: SET_MENU, opened: !matchDownMd });
        return () => {
            // dispatch(clearUserSelectedCourse());
        }
    }, [matchDownMd]);

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: theme.palette.background.default,
                    transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
                }}
            >
                <Toolbar>
                    <Header handleLeftDrawerToggle={handleLeftDrawerToggle} drawerToggleEnabled={visibleSideMenu()} />
                </Toolbar>
            </AppBar>

            {/* drawer */}
            <Sidebar drawerOpen={leftDrawerOpened && visibleSideMenu()} drawerToggle={handleLeftDrawerToggle} />

            {/* main content */}
            <Main theme={theme} open={leftDrawerOpened && visibleSideMenu()}>
                {/* breadcrumb */}
                <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                <Outlet />

                <Grid
                    item
                    xs={12}
                    sx={{
                        position: 'fixed',
                        left: 30,
                        bottom: 10,
                        right: 30
                    }}
                >
                    <AuthFooter />
                </Grid>
            </Main>
            <Customization />
            <ToastContainer></ToastContainer>
        </Box>
    );
};

export default MainLayout;
