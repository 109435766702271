import { gridSpacing } from '@core/actions/constant';
import { setSelectedCourse } from '@core/actions/course';
import { setCurrentPage } from '@core/actions/customization';
import { graphQLConnectionHandler } from '@core/graphql/graphQLConnectionHandler';
import { FETCH_CATEGORY_PAGINATE } from '@core/graphql/queries/category';
import { FETCH_COURSE, UPDATE_COURSE, VALIDATE_COURSE_PUBLISHABILITY } from '@core/graphql/queries/course';
import { Navigations } from '@core/helpers/Navigations';
import { useTheme } from '@emotion/react';
import { NoteAdd } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import courseBrandImage from 'assets/images/course/course-default1.png';
import Resizer from "react-image-file-resizer";
// material-ui
import {
    Autocomplete,
    Box, Button, Card,
    CardContent, CardMedia, FormControl,
    FormHelperText,
    Grid,
    Input,
    InputLabel,
    OutlinedInput, TextField, Tooltip, Typography
} from '@mui/material';
// import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { useFormik } from 'formik';
import useScriptRef from 'hooks/useScriptRef';
import { useMenuItemChanged } from 'hooks/utilityHooks';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { sideMenuRoutingFactory, useSideMenuRoute } from 'routes/sidemenu';
import SunEditor from 'suneditor-react';
import Switch from 'ui-component/switch';
// project imports
import { AdminCoursesSideMenuItems, showUserNotification } from 'utils';
import * as Yup from 'yup';
import { COURSE } from '../../@core/helpers/pageRoutings';

const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/png",
    "image/jpeg",
    "image/bmp",
];
// ==============================|| DEFAULT DASHBOARD ||============================== //

const ValidationSchema = Yup.object().shape({
    courseImage: Yup.mixed()
        .test("fileSize", "The File is too large ( should be lower than 100kb )", (value) => {

            let buffer = null;
            if (value)
                buffer = Buffer.from(value.split('base64,')[1], 'base64');
            return value && buffer.length <= 100000;
        }),
    courseName: Yup.string().max(255).required('Course Name is requirerd'),
    category: Yup.string().required('Category is required'),
    shortDescription: Yup.string().max(100, "Short description must be less than 100 characters").min(20, "Short description must be more than 20 characters").required('Short description is required'),
    courseDescription: Yup.string().max(10000, "Description must be less than 3000 characters").min(100, "Description must be more than 100 characters").required('Description is required')
});

let initFormData = {
    courseImage: '',
    courseName: '',
    courseDescription: '',
    shortDescription: '',
    courseStatus: false,
    category: '',
    isSelfEnrollCourse: false,
    courseOwner: '',
    courseOwnerDesignation: '',
    submit: null
};

const CourseDetail = () => {
    const navigate = useNavigate();
    const onPageLoad = useMenuItemChanged();
    const scriptedRef = useScriptRef();
    const { course_uuid } = useParams();
    const [isLoading, setLoading] = useState(true);
    const [course, setCourse] = useState(null);
    const [isCoursePublishable, setCoursePublishable] = useState(false);
    const [unPublishNotification, setUnPublishNotification] = useState("");
    const [courseCategories, setCourseCategories] = useState([]);
    const [defaultCourseCategory, setDefaultCourseCategory] = useState("");
    const [pendingRequest, setPendingRequest] = useState(false);
    const [message, setMessage] = useState(null);

    const dispatch = useDispatch();
    const theme = useTheme();
    useSideMenuRoute(sideMenuRoutingFactory.courseRoute(course_uuid));

    const [courseDescription, setCourseDescription] = useState('');
    const [courseStatus, setCourseStatus] = useState(false);
    const [isSelfEnrollCourse, setSelfEnrollCourse] = useState(false);

    const [enableEdit, setEnableEdit] = useState(false);

    const [selectedImage, setSelectedImage] = useState(null);

    const publishCourseDetailsUpdateEvent = (details) => {
        dispatch(setSelectedCourse(details));
    }

    const loadCourseDetails = () => {
        graphQLConnectionHandler(dispatch, FETCH_COURSE(course_uuid), (err, res) => {
            if (res.course == null) {
                showUserNotification('Course not found', 'WARN');
                navigate(Navigations.PORTAL);
            } else {
                setCourseDescription(res.course.description);
                setCourseStatus(res.course.status === "ACTIVE");
                setSelfEnrollCourse(res.course.is_autoEnrolled);
                setDefaultCourseCategory(res.course.category);
                initFormData.category = res.course.category;
                initFormData.courseDescription = res.course.description
                initFormData.courseName = res.course.course_name
                initFormData.shortDescription = res.course.short_description
                initFormData.courseOwner = res.course?.course_owner ?? ''
                initFormData.courseOwnerDesignation = res.course?.course_owner_designation ?? ''
                if (res.course.image) {
                    initFormData.courseImage = res.course.image
                    setSelectedImage(res.course.image)
                }
                setCourse(res.course);
                publishCourseDetailsUpdateEvent(res.course);
            }
            setLoading(false);
        });
    };

    const fetchCourseCategories = (searchQuery = '') => {
        let gqlVaribles = {
            variables: {
                searchQuery: searchQuery,
                page: 0,
                limit: 10,
                sortData: ['name', 'asc'],
            }
        };
        let mergedRequest = FETCH_CATEGORY_PAGINATE(gqlVaribles);
        graphQLConnectionHandler(dispatch, mergedRequest, (err, res) => {
            if (res?.categories == null) {
                showUserNotification('Courses categories not found', 'WARN');
            } else {
                const courseCategoryList = res.categories.rows.map(value => {
                    return {
                        label: value.name
                    }
                });
                setCourseCategories(courseCategoryList);
            }
        });
    };

    const validateCoursePublishability = () => {
        let gqlVaribles = {
            variables: { "course_uuid": course_uuid }
        }
        graphQLConnectionHandler(dispatch, VALIDATE_COURSE_PUBLISHABILITY(gqlVaribles), (err, res) => {
            if (!err && res?.coursePublishable != undefined) {
                setCoursePublishable(res?.coursePublishable.is_publishable);
                setUnPublishNotification(res?.coursePublishable.message);
            }
        });
    }

    const handleEditorChange = content => {
        var div = document.createElement("div");
        div.innerHTML = content;
        var text = div.textContent || div.innerText || "";
        div.remove();

        if (text.length > 0) {
            formik.setFieldValue("courseDescription", content);
            setTimeout(() => formik.setFieldTouched("courseDescription", true));
        } else {
            formik.setFieldValue('courseDescription', content);
        }

    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initFormData,
        validationSchema: ValidationSchema,
        onSubmit: (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
            try {

                if (scriptedRef.current) {
                    setPendingRequest(true);
                    let gqlVaribles = {
                        variables: {
                            "category": values.category,
                            "short_description": values.shortDescription.replace(/(?:\r\n|\r|\n)/g, '').trim(),
                            "course_name": values.courseName.replace(/(?:\r\n|\r|\n)/g, '').trim(),
                            "description": values.courseDescription.trim(),
                            "image": values.courseImage,
                            "status": courseStatus ? "ACTIVE" : "INACTIVE",
                            "course_uuid": course_uuid,
                            "is_autoEnrolled": isSelfEnrollCourse,
                            "course_owner": values.courseOwner,
                            "course_owner_designation": values.courseOwnerDesignation,
                        }
                    }

                    graphQLConnectionHandler(dispatch, UPDATE_COURSE(gqlVaribles), (error, response) => {
                        setPendingRequest(false);
                        if (!error) {
                            showUserNotification(response.updateCourse?.course_name + ' course has been updated successfully', "SUCCESS");
                            publishCourseDetailsUpdateEvent(response.updateCourse);
                        } else {
                            setMessage(error.message);
                        }
                    });
                }
            } catch (err) {
                console.error(err);
                if (scriptedRef.current) {
                    setStatus({ success: false });
                    setErrors({ submit: err.message });
                    setSubmitting(false);
                }
            }
        },
    });

    function dataURLtoFile(dataurl, filename) {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    useEffect(() => {
        dispatch(setCurrentPage(COURSE));
        onPageLoad(AdminCoursesSideMenuItems.courseDetails);
        loadCourseDetails();
        validateCoursePublishability();
    }, []);

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12} md={12}>
                        <Card>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs>
                                        <Typography gutterBottom variant="h4" component="div">
                                            Course Information
                                        </Typography>
                                    </Grid>
                                    <Grid display="flex" justifyContent="flex-end" item xs>
                                        <Switch inputLabel="Edit" triggerChange={enableEdit} onChange={() => setEnableEdit(!enableEdit)} />
                                    </Grid>
                                </Grid>

                                {enableEdit ? (

                                    <form noValidate onSubmit={formik.handleSubmit}>
                                        <FormControl
                                            fullWidth
                                            error={Boolean(formik.touched.courseName && formik.errors.courseName)}
                                            sx={{ ...theme.typography.customInput }}
                                        >
                                            <InputLabel htmlFor="outlined-adornment-courseName-course">Course Name</InputLabel>

                                            <OutlinedInput
                                                id="outlined-adornment-courseName-course"
                                                type="text"
                                                value={formik.values.courseName}
                                                name="courseName"
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                label="Course Name"
                                                inputProps={{}}
                                            />

                                            {formik.touched.courseName && formik.errors.courseName && (
                                                <FormHelperText error id="standard-weight-helper-text-courseName-course">
                                                    {formik.errors.courseName}
                                                </FormHelperText>
                                            )}

                                        </FormControl>

                                        <FormControl
                                            fullWidth
                                            error={Boolean(formik.touched.shortDescription && formik.errors.shortDescription)}
                                            sx={{ ...theme.typography.customInput }}
                                        >
                                            <InputLabel htmlFor="outlined-adornment-shortDescription-course">Description</InputLabel>

                                            <OutlinedInput
                                                id="outlined-adornment-shortDescription-course"
                                                type="text"
                                                value={formik.values.shortDescription}
                                                name="shortDescription"
                                                multiline
                                                rows={4}
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                label="Course Short Description"
                                                inputProps={{}}
                                            />

                                            {formik.touched.shortDescription && formik.errors.shortDescription && (
                                                <FormHelperText error id="standard-weight-helper-text-shortDescription-course">
                                                    {formik.errors.shortDescription}
                                                </FormHelperText>
                                            )}

                                        </FormControl>


                                        <FormControl
                                            fullWidth
                                            error={Boolean(formik.touched.courseDescription && formik.errors.courseDescription)}
                                            sx={{ ...theme.typography.customInput }}
                                        >

                                            <InputLabel htmlFor="outlined-adornment-description-course">Overview</InputLabel>
                                            <Box sx={{ pt: 1, width: 1 }}>
                                                <SunEditor
                                                    setContents={formik.values.courseDescription}
                                                    showToolbar={true}
                                                    name='courseDescription'
                                                    setDefaultStyle="height: 40vh"
                                                    onBlur={(event, editorContents) => {
                                                        handleEditorChange(editorContents);
                                                    }}
                                                    onChange={(editorContents) => {
                                                        handleEditorChange(editorContents);
                                                    }}
                                                    setOptions={{
                                                        videoHeight: "100%",
                                                        videoTagAttrs: { autoplay: true },
                                                        iframeAttributes: {
                                                            "style": "border : none",
                                                            "allow": "autoplay"
                                                        },
                                                        youtubeQuery: "autoplay=1&loop=1&autopause=0",
                                                        buttonList: [
                                                            [
                                                                "bold",
                                                                "underline",
                                                                "italic",
                                                                "strike",
                                                                "list",
                                                                "align",
                                                                "fontSize",
                                                                "formatBlock",
                                                                "table",
                                                                "undo",
                                                                "redo",
                                                                "font",
                                                                "fontColor",
                                                                "hiliteColor",
                                                            ]
                                                        ]
                                                    }}
                                                />

                                                {formik.touched.courseDescription && formik.errors.courseDescription && (
                                                    <FormHelperText error id="standard-weight-helper-text-description-course">
                                                        {formik.errors.courseDescription}
                                                    </FormHelperText>
                                                )}
                                            </Box>
                                        </FormControl>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <FormControl
                                                    fullWidth
                                                    error={Boolean(formik.touched.courseOwner && formik.errors.courseOwner)}
                                                    sx={{ ...theme.typography.customInput }}
                                                >
                                                    <InputLabel htmlFor="outlined-adornment-courseOwner-course">Course Owner</InputLabel>
                                                    <OutlinedInput
                                                        id="outlined-adornment-courseOwner-course"
                                                        type="text"
                                                        variant='outlined'
                                                        value={formik.values.courseOwner}
                                                        name="courseOwner"
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        label="Course Owner"
                                                    />

                                                    {formik.touched.courseOwner && formik.errors.courseOwner && (
                                                        <FormHelperText error id="standard-weight-helper-text-courseOwner-course">
                                                            {formik.errors.courseOwner}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6} justify="flex-end">
                                                <FormControl
                                                    fullWidth
                                                    error={Boolean(formik.touched.courseOwnerDesignation && formik.errors.courseOwnerDesignation)}
                                                    sx={{ ...theme.typography.customInput }}
                                                >
                                                    <InputLabel htmlFor="outlined-adornment-courseOwner-course">Course Owner Designation</InputLabel>
                                                    <OutlinedInput
                                                        id="outlined-adornment-courseOwnerDesignation-course"
                                                        type="text"
                                                        variant='outlined'
                                                        value={formik.values.courseOwnerDesignation}
                                                        name="courseOwnerDesignation"
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        label="Course Owner Designation"
                                                    />

                                                    {formik.touched.courseOwnerDesignation && formik.errors.courseOwnerDesignation && (
                                                        <FormHelperText error id="standard-weight-helper-text-courseOwnerDesignation-course">
                                                            {formik.errors.courseOwnerDesignation}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <FormControl fullWidth>
                                            <br />
                                            <Grid item container xs={12}>
                                                <Grid item xs={4}>
                                                    <Card sx={{ maxWidth: 345 }}>

                                                        <CardMedia
                                                            component="img"
                                                            height="140"
                                                            image={selectedImage ? selectedImage : courseBrandImage}
                                                        />
                                                    </Card>
                                                </Grid>
                                                <Grid item xs={2} sx={{ alignContent: "center" }}>
                                                    <Grid direction={"row"} container justifyContent={"center"}>
                                                        <label htmlFor="contained-button-file" >
                                                            <Input sx={{ display: "none" }}
                                                                inputProps={{
                                                                    accept: "image/png, image/jpeg, image/jpg, image/bmp"
                                                                }} id="contained-button-file" type="file" onChange={(event) => {

                                                                    let newCourseImage = null;
                                                                    Resizer.imageFileResizer(
                                                                        event.target.files[0],
                                                                        300,
                                                                        212,
                                                                        "JPEG",
                                                                        100,
                                                                        0,
                                                                        (uri) => {
                                                                            newCourseImage = dataURLtoFile(uri, 'courseImg.jpeg');
                                                                            formik.setFieldValue("courseImage", uri)
                                                                            setSelectedImage(uri);
                                                                        },
                                                                        "base64"
                                                                    );

                                                                }} name="courseImage" />
                                                            <Button variant="contained" component="span" sx={{ float: "right" }}>
                                                                Upload Course Image
                                                            </Button>
                                                        </label>
                                                    </Grid>

                                                    <Grid direction={"row"} container justifyContent={"center"}>
                                                        <Typography component="div">
                                                            <Box sx={{ fontSize: 'default', fontSize: "10px" }}>(.jpg, .jpeg, .png, .bmp | 100kb)</Box>
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                {formik.touched.courseImage && formik.errors.courseImage && (
                                                    <FormHelperText error id="standard-weight-helper-text-courseImage-course">
                                                        {formik.errors.courseImage}
                                                    </FormHelperText>
                                                )}
                                            </Grid>
                                        </FormControl>
                                        <Grid container spacing={2}>
                                            <Grid item xs={8}>
                                                <Autocomplete
                                                    disablePortal
                                                    id="user-course-category-component"
                                                    multiple={false}
                                                    defaultValue={defaultCourseCategory}
                                                    value={defaultCourseCategory || ''}
                                                    onChange={(event, value, reason) => {
                                                        setDefaultCourseCategory(value?.label ?? 'ALL');
                                                        formik.setFieldValue('category', value?.label ?? 'ALL');
                                                    }}
                                                    onInputChange={(event, value, reason) => {
                                                        fetchCourseCategories(value);
                                                    }}
                                                    sx={{ mt: 3, float: 'left' }}
                                                    options={courseCategories}
                                                    fullWidth
                                                    renderInput={(params) => <TextField  {...params} style={{ top: '-3px' }} label="Category" />}
                                                />
                                                {formik.touched.category && formik.errors.category && (
                                                    <FormHelperText error id="standard-weight-helper-text-description-course">
                                                        {formik.errors.category}
                                                    </FormHelperText>
                                                )}
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <Tooltip title={unPublishNotification} placement="top-start">
                                                    <FormControl
                                                        fullWidth
                                                        error={Boolean(formik.touched.courseStatus && formik.errors.courseStatus)}
                                                        sx={{ ...theme.typography.customInput }}
                                                    >       <Switch inputLabel="Activate course" color="rgb(101, 196, 102)" triggerChange={courseStatus} onChange={() => {
                                                        if (isCoursePublishable)
                                                            setCourseStatus(!courseStatus);
                                                    }} />
                                                    </FormControl>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={6} justify="flex-end">
                                                <FormControl
                                                    fullWidth
                                                    error={Boolean(formik.touched.isSelfEnrollCourse && formik.errors.isSelfEnrollCourse)}
                                                    sx={{ ...theme.typography.customInput }}
                                                >
                                                    <Switch inputLabel="Self Enroll Course" color="rgb(101, 196, 102)" triggerChange={isSelfEnrollCourse} onChange={() => setSelfEnrollCourse(!isSelfEnrollCourse)} />
                                                </FormControl>
                                            </Grid>

                                        </Grid>
                                        {formik.errors.submit ||
                                            (message && (
                                                <Box sx={{ mt: 3 }}>
                                                    <FormHelperText error style={{ textAlign: 'center' }}>
                                                        {formik.errors.submit ?? message}
                                                    </FormHelperText>
                                                </Box>
                                            ))}

                                        <Box sx={{ mt: 2 }}>
                                            <LoadingButton
                                                loading={pendingRequest}
                                                loadingPosition="start"
                                                disableElevation
                                                disabled={pendingRequest}
                                                fullWidth
                                                startIcon={<NoteAdd />}
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                            >
                                                Update Now
                                            </LoadingButton>
                                        </Box>
                                    </form>
                                ) : (

                                    <Grid item container xs={12} m={1} spacing={3}>
                                        <Grid item container direction={"row"}>
                                            <Typography variant="h2" sx={{ marginBottom: "20px" }}>{course?.course_name}</Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography variant="h4">Short Description</Typography>
                                            <Typography variant="body2" color="text.primary">{course?.short_description}</Typography>
                                            <br />
                                            <Typography variant="h4">Overview</Typography>
                                            <Typography variant="body2" color="text.primary" dangerouslySetInnerHTML={{ __html: courseDescription }}></Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <CardMedia
                                                component="img"
                                                height="140"
                                                image={selectedImage ? selectedImage : courseBrandImage}
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default CourseDetail;
