// assets
import { Navigations } from '@core/helpers/Navigations';
import { Category, MenuBookRounded } from '@mui/icons-material';
import { IconCertificate, IconUsers,IconFileReport } from '@tabler/icons';
import { AdminUserSideMenuItems } from 'utils';
// constant
const icons = {
    IconUsers,
    IconCertificate,
    IconFileReport,
    Category,
    MenuBookRounded,
};

// ==============================|| PROFILE MENU ITEMS ||============================== //

const administrativeSideMenu = {
    id: AdminUserSideMenuItems.rootId,
    title: 'Users',
    type: 'group',
    children: [
        {
            id: AdminUserSideMenuItems.registeredUsers,
            title: 'Registered Users',
            type: 'item',
            url: Navigations.ADMIN + Navigations.USERS,
            icon: icons.IconUsers,
            breadcrumbs: false,
            getUrl: (subRoute = '') => {
                return Navigations.ADMIN + Navigations.USERS + '/' + subRoute;
            }
        },
        {
            id: AdminUserSideMenuItems.enrolledUsers,
            title: 'Course Enrollment',
            type: 'item',
            url: Navigations.ADMIN + Navigations.ADMIN_USER_COURSE_ENROLLMENT,
            icon: icons.IconCertificate,
            breadcrumbs: false,
            getUrl: (subRoute = '') => {
                return Navigations.ADMIN + Navigations.ADMIN_USER_COURSE_ENROLLMENT + '/' + subRoute;
            }
        },
        {
            id: AdminUserSideMenuItems.adminCourseEResources,
            title: 'Reference Materials',
            type: 'item',
            url: Navigations.ADMIN + Navigations.ADMIN_USER_REFERENCE_MATERIALS,
            icon: icons.MenuBookRounded,
            breadcrumbs: false,
            getUrl: (subRoute = '') => {
                return Navigations.ADMIN + Navigations.ADMIN_USER_REFERENCE_MATERIALS + '/' + subRoute;
            }
        },
        {
            id: AdminUserSideMenuItems.courseCategory,
            title: 'Course Category',
            type: 'item',
            url: Navigations.ADMIN + Navigations.CATEGORY,
            icon: icons.Category,
            breadcrumbs: false,
            getUrl: (subRoute = '') => {
                return Navigations.ADMIN + Navigations.CATEGORY + '/' + subRoute;
            }
        },
        {
            id: AdminUserSideMenuItems.adminCourseReports,
            title: 'Course Reports',
            type: 'item',
            url: Navigations.ADMIN + Navigations.ADMIN_COURSE_REPORTS,
            icon: icons.IconFileReport,
            breadcrumbs: false,
            getUrl: (subRoute = '') => {
                return Navigations.ADMIN + Navigations.ADMIN_COURSE_REPORTS + '/' + subRoute;
            }
        },
    ]
};

export default administrativeSideMenu;
