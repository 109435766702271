import { SET_CURRENT_PAGE } from "./types";


const onOpenPageChange = (currentOpenPage) => {
  return {
    type: SET_CURRENT_PAGE,
    currentOpenPage: currentOpenPage
  }
};

export const setCurrentPage = (currentOpenPage) => (dispatch) => {
  return dispatch(onOpenPageChange(currentOpenPage));
};