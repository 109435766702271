export const Navigations = {
    PORTAL: '/portal',
    COURSE: '/course',
    LIBRARY: '/library',
    COURSE_VIEW: '/course/view',
    COURSE_CONTENT: '/course/content',
    COURSE_DETAILS: '/course/detail',
    COURSE_ASSIGNMENT: '/course/assignment',
    COURSE_USER_ENROLLMENT: '/course/user-enrollment',
    COURSE_E_MATERIALS: '/course/e-materials',
    COURSE_ASSIGNMENT_QUIZ: '/course/assignment',
    COURSE_REPORT: '/course/report',
    LOGIN: '/',
    ADMIN: '/admin',
    USERS: '/users',
    CATEGORY: '/category',
    ADMIN_USER_COURSE_ENROLLMENT: '/course-enrollments',
    ADMIN_USER_REFERENCE_MATERIALS: '/course-e-materials',
    ADMIN_COURSE_REPORTS: '/course-reports'
}

export const SideMenuTypes = {
    COURSE: 'course',
    ADMIN_USERS: 'admin_users',
}