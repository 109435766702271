export const FETCH_COURSE_REFERENCE_MATERIAL_PAGINATE = (variables) => {
  let query = {
    query: `query($searchQuery: String, $page: Int!, $limit: Int!, $filter: [UserInputFilterData],$course_uuid: String,$sortData:[String]!)
                    { referenceMaterials(searchQuery: $searchQuery, page: $page, limit: $limit, filter: $filter,course_uuid:$course_uuid,sortData:$sortData) {
                        count,
                        rows {
                            reference_uuid
                            title
                            description
                            status
                            version
                            course_uuid
                            resource_path
                            createdAt
                            updatedAt
                        }
                    }}`
  };

  let mergeQuery = Object.assign({}, query, variables);

  return JSON.stringify(mergeQuery);
};
export const FETCH_COURSE_REFERENCE_MATERIAL_DOWNLOAD_LINK = (variables) => {
  let query = {
    query: `query($filename: String!)
                    { referenceMaterialsDownloadLink(filename: $filename) {
                        url
                        status
                        filename
                    }}`
  };

  let mergeQuery = Object.assign({}, query, variables);

  return JSON.stringify(mergeQuery);
};

export const FETCH_COURSE_REFERENCE_MATERIAL_AVAILABILITY = (variables) => {
  let query = {
    query: `query($course_uuid: String!)
                    { checkCourseReferenceMaterialAvailability(course_uuid: $course_uuid) {
                        referenceFileAvailable
                    }}`
  };
  let mergeQuery = Object.assign({}, query, variables);
  return JSON.stringify(mergeQuery);
};

export const UPDATE_COURSE_REFERENCE_MATERIAL = (variables) => {
  let query = {
    query: `mutation UpdateCourseReferenceMaterial($courseReferenceMaterial: ReferenceMaterialInputData!) {
            updateCourseReferenceMaterial (
                            courseReferenceMaterial: $courseReferenceMaterial) {
                             id
                             response
                        }}`
  };

  let mergeQuery = Object.assign({}, query, variables);

  return JSON.stringify(mergeQuery);
};
export const DELETE_COURSE_REFERENCE_MATERIAL = (variables) => {
  let query = {
    query: `mutation DeleteCourseReferenceMaterial($courseReferenceMaterial: ReferenceMaterialDeletionInputData!) {
            deleteCourseReferenceMaterial (
                courseReferenceMaterial: $courseReferenceMaterial) {
                             id
                             response
                        }}`
  };
  let mergeQuery = Object.assign({}, query, variables);
  return JSON.stringify(mergeQuery);
};

export const UPLOAD_TRAINING_CATALOGUE_REFERENCE_MATERIAL = (variables) => {
  let query = {
    query: `mutation UploadAcademyTrainingCatalogue($catalogueReferenceMaterial: TrainingCatalogueInputData!) {
        uploadAcademyTrainingCatalogue (
          catalogueReferenceMaterial: $catalogueReferenceMaterial) {
                           id
                           response
                      }}`
  };

  let mergeQuery = Object.assign({}, query, variables);

  return JSON.stringify(mergeQuery);
};

export const FETCH_TRAINING_CATALOGUE_AVAILABILITY = (variables) => {
     return JSON.stringify({
       query: `{ checkTrainingCatalogueAvailability{
                   catalogueAvailable
            }}`
  });

};

export const FETCH_TRAINING_CATALOGUE_DOWNLOAD_LINK = (variables) => {
  return JSON.stringify({
    query: `{ courseCatalogueDownloadLink{
                url
                status
                filename
         }}`
});

};
