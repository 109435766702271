export const FETCH_CATEGORY_PAGINATE = (variables) => {
    let query = {
        query: `query($searchQuery: String, $page: Int!, $limit: Int!, $sortData: [String]!) 
                    { categories(searchQuery: $searchQuery, page: $page, limit: $limit, sortData:$sortData) {
                        count,
                        rows {
                            name
                        }
                    }}`
    };

    let mergeQuery = Object.assign({}, query, variables);

    return JSON.stringify(mergeQuery);
};
export const UPDATE_COURSE_CATEGORY = (variables) => {
    let query = {
        query: `mutation UpdateCourseCategory($categoryDetail: CategoryInputData!) { 
            updateCourseCategory ( categoryDetail:$categoryDetail ) {
                                name,
                                id
                            }
                    }`
    };
    let mergeQuery = Object.assign({}, query, variables);
    return JSON.stringify(mergeQuery);
};
export const CREATE_COURSE_CATEGORY = (variables) => {
    let query = {
        query: `mutation CreateCourseCategory($categoryDetail: CategoryInputData!) { 
            createCourseCategory ( categoryDetail:$categoryDetail ) {
                                name,
                                id
                            }
                    }`
    };
    let mergeQuery = Object.assign({}, query, variables);
    return JSON.stringify(mergeQuery);
};