import { Navigations } from "@core/helpers/Navigations";
import { CardMembership, DataThresholding, MenuBookRounded } from '@mui/icons-material';
import { IconDashboard, IconEdit, IconFileReport, IconKey, IconNotebook } from '@tabler/icons';
import { AdminCoursesSideMenuItems } from "utils";
const icons = {
    IconKey,
    IconEdit,
    IconFileReport,
    IconNotebook,
    IconDashboard,
    DataThresholding,
    CardMembership,
    MenuBookRounded,
};

export const CourseMenu = {
    courseDetails: {
        id: AdminCoursesSideMenuItems.courseDetails,
        title: 'Course Details',
        type: 'item',
        url: Navigations.COURSE_DETAILS,
        target: true,
        breadcrumbs: false,
        icon: icons.DataThresholding,
        getUrl: (subRoute = '') => {
            return Navigations.COURSE_DETAILS + '/' + subRoute;
        }
    },
    courseContent: {
        id: AdminCoursesSideMenuItems.courseContent,
        title: 'Course Content',
        type: 'item',
        url: Navigations.COURSE_CONTENT,
        target: true,
        breadcrumbs: false,
        icon: icons.IconEdit,
        getUrl: (subRoute = '') => {
            return Navigations.COURSE_CONTENT + '/' + subRoute;
        }
    },
    courseAssignment: {
        id: AdminCoursesSideMenuItems.courseAssessment,
        title: 'Assessment',
        type: 'item',
        url: Navigations.COURSE_ASSIGNMENT + '/',
        target: true,
        breadcrumbs: false,
        icon: icons.IconNotebook,
        getUrl: (subRoute = '') => {
            return Navigations.COURSE_ASSIGNMENT + '/' + subRoute;
        }
    },
    courseUserEnrollment: {
        id: AdminCoursesSideMenuItems.courseUserEnrollment,
        title: 'User Enrollment',
        type: 'item',
        url: Navigations.COURSE_USER_ENROLLMENT + '/',
        target: true,
        breadcrumbs: false,
        icon: icons.CardMembership,
        getUrl: (subRoute = '') => {
            return Navigations.COURSE_USER_ENROLLMENT + '/' + subRoute;
        }
    },
    courseEResources: {
        id: AdminCoursesSideMenuItems.courseEResources,
        title: 'Reference Materials',
        type: 'item',
        url: Navigations.COURSE_E_MATERIALS + '/',
        target: true,
        breadcrumbs: false,
        icon: icons.MenuBookRounded,
        getUrl: (subRoute = '') => {
            return Navigations.COURSE_E_MATERIALS + '/' + subRoute;
        }
    },
    courseReport: {
        id: AdminCoursesSideMenuItems.courseReport,
        title: 'Reports',
        type: 'item',
        url: Navigations.COURSE_REPORT + '/',
        target: true,
        breadcrumbs: false,
        icon: icons.IconFileReport,
        getUrl: (subRoute = '') => {
            return Navigations.COURSE_REPORT + '/' + subRoute;
        }
    },
    courseDashboard: {
        id: AdminCoursesSideMenuItems.courseDashboard,
        title: 'Course Dashboard',
        type: 'item',
        url: '',
        icon: icons.IconDashboard,
        breadcrumbs: false,
        getUrl: (subRoute = '') => {
            return Navigations.COURSE + '/' + subRoute;
        }
    }
}
