import { graphQLConnectionHandler } from '@core/graphql/graphQLConnectionHandler';
import { CREATE_COURSE_ASSESSMENT, UPDATE_COURSE_ASSESSMENT, VALIDATE_ASSESSMENT_PUBLISHABILITY } from '@core/graphql/queries/assessment';
import { FETCH_COURSE_MODULES } from '@core/graphql/queries/courseModule';
import { Autocomplete, Box, FormControl, FormHelperText, Grid, InputLabel, MenuItem, OutlinedInput, Select, TextField, Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import useForceUpdate from 'hooks/useForceUpdate';
import useScriptRef from 'hooks/useScriptRef';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showUserNotification } from 'utils';
import * as Yup from 'yup';


export default function AssessmentCreateModal({ action, assessmentDetails, onClose, open, courseUUID, onFormSubmissionSuccess }) {

    let initFormData = {
        assessmentTitle: assessmentDetails?.title ?? '',
        batch_number: assessmentDetails?.batch_number ?? '',
        status: assessmentDetails?.status ?? 'INACTIVE',
        module_uuid: assessmentDetails?.module_uuid ?? '',
        passing_score: assessmentDetails?.passing_score ?? '',
        type: assessmentDetails?.type ?? 'QUIZ',
        number_display_questions: assessmentDetails?.number_display_questions ?? 0,
    };




    const forceUpdate = useForceUpdate();
    const [message, setMessage] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [courseModules, setCourseModules] = useState([]);
    const [assessmentCourseModule, setAssessmentCourseModule] = useState(assessmentDetails.module_uuid ?? null);
    const [InitFormData, setInitFormData] = useState(initFormData);
    const [pendingRequest, setPendingRequest] = useState(false);
    const [defaultAssementModule, setDefaultAssessmentModule] = useState("");
    const [assessmentModuleValidationMsg, setAssessmentModuleValidationMsg] = useState(null);
    const [isAssessmentPublishable, setAssessmentPublishable] = useState(false);

    const dispatch = useDispatch();
    const scriptedRef = useScriptRef();

    const fetchCourseModules = () => {
        graphQLConnectionHandler(dispatch, FETCH_COURSE_MODULES(
            {
                variables: { course_uuid: courseUUID }
            }), (err, res) => {
                if (res?.courseModules == null) {
                    showUserNotification('Course madules not found', 'WARN');
                } else {
                    const moduleList = res.courseModules.map(value => {
                        return {
                            module_uuid: value.module_uuid,
                            label: value.title
                        }
                    })
                    if (action == 'UPDATE' && assessmentDetails.module_uuid) {
                        let findvalue = moduleList.find(value => value.module_uuid == assessmentDetails.module_uuid);
                        setDefaultAssessmentModule(findvalue?.label ?? '');
                    }
                    setCourseModules(moduleList);
                    forceUpdate();
                }
                setLoading(false);
            });
    };

    const validateAssignmentPublishability = () => {

        if (action === 'UPDATE') {
            let activeQuestions = assessmentDetails?.quizzes.filter(function (el) {
                return el.status == "ACTIVE";
            });

            if (activeQuestions.length < parseInt(formik.values.number_display_questions) ) {
                setAssessmentPublishable(false)
                setMessage("Not able to update status of assessment due to questions unavailability")
            } else {
                setAssessmentPublishable(true)
                setMessage(null)
            }
        }
    }

    useEffect(() => {
        fetchCourseModules();
        validateAssignmentPublishability();
    }, []);

    const handleClose = () => {
        onClose(false);
    };

    const isAssessmentModuleRequired = (quizType, selectedModule = assessmentCourseModule) => {
        if (quizType === 'QUIZ' && (selectedModule == undefined)) {
            setAssessmentModuleValidationMsg('Select the relevant course module');
            return
        } else {
            setAssessmentModuleValidationMsg(null);
        }
    }

    const formik = useFormik({
        initialValues: InitFormData,
        validationSchema: Yup.object({
            assessmentTitle: Yup.string().max(800).required('Assessment Title is required'),
            batch_number: Yup.string().max(50).required('Assessment Version is required'),
            status: Yup.string().max(100).required('Assessment Status is required'),
            passing_score: Yup.number().required('Assessment Passing Score is required').typeError('Assessment Passing Score required numeric value').min(10, 'Minimum passing score is value 10.').max(100, 'Maximum passing score is 100.'),
            type: Yup.string().max(100).required('Assessment Type is required'),
            number_display_questions: Yup.number().required('Display questions count is required').typeError('Display questions count required numeric value').min(1, 'Display questions count is value 1.'),

        }),
        onSubmit: (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
            try {

                if (isAssessmentPublishable || action == 'CREATE')
                    if (scriptedRef.current) {

                        if (values.type === 'QUIZ' && (assessmentCourseModule == undefined)) {
                            return
                        }
                        let gqlVaribles = {
                            variables: {
                                course_uuid: courseUUID,
                                title: values.assessmentTitle,
                                batch_number: values.batch_number,
                                passing_score: parseInt(values.passing_score),
                                status: values.status,
                                type: values.type,
                                number_display_questions: parseInt(values.number_display_questions),
                                module_uuid: values.type === 'QUIZ' ? assessmentCourseModule : null,
                                assignment_uuid: assessmentDetails?.assignment_uuid ?? null
                            }
                        };

                        let actionOperator = action == 'CREATE' ? CREATE_COURSE_ASSESSMENT(gqlVaribles) : UPDATE_COURSE_ASSESSMENT(gqlVaribles);
                        setPendingRequest(true);

                        graphQLConnectionHandler(dispatch, actionOperator, (error, response) => {
                            console.log(error, response);
                            setPendingRequest(false);
                            if (!error) {
                                showUserNotification(
                                    'Course Assessment has been ' + action.toLowerCase() + 'd successfully',
                                    'SUCCESS'
                                );
                                onFormSubmissionSuccess()
                                resetForm();
                            } else {
                                setMessage(error.message);
                            }
                        });
                    }
            } catch (err) {
                console.log('eroor ', err);
                showUserNotification('Unexpected error occurred while creating Assessment ', 'ERROR');
            }

        },
    });
    

    const clearErrorMsg = () => {
        setMessage(null);
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle sx={{ fontSize: '16px', textAlign: 'center' }}> {action === 'CREATE' ? 'Create Course Assessment' : 'Update Course Assessment'}</DialogTitle>
            <form noValidate onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <FormControl size="medium" sx={{ mb: 2 }} fullWidth error={Boolean(formik.touched.assessmentTitle && formik.errors.assessmentTitle)}>
                        <InputLabel htmlFor="outlined-adornment-course-assessment-title">Assessment Title</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-course-assessment-title"
                            type="assessmentTitle"
                            value={formik.values.assessmentTitle}
                            name="assessmentTitle"
                            size="medium"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            label="Assessment Title"
                            inputProps={{}}
                        />
                        {formik.touched.assessmentTitle && formik.errors.assessmentTitle && (
                            <FormHelperText error id="standard-weight-helper-text-course-assessment-title">
                                {formik.errors.assessmentTitle}
                            </FormHelperText>
                        )}
                    </FormControl>
                    <FormControl size="medium" sx={{ mb: 2 }} fullWidth error={Boolean(formik.touched.batch_number && formik.errors.batch_number)}>
                        <InputLabel htmlFor="outlined-adornment-course-assessment-batchNo">Assessment Version</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-course-assessment-batchNo"
                            type="batch_number"
                            value={formik.values.batch_number}
                            name="batch_number"
                            size="medium"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            label="Assessment Version"
                            inputProps={{}}
                        />
                        {formik.touched.batch_number && formik.errors.batch_number && (
                            <FormHelperText error id="standard-weight-helper-text-course-assessment-batchNo">
                                {formik.errors.batch_number}
                            </FormHelperText>
                        )}
                    </FormControl>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <FormControl size="medium" sx={{ mb: 2 }} fullWidth error={Boolean(formik.touched.passing_score && formik.errors.passing_score)}>
                                <InputLabel htmlFor="outlined-adornment-course-assessment-passing_score">Assessment Passing Score</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-course-assessment-passing_score"
                                    type="number"
                                    value={formik.values.passing_score}
                                    name="passing_score"
                                    size="medium"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    label="Assessment Passing Score"
                                    inputProps={{}}
                                />
                                {formik.touched.passing_score && formik.errors.passing_score && (
                                    <FormHelperText error id="standard-weight-helper-text-course-assessment-passing_score">
                                        {formik.errors.passing_score}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl size="medium" sx={{ mb: 2 }} fullWidth error={Boolean(formik.touched.number_display_questions && formik.errors.number_display_questions)}>
                                <InputLabel htmlFor="outlined-adornment-number-display-questions">Display Questions</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-number-display-questions"
                                    type="number"
                                    value={formik.values.number_display_questions}
                                    name="number_display_questions"
                                    size="medium"
                                    onBlur={formik.handleBlur}
                                    onChange={(event) => {
                                        formik.values.number_display_questions = event.target.value;
                                        validateAssignmentPublishability();
                                        forceUpdate();
                                    }}
                                    label="Display Questions"
                                    inputProps={{}}
                                />
                                {formik.touched.number_display_questions && formik.errors.number_display_questions && (
                                    <FormHelperText error id="standard-weight-helper-text-number-display-questions">
                                        {formik.errors.number_display_questions}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <FormControl size="medium" sx={{ mb: 2 }} fullWidth error={Boolean(formik.touched.status && formik.errors.status)}>
                                <InputLabel htmlFor="outlined-adornment-course-assessment-status">Assessment Status</InputLabel>
                                <Select
                                    id="outlined-adornment-course-assessment-status"
                                    type="assessmentStatus"
                                    value={formik.values.status}
                                    name="assessmentStatus"
                                    size="medium"
                                    onBlur={formik.handleBlur}
                                    onChange={(event) => {
                                        formik.values.status = event.target.value;
                                        forceUpdate();
                                    }}
                                    disabled={action === 'CREATE' || !isAssessmentPublishable}
                                    label="Assessment Status"
                                    inputProps={{}}
                                >
                                    <MenuItem value={"ACTIVE"}>ACTIVE</MenuItem>
                                    <MenuItem value={"INACTIVE"}>INACTIVE</MenuItem>
                                </Select>
                                {formik.touched.status && formik.errors.status && (
                                    <FormHelperText error id="standard-weight-helper-text-course-assessment-status">
                                        {formik.errors.status}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl size="medium" sx={{ mb: 2 }} fullWidth error={Boolean(formik.touched.type && formik.errors.type)}>
                                <InputLabel htmlFor="outlined-adornment-course-assessment-type">Assessment Type</InputLabel>
                                <Select
                                    id="outlined-adornment-course-assessment-type"
                                    type="assessmentType"
                                    value={formik.values.type}
                                    name="assessmentType"
                                    size="medium"
                                    onBlur={formik.handleBlur}
                                    onChange={(event) => {
                                        formik.values.type = event.target.value;
                                        forceUpdate();
                                        isAssessmentModuleRequired(event.target.value);
                                    }}
                                    label="Assessment Type"
                                    inputProps={{}}
                                >
                                    <MenuItem value={"ASSESSMENT"}>ASSESSMENT</MenuItem>
                                    <MenuItem value={"QUIZ"}>QUIZ</MenuItem>
                                </Select>
                                {formik.touched.type && formik.errors.type && (
                                    <FormHelperText error id="standard-weight-helper-text-course-assessment-type">
                                        {formik.errors.type}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                    </Grid>
                    {formik.values.type == 'QUIZ' &&
                        <>
                            <Autocomplete
                                disablePortal
                                id="assessment-module-select-component"
                                multiple={false}
                                defaultValue={defaultAssementModule}
                                value={defaultAssementModule || ''}
                                onChange={(event, value, reason) => {
                                    setAssessmentCourseModule(value?.module_uuid ?? undefined);
                                    setDefaultAssessmentModule(value?.label ?? '');
                                    isAssessmentModuleRequired(formik.values.type, value?.module_uuid ?? undefined);
                                }}
                                options={courseModules}
                                fullWidth
                                renderInput={(params) => <TextField  {...params} label="Assessment Module" />}
                            />
                            {assessmentModuleValidationMsg != null && (
                                <FormHelperText sx={{ ml: 2 }} error id="standard-weight-helper-text-course-assessment-module">
                                    {assessmentModuleValidationMsg}
                                </FormHelperText>
                            )}
                        </>
                    }

                    {formik.errors.submit || message &&
                        <Box sx={{ mt: 3 }}>
                            <FormHelperText error sx={{ textAlign: 'center' }} id="standard-weight-helper-text-course-assessment-message"  >
                                {formik.errors.submit ?? message}
                            </FormHelperText>
                        </Box>
                    }
                </DialogContent>
                <DialogActions>
                    <Button disabled={pendingRequest || isLoading} type="submit"
                        onClick={() => { isAssessmentModuleRequired(formik.values.type) }}>{action == 'CREATE' ? 'Create' : 'Update'}</Button>
                    <Button onClick={handleClose} color='error'>Close</Button>

                </DialogActions>
            </form>
        </Dialog>
    );
}

AssessmentCreateModal.propTypes = {
    action: PropTypes.oneOf(['CREATE', 'UPDATE']).isRequired,
    assessmentDetails: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onFormSubmissionSuccess: PropTypes.func.isRequired,
};

AssessmentCreateModal.defaultProps = {
    action: 'CREATE'
};