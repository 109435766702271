import { graphQLConnectionHandler } from '@core/graphql/graphQLConnectionHandler';
import { FETCH_TRAINING_CATALOGUE_AVAILABILITY, FETCH_TRAINING_CATALOGUE_DOWNLOAD_LINK, UPLOAD_TRAINING_CATALOGUE_REFERENCE_MATERIAL } from '@core/graphql/queries/referenceMaterials';
import { CloudDownloadOutlined, NoteAdd } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormHelperText, IconButton, Tooltip, Typography } from "@mui/material";
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import FileUpload from 'react-material-file-upload';
import { useDispatch } from 'react-redux';
import { convertToBase64, downloadDataFile, showPromiseNotification } from 'utils';
import * as Yup from 'yup';

const ValidationSchema = Yup.object().shape({
  attachment: Yup.string().required('Attachment is required')
});

const TrainingCatalogueReferenceMaterialModal = (props) => {
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const [pendingRequest, setPendingRequest] = useState(false);
  const [showDownloadLink, setDownloadLinkVisibility] = useState(false);
  const [pendDownload, setPendingDownload] = useState(false)
  const [initFormData, setInitFormData] = useState({
    attachment: ''
  });

  useEffect(() => {
    checkLearningCatalogueDetailsAvaliability();
  }, []);

  const getUploadFile = async () => {
    if (files.length > 0) {
      return await convertToBase64(files[0]);
    }
    return null;
  }

  const checkLearningCatalogueDetailsAvaliability = () => {
    graphQLConnectionHandler(dispatch, FETCH_TRAINING_CATALOGUE_AVAILABILITY(), (error, response) => {
      if (!error && response.checkTrainingCatalogueAvailability?.catalogueAvailable) {
        setDownloadLinkVisibility(true);
      }
    });
  }

  const downloadTrainingCatalogue = () => {
    setPendingDownload(true);
    graphQLConnectionHandler(dispatch, FETCH_TRAINING_CATALOGUE_DOWNLOAD_LINK(), (error, response) => {
      setPendingDownload(false);
      if (!error && response.courseCatalogueDownloadLink?.status === 200) {
        downloadDataFile(response.courseCatalogueDownloadLink?.url)
      }
    });
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initFormData,
    validationSchema: ValidationSchema,
    onSubmit: async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
      try {
        setPendingRequest(true);
        let attachment = await getUploadFile();
        let gqlVaribles = {
          variables: {
            "catalogueReferenceMaterial": {
              attachment: attachment
            },
          }
        }

        let referenceMaterialUploadRequest = new Promise((resolve, reject) => {
          graphQLConnectionHandler(dispatch, UPLOAD_TRAINING_CATALOGUE_REFERENCE_MATERIAL(gqlVaribles), (error, response) => {
            setPendingRequest(false);
            if (error != null) {
              reject('Training Catalogue upload failed');
            } else {
              if (response.uploadAcademyTrainingCatalogue.id == 200)
                resolve('Training Catalogue successfully uploaded');
              else {
                reject("Training Catalogue upload failed due to " + response.catalogueReferenceMaterial.response);
              }
            }
          });
        });
        showPromiseNotification(referenceMaterialUploadRequest, 'Uploading Training Catalogue ...', 'Internal error occurred while uploading traning catalogue. Please try again later.');
      } catch (err) {
        setStatus({ success: false });
        setErrors({ submit: err.message });
        setSubmitting(false);
      }
    },
  });

  return (
    <Dialog
      fullWidth={true}
      open={props.showPopup}
      onClose={() => {
        if (props.backdropCloseEnable) {
          props.onClose();
        }
      }}
      maxWidth="sm"
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        <div>
          <Typography variant="subtitle1">
            <b>Manage Emapta Academy Training Catalogue</b>
          </Typography>
        </div>
        <IconButton
          aria-label="close"
          onClick={() => {
            props.onClose();
          }}
          disabled={pendingRequest}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <Divider></Divider>
      <DialogContent>
        <form noValidate onSubmit={formik.handleSubmit} >
          <FileUpload value={files} accept={[".pdf"]} title="Drag 'n' drop pdf file here, or click to select files" onChange={(files) => {
            setFiles(files);
            formik.setFieldValue('attachment', files.length > 0 ? files[0].path : "");
          }} />
          {formik.touched.attachment && formik.errors.attachment && (
            <FormHelperText error id="standard-weight-helper-text-course-material-attachment">
              {formik.errors.attachment}
            </FormHelperText>
          )}

          {formik.errors.submit && (
            <Box sx={{ mt: 1 }} >
              <FormHelperText error style={{ textAlign: "center" }}>{formik.errors.submit}</FormHelperText>
            </Box>
          )}
          <DialogActions>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Box>
                <LoadingButton
                  loading={pendingRequest}
                  loadingPosition="start"
                  disableElevation
                  disabled={pendingRequest}
                  fullWidth
                  startIcon={<NoteAdd />}
                  size="medium"
                  type="submit"
                  variant="contained"
                >
                  Submit
                </LoadingButton>
              </Box>
              {showDownloadLink &&
                <Tooltip title="Download hosted training catalogue" placement="top">
                  <IconButton sx={{ mt: 1, ml: 2 }} color="primary" aria-label="download reference material" disabled={pendDownload} component="span" onClick={() => {
                    downloadTrainingCatalogue();
                  }}>
                    <CloudDownloadOutlined sx={{ fontSize: 25 }} />
                  </IconButton>
                </Tooltip>
              }
            </div>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}

TrainingCatalogueReferenceMaterialModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  backdropCloseEnable: PropTypes.bool,
  showPopup: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

TrainingCatalogueReferenceMaterialModal.defaultProps = {
  backdropCloseEnable: false,
};
export default TrainingCatalogueReferenceMaterialModal;
