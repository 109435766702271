import EmaptaIMS from "../utils/emaptaIAM";

const _iam = new EmaptaIMS('/emapta-iam.json');

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initializeIAM = (onAuthenticatedCallback) => {
    _iam.init({
        onLoad: 'login-required',
        silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
        pkceMethod: 'S256',
        responseMode: 'query',
        silentCheckSsoFallback: false,
        enableLogging: false
    })
        .then((authenticated) => {
           onAuthenticatedCallback();
        })
        .catch(console.error);
};

const doLogin = _iam.login;

const doLogout = _iam.logout;

const getToken = () => _iam.token;

const isLoggedIn = () => !!_iam.token

const updateToken = (successCallback) => _iam.updateToken(5).then(successCallback).catch(doLogin);

const getUsername = () => _iam.tokenParsed?.preferred_username;

const hasRole = (roles) => roles.some((role) => _iam.hasRealmRole(role));

const getGivenName = () => _iam.tokenParsed.given_name;

const getUserInfo = () => _iam.userInfo;
const clearToken = () => _iam.clearToken;
const loadUserProfile = async () => await _iam.loadUserInfo();

const IAMService = {
    initializeIAM,
    doLogin,
    doLogout,
    isLoggedIn,
    getToken,
    updateToken,
    getUsername,
    hasRole,
    getGivenName,
    getUserInfo,
    clearToken,
    loadUserProfile
};

export default IAMService;
