import { setSelectedCourse, updateUserSelectedCourse } from "@core/actions/course";
import { graphQLConnectionHandler } from "@core/graphql/graphQLConnectionHandler";
import { FETCH_COURSE } from "@core/graphql/queries/course";
import { Navigations } from "@core/helpers/Navigations";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router';

const { useState, useCallback, useMemo, useEffect } = require("react")


const useForceUpdate = () => {
    const [, setForceRender] = useState(0);
    return useCallback(() => {
        setForceRender(val => val + 1);
    }, []);
}


export const useIsAdmin = () => {
    const authenticationState = useSelector((state) => state.auth);
    return useMemo(() => {
        return authenticationState.user?.role === 'ADMIN';
    }, [authenticationState]);
}

export const useIsValidAdminSession = () => {
    const authenticationState = useSelector((state) => state.auth);
    const navigate = useNavigate();
    useEffect(() => {
        if (authenticationState.originalRole !== 'ADMIN') {
            navigate(Navigations.LOGIN);
        }
    }, [authenticationState]);
}

export const useSelectedCourse = () => {
    const dispatch = useDispatch();
    const selectedCourse = useSelector((state) => state.selectedCourse);
    const [currentCourseUUID, setCurrentCourseUUID] = useState('');
    return useMemo(() => {
        if (selectedCourse.course_uuid != "" && selectedCourse.course_uuid != currentCourseUUID) {
            setCurrentCourseUUID(selectedCourse.course_uuid);
            graphQLConnectionHandler(dispatch, FETCH_COURSE(selectedCourse.course_uuid), (err, res) => {
                if (!err) {
                    dispatch(setSelectedCourse(res.course));
                }
            });
        }
        return selectedCourse;
    }, [selectedCourse]);
}

export const useSelectedCourseChanged = () => {
    const dispatch = useDispatch();
    const selectedCourse = useSelector((state) => state.selectedCourse);
    return useCallback((course_uuid) => {
        if (selectedCourse.course_uuid != course_uuid) {
            dispatch(updateUserSelectedCourse(course_uuid));
        }
    }, [])
}

export default useForceUpdate;