import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, TableCell, TableRow } from "@mui/material";
import PropTypes from 'prop-types';
import { SortableHandle } from "react-sortable-hoc";
import tableStyle from './dragableDatatable.module.css';
import { useState } from 'react';
import TableRowSwitch from './tableRowSwitch';

const RowHandler = SortableHandle(() => <div className={tableStyle.drag}><MoreVertIcon /></div>);

const DraggableTableRow = ({ id, row, rowDetails, tableHeaders, rowClassName, rowStyle, cellClassName, cellStyle, enableRowActions, rowActions }) => {

  const getCellDetails = (index, column) => {
    if (index == 0) {
      return (
        <div className={tableStyle.dragableCell}>
          <RowHandler />
          {rowDetails[column]}
        </div>
      );
    }
    else
      return rowDetails[column];
  }

  return (
    <TableRow className={rowClassName} style={{ ...rowStyle }}>
      {Object.keys(tableHeaders).map((column, index) => {

        if (tableHeaders[column]["isData"]) {
          return (
            <TableCell key={id + '-' + index} className={cellClassName} style={{ ...cellStyle }}>
              {getCellDetails(index, column)}
            </TableCell>
          );
        }
      })
      }
      {enableRowActions &&

        rowActions.map((eventData, index) => {
          rowDetails["parentRow"] = row;
          if (eventData.type === 'BUTTON') {
            return (
              <TableCell key={eventData.id + '-' + index}>
                <div className={tableStyle.dragableCell + " " + tableStyle.mr10}>
                  <IconButton  color="primary" aria-label="" onClick={() => { eventData.onTrigger(eventData.action ?? "NAVIGATE", rowDetails) }}>
                    {eventData.icon}
                  </IconButton>
                </div>
              </TableCell>
            )
          } else if (eventData.type === 'SWITCH') {

            return (
              <TableCell key={eventData.id + '-' + index}>
                <div className={tableStyle.dragableCell + " " + tableStyle.mr10}>
                  <TableRowSwitch eventData={eventData} rowDetails={rowDetails}
                    onTrigger={(action, rowDetails) => {
                      eventData.onTrigger(eventData.action ?? "TOGGLE", rowDetails)
                    }} />
                </div>
              </TableCell>
            )

          }
        })
      }
    </TableRow>
  );
};

DraggableTableRow.propTypes = {
  id: PropTypes.string.isRequired,
  row: PropTypes.object.isRequired,
  rowDetails: PropTypes.object.isRequired,
  tableHeaders: PropTypes.object.isRequired,
  rowClassName: PropTypes.string,
  rowStyle: PropTypes.object,
  cellClassName: PropTypes.string,
  cellStyle: PropTypes.object,
  enableRowActions: PropTypes.bool,
  rowActions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    action: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.any,
    onTrigger: PropTypes.func.isRequired,
    type: PropTypes.oneOf(['BUTTON', 'SWITCH']).isRequired,
    icon: PropTypes.any,
  }))
};

DraggableTableRow.defaultProps = {
  rowClassName: '',
  rowStyle: {},
  cellClassName: '',
  cellStyle: {},
};


export default DraggableTableRow;
