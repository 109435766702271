import { graphQLConnectionHandler } from '@core/graphql/graphQLConnectionHandler';
import { CREATE_COURSE } from '@core/graphql/queries/course';
import CloseIcon from '@mui/icons-material/Close';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { LoadingButton } from '@mui/lab';
import "./CourseCreate.modal.css";
import {
    Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider,
    FormControl, FormHelperText, IconButton, InputLabel, OutlinedInput, TextField, Typography
} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import {  useFormik } from "formik";
import useScriptRef from 'hooks/useScriptRef';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { showUserNotification } from 'utils';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { Navigations } from '@core/helpers/Navigations';
import SunEditor from 'suneditor-react';

const ValidationSchema = Yup.object().shape({
    courseName: Yup.string().max(255).required('Course Name is required'),
    shortDescription: Yup.string().max(100, "Short description must not be more than 100 characters").min(20, "Short description must be more than 20 characters").required('Short description is required')
});

const initFormData = {
    courseName: '',
    shortDescription: '',
    submit: null
};


const CourseCreateModal = (props) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const [pendingRequest, setPendingRequest] = useState(false);
    const [message, setMessage] = useState(null);
    const scriptedRef = useScriptRef();
    const navigate = useNavigate();

    const handleEditorChange = content => {

        var div = document.createElement("div");
        div.innerHTML = content;
        var text = div.textContent || div.innerText || "";
        div.remove();

        if (text.length > 0) {
            formik.setFieldValue("shortDescription", content);
            setTimeout(() => formik.setFieldTouched("shortDescription", true));
          } else {
            formik.setFieldValue('shortDescription', content);
          }

    };
    
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initFormData,
        validationSchema: ValidationSchema,
        onSubmit: (values, { setErrors, setStatus, setSubmitting, resetForm }) => {

            try {

                if (scriptedRef.current) {

                    setPendingRequest(true);
                    let gqlVaribles = {
                        variables: {
                            "course_name": values.courseName.replace(/(?:\r\n|\r|\n)/g, '').trim(),
                            "short_description": values.shortDescription.replace(/(?:\r\n|\r|\n)/g, '').trim(),
                        }
                    }
    
                    graphQLConnectionHandler(dispatch, CREATE_COURSE(gqlVaribles), (error, response) => {
                        setPendingRequest(false);
                        if (!error) {
                            showUserNotification(response.createCourse?.course_name + ' course has been successfully created', "SUCCESS");
                            props.onClose();
                            navigate(Navigations.COURSE + '/' + response.createCourse?.course_uuid);
                        } else {
                            setMessage(error.message);
                        }
                    });
    
                }
            } catch (err) {
                console.error(err);
                if (scriptedRef.current) {
                    setStatus({ success: false });
                    setErrors({ submit: err.message });
                    setSubmitting(false);
                }
            }
    
        },
    });

    
    return (
        <Dialog
            fullWidth={true}
            maxWidth='md'
            open={props.showPopup}
            onClose={() => {
                if (props.backdropCloseEnable) {
                    props.onClose();
                }
            }}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                <Typography variant="subtitle1" component="div">
                    Create New Course
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={() => {
                        props.onClose();
                    }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <Divider></Divider>
            <DialogContent>

                <form noValidate onSubmit={formik.handleSubmit} >
                    <FormControl
                        fullWidth
                        error={Boolean(formik.touched.courseName && formik.errors.courseName)}
                        sx={{ ...theme.typography.customInput }}
                    >
                        <InputLabel htmlFor="outlined-adornment-courseName-course">Course Name</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-courseName-course"
                            type="courseName"
                            value={formik.values.courseName}
                            name="courseName"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            label="Course Name"
                            inputProps={{}}
                        />
                        {formik.touched.courseName && formik.errors.courseName && (
                            <FormHelperText error id="standard-weight-helper-text-courseName-course">
                                {formik.errors.courseName}
                            </FormHelperText>
                        )}
                    </FormControl>

                    <FormControl
                        fullWidth
                        error={Boolean(formik.touched.shortDescription && formik.errors.shortDescription)}
                        sx={{ ...theme.typography.customInput }}
                    >
                        <InputLabel htmlFor="outlined-adornment-shortDescription-course">Description</InputLabel>
           
                        <OutlinedInput
                            id="outlined-adornment-shortDescription-course"
                            type="shortDescription"
                            value={formik.values.shortDescription}
                            name="shortDescription"
                            multiline
                            rows={4}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            label="Course Short Description"
                            inputProps={{}}
                        />

                        {formik.touched.shortDescription && formik.errors.shortDescription && (
                            <FormHelperText error id="standard-weight-helper-text-shortDescription-course">
                                {formik.errors.shortDescription}
                            </FormHelperText>
                        )}
                     
                    </FormControl>
                    {formik.errors.submit || message && (
                        <Box sx={{ mt: 3 }} >
                            <FormHelperText error style={{ textAlign: "center" }}>{formik.errors.submit ?? message}</FormHelperText>
                        </Box>
                    )}
                    <DialogActions>
                        <Box sx={{ mt: 2, }} >
                            <LoadingButton
                                loading={pendingRequest}
                                loadingPosition="start"
                                disableElevation
                                disabled={pendingRequest}
                                fullWidth
                                startIcon={<NoteAddIcon />}
                                size="large"
                                type="submit"
                                variant="contained"
                            >
                                Create
                            </LoadingButton>
                        </Box>
                    </DialogActions>
                </form>

                <Divider sx={{ mt: 3 }}></Divider>
            </DialogContent>

            <DialogActions>
            </DialogActions>
        </Dialog>
    );
}

CourseCreateModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    backdropCloseEnable: PropTypes.bool,
    showPopup: PropTypes.bool,
};

CourseCreateModal.defaultProps = {
    backdropCloseEnable: false
};


export default CourseCreateModal;