import { Navigations } from '@core/helpers/Navigations';
import { AdminPanelSettings, CreateNewFolder, School, ReceiptLong } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
    Avatar,
    Box, Button, ButtonBase, Dialog, DialogActions, DialogContent, DialogContentText
} from '@mui/material';
// material-ui
import { useTheme } from '@mui/material/styles';
// assets
import { IconHome, IconSettings } from '@tabler/icons';
import { useIsAdmin } from 'hooks/useForceUpdate';
import { CourseCreateModal } from 'pages/course/modal';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { changeRole, logout } from '../../../../@core/actions/auth';
import { graphQLConnectionHandler } from '@core/graphql/graphQLConnectionHandler';
import { FETCH_TRAINING_CATALOGUE_DOWNLOAD_LINK } from '@core/graphql/queries/referenceMaterials';



// ==============================|| Home ||============================== //
const HomeSection = (props) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isAdminUser = useIsAdmin();
    const [open, setOpen] = useState(false);
    const [hasPendingCatalogueRequest, setPendingCatalogueRequest] = useState(false);
    const authenticationState = useSelector((state) => state.auth);

    /**
    * anchorRef is used on different componets and specifying one type leads to other components throwing an error
    * */
    const anchorRef = useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const onModeChange = () => {
        dispatch(changeRole(isAdminUser ? 'LEANER' : authenticationState.originalRole));
    }

    const requestTrainingCatalogue = ()=>{
      setPendingCatalogueRequest(true)
      graphQLConnectionHandler(dispatch, FETCH_TRAINING_CATALOGUE_DOWNLOAD_LINK(), (error, response) => {
        setPendingCatalogueRequest(false);
        if (!error && response.courseCatalogueDownloadLink?.status === 200) {
          window.open(response.courseCatalogueDownloadLink?.url+'#toolbar=0', '_blank');
        }
      });
    }

    const [openModal, setModalOpen] = useState(false);

    return (
        <>
            <Box
                sx={{
                    ml: 2,
                    mr: 3,
                    [theme.breakpoints.down('md')]: {
                        mr: 2
                    }
                }}
            >
                {!isAdminUser &&
                    <LoadingButton
                        loading={hasPendingCatalogueRequest}
                        size="large"
                        color="primary"
                        loadingPosition="start"
                        startIcon={<ReceiptLong />}
                        sx={{ mr: 5 }}
                        variant="outlined"
                        onClick={() => {
                          requestTrainingCatalogue();
                        }}>View Training Catalogue</LoadingButton>
                }
                {isAdminUser &&
                    <>
                        <LoadingButton
                            loading={false}
                            size="large"
                            color="primary"
                            loadingPosition="start"
                            startIcon={<CreateNewFolder />}
                            sx={{ mr: 5 }}
                            variant="outlined"
                            onClick={() => {
                                setModalOpen(true);
                            }}>Create Course</LoadingButton>
                        <LoadingButton
                            loading={false}
                            size="large"
                            color="primary"
                            loadingPosition="start"
                            startIcon={<IconSettings />}
                            sx={{ mr: 5 }}
                            variant="outlined"
                            onClick={() => {
                                navigate(Navigations.ADMIN + Navigations.USERS);
                            }}>Configuration</LoadingButton>

                    </>

                }
                {authenticationState.originalRole === 'ADMIN' &&
                    <ButtonBase sx={{ borderRadius: '12px' }} component={Link} to="/">
                        <Avatar
                            variant="rounded"
                            sx={{
                                ...theme.typography.commonAvatar,
                                ...theme.typography.mediumAvatar,
                                transition: 'all .2s ease-in-out',
                                marginRight: 3,
                                background: theme.palette.navigate.light,
                                color: theme.palette.navigate.dark,
                                '&[aria-controls="menu-list-grow"],&:hover': {
                                    background: theme.palette.navigate.dark,
                                    color: theme.palette.navigate.light
                                }
                            }}
                            aria-haspopup="true"
                            onClick={onModeChange}
                            color="#E3F2FD"
                        >
                            {isAdminUser ? (
                                <AdminPanelSettings stroke={1.5} size="1.3rem" />
                            ) : (
                                <School stroke={1.5} size="1.3rem" />
                            )
                            }
                        </Avatar>
                    </ButtonBase>
                }

                <ButtonBase sx={{ borderRadius: '12px' }} component={Link} to="/">
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.navigate.light,
                            color: theme.palette.navigate.dark,
                            '&[aria-controls="menu-list-grow"],&:hover': {
                                background: theme.palette.navigate.dark,
                                color: theme.palette.navigate.light
                            }
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                        color="#00a152"
                    >
                        <IconHome stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase>
            </Box>
            {openModal &&
                <CourseCreateModal showPopup={openModal} onClose={() => setModalOpen(false)} />
            }
            {/* Start of Session Expired Popup */}
            <Dialog
                open={!authenticationState.isSessionValid}
                keepMounted
                onClose={() => { }}
                aria-describedby="alert-dialog-session-expired"
            >
                <Box sx={{ mt: 2 }}>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-session-expired">
                            Your session has expired or You have performed an Unauthorized action
                        </DialogContentText>
                    </DialogContent>
                </Box>
                <DialogActions sx={{ alignItems: 'center', justifyContent: 'center', mb: 2 }}>
                    <Button variant="contained" onClick={() => {
                        dispatch(logout());
                    }}>Okay</Button>
                </DialogActions>
            </Dialog>
            {/* End of Session Expired popup */}

        </>
    );
};

export default HomeSection;
