export const AdminUserSideMenuItems = {
    rootId: 'adminUser',
    registeredUsers: 'admin-registered-user',
    enrolledUsers: 'admin-enrollment-user',
    courseCategory: 'course-category',
    adminCourseEResources: 'admin-course-e-resources',
    adminCourseReports:'admin-course-reports',
}

export const AdminCoursesSideMenuItems = {
    rootId: 'courseContents',
    courseDetails: 'courseDetails',
    courseAssessment: 'courseAssessment',
    courseContent: 'courseContent',
    courseUserEnrollment: 'courseUserEnrollment',
    courseEResources: 'courseEResources',
    courseReport: 'courseReport',
    courseDashboard: 'courseDashboard',
}

export const UsersProfileSideMenuItems = {
    rootId: 'userProfile',
    userAccountSettings: 'user-accountSettings',
    userProfile: 'user-my-profile'
}
