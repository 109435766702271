export const FETCH_COURSE_ASSESSMENTS = (variables) => {
    let query = {
        query: `query($course_uuid: String!)
                    { courseAssignments(course_uuid: $course_uuid)  {
                        assignment_uuid,
                        title,
                        batch_number,
                        passing_score,
                        type,
                        status,
                        module_uuid,
                        course_uuid,
                        assessment_order,
                        number_display_questions,
                        quizzes{
                            quiz_uuid,
                            assignment_uuid,
                            status,
                            comment,
                            question,
                            quiz_order,
                        }
                    }
                    }`
    };

    let mergeQuery = Object.assign({}, query, variables);

    return JSON.stringify(mergeQuery);
}

export const LOAD_FILTERED_COURSE_ASSESSMENTS = (variables) => {
    let query = {
        query: `query($course_uuid: String!,$search_query: String!, $limit: Int!)
                    { courseAssignmentsSearch(course_uuid: $course_uuid,search_query:$search_query,limit:$limit)  {
                        assignment_uuid
                        title
                    }
                }`
    };

    let mergeQuery = Object.assign({}, query, variables);

    return JSON.stringify(mergeQuery);
}

export const CREATE_COURSE_ASSESSMENT = (variables) => {
    let query = {
        query: `mutation CreateCourseAssessment($title: String!, $course_uuid: String!,$batch_number:String!,$passing_score: Int!,$type:String!,$status:String!,$module_uuid:String, $number_display_questions: Int!) {
                         createCourseAssignment (
                            courseAssignmentInput: {
                                course_uuid: $course_uuid
                                title: $title,
                                batch_number: $batch_number,
                                status: $status,
                                type: $type,
                                passing_score:$passing_score,
                                module_uuid: $module_uuid,
                                number_display_questions: $number_display_questions
                            }) {
                                assignment_uuid,
                                title,
                                status,
                                passing_score,
                                batch_number,
                                createdAt,
                                updatedAt,
                                course_uuid,
                                module_uuid,
                                type,
                                number_display_questions
                        }}`
    };

    let mergeQuery = Object.assign({}, query, variables);

    return JSON.stringify(mergeQuery);
};

export const UPDATE_COURSE_ASSESSMENT = (variables) => {
    let query = {
        query: `mutation UpdateCourseAssignment($title: String!, $course_uuid: String!,$batch_number:String!,$passing_score: Int!,$type:String!,$status:String!,$module_uuid:String,$assignment_uuid:String!, $number_display_questions: Int!) {
                            updateCourseAssignment (
                            courseAssignmentInput: {
                                course_uuid: $course_uuid
                                title: $title,
                                batch_number: $batch_number,
                                status: $status,
                                type: $type,
                                passing_score:$passing_score,
                                module_uuid: $module_uuid,
                                number_display_questions: $number_display_questions
                            },assignment_uuid:$assignment_uuid) {
                                assignment_uuid,
                                title,
                                status,
                                batch_number,
                                passing_score,
                                createdAt,
                                updatedAt,
                                course_uuid,
                                module_uuid,
                                type,
                                number_display_questions
                        }}`
    };

    let mergeQuery = Object.assign({}, query, variables);

    return JSON.stringify(mergeQuery);
};


export const FETCH_ASSESSMENT = (variables) => {
    let query = {
        query: `query($assignment_uuid: String!)
                    { courseAssignment(assignment_uuid: $assignment_uuid)  {
                        assignment_uuid
                        title
                        quizzes {
                            question
                            quiz_uuid
                            quiz_answers {
                                answer_id
                                answer
                                quiz_uuid
                            }
                        }
                        user_assignments {
                            question_count
                            mark_percentage
                            isSuccess
                            attempts
                        }
                    }
                    }`
    };

    let mergeQuery = Object.assign({}, query, variables);

    return JSON.stringify(mergeQuery);
}

//////////// Quiz Answers ///////////////////
export const FETCH_ASSESSMENT_QUIZ_ANSWERS = (variables) => {
    let query = {
        query: `query($quiz_uuid: String!)
                    { assignmentQuizAnswers(quiz_uuid: $quiz_uuid)  {
                        answer_id
                        answer
                        isCorrect
                        quiz_uuid
                    }
                    }`
    };

    let mergeQuery = Object.assign({}, query, variables);

    return JSON.stringify(mergeQuery);
}

export const DELETE_ASSESSMENT_QUIZ_ANSWER = (variables) => {
    let query = {
        query: `mutation DeleteAssignmentQuizAnswer($answer_id: String!) {
                    deleteAssignmentQuizAnswer(answer_id:$answer_id) {
                      answer_id
                      isDeleted
                      responseCode
        }}`
    };
    let mergeQuery = Object.assign({}, query, variables);

    return JSON.stringify(mergeQuery);
}


export const SUBMIT_ASSESSMENT_QUIZ_ANSWERS = (variables) => {
    let query = {
        query: `mutation SubmitAssignmentQuizAnswers($dataSet:[UserAnswerInputData], $course_uuid:String!, $assignment_uuid:String!) {
            submitAssignmentQuizAnswers (userAnswerInput: $dataSet, course_uuid:$course_uuid, assignment_uuid:$assignment_uuid) {
                mark_percentage
                is_success
                certificate
                quiz_details {
                    correct_ids
                    note
                    quiz_uuid
                    answer_id
                    isCorrect
                }
        }}`
    };

    let mergeQuery = Object.assign({}, query, variables);

    return JSON.stringify(mergeQuery);
}

export const REARRANGE_COURSE_ASSESSMENTS = (variables) => {
    let query = {
        query: `mutation ReorderCourseAssignment($assessments:[AssignmentReorder]!) {
                     reorderCourseAssignment(assessments:$assessments) {
                      code
                      reason
        }}`
    };
    let mergeQuery = Object.assign({}, query, variables);

    return JSON.stringify(mergeQuery);
}

export const UPLOAD_COURSE_ASSESSMENT_CERTIFICATE = (variables) => {
    let query = {
        query: `mutation CourseAssessmentCertificateImport($file: Upload!,$course_uuid: String!,$certificate_description: String!,$action: String!) {
                         courseAssessmentCertificateImport (
                            file: $file,course_uuid:$course_uuid,certificate_description:$certificate_description,action:$action) {
                             id
                        }}`
    };

    let mergeQuery = Object.assign({}, query, variables);

    return JSON.stringify(mergeQuery);
};

export const FETCH_COURSE_ASSESSMENT_CERTIFICATE = (variables) => {

    let query = {
        query: `query($course_uuid: String!)
                    { courseCertificateTemplate(course_uuid:$course_uuid)  {
                        certificate_uuid
                        course_uuid
                        certificate_description
                        createdAt
                        updatedAt
                    }
                    }`
    };
    let mergeQuery = Object.assign({}, query, variables);

    return JSON.stringify(mergeQuery);
};


export const FETCH_USER_ASSESSMENT_CERTIFICATE = (variables) => {
    let query = {
        query: `query($course_uuid: String!, $assignment_uuid: String!)
                    { userAssessmentCertificate(assignment_uuid: $assignment_uuid, course_uuid: $course_uuid )  {
                        certificate,fileName
                    }
                    }`
    };

    let mergeQuery = Object.assign({}, query, variables);

    return JSON.stringify(mergeQuery);
}

export const FETCH_ADMIN_USER_ASSESSMENT_CERTIFICATES = (variables) => {
    let query = {
        query: `query($course_uuid: String!, $user_id: Int!)
                    { adminUserAssessmentCertificates(course_uuid: $course_uuid, user_id: $user_id )  {
                        certificate,fileName
                    }
                    }`
    };

    let mergeQuery = Object.assign({}, query, variables);

    return JSON.stringify(mergeQuery);
}

export const VALIDATE_ASSESSMENT_PUBLISHABILITY = (variables) => {
    let query = {
        query: `query($assignment_uuid: String!, $assignment_type:String!, $number_display_questions: Int! )
                    { assignmentPublishable(assignment_uuid: $assignment_uuid, assignmentValidationInput: {
                        assignment_type: $assignment_type
                        number_display_questions: $number_display_questions,
                    }) {
                        is_publishable
                        message
                    }}`
    };
    let mergeQuery = Object.assign({}, query, variables);
    return JSON.stringify(mergeQuery);
};
