import { Add } from '@mui/icons-material';
import { Button, Grid, TablePagination } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import { arrayMove } from "utils";
import DataTableExpandable from './dataTableExpandable';
import tableStyle from './dragableDatatable.module.css';

const DraggableTableContainer = SortableContainer(({ children }) => {
    return <TableBody>{children}</TableBody>;
});

export default function DataTableCollapsible(props) {

    const { rows } = props;
    const { headers } = props;
    const { options } = props
    const [page, setPage] = useState(0);
    const [items, setItems] = useState(rows);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
        setItems(oldItems => {
            let data = arrayMove(oldItems, oldIndex, newIndex);
            props.onDataRearrange(data, page, rowsPerPage);
            return data;
        });
    }, []);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        props.onPaginateTableContent(0, parseInt(event.target.value, 10))
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.onPaginateTableContent(newPage, rowsPerPage)
    };


    useEffect(() => {
        setItems(props.rows)
    }, [props.rows])


    return (
        <>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={props.totalRecordsCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <TableContainer component={Paper}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {props.parentOption.map((option) => {
                            return (
                                <Button key={option.id} onClick={() =>
                                    option.onTrigger(props.row)
                                } startIcon={option.icon ?? <Add />}>{option.label}</Button>
                            )
                        })}

                    </Grid>

                </Grid>
                <Table aria-label="collapsible table">

                    <TableHead>
                        <TableRow>
                            {
                                Object.values(headers["parent"]).map(value => {
                                        return <TableCell key={value.name}>{value.name}</TableCell>
                               
                                })
                            }
                        </TableRow>
                    </TableHead>

                    <DraggableTableContainer
                        onSortEnd={onSortEnd}
                        axis="y"
                        lockAxis="y"
                        lockToContainerEdges={true}
                        lockOffset={["30%", "50%"]}
                        helperClass={tableStyle.helperContainerClass}
                        useDragHandle={true}
                    >
                        {items.map((row, index) => (
                            <DataTableExpandable
                                {...props}
                                key={index} row={row} options={options} rowIndex={index} headers={headers}
                                onReArrangeChild={(changingData, element) => {
                                    props.onRearrangeChildData(changingData, element);
                                }}
                            />
                        ))}


                    </DraggableTableContainer>
                </Table>
            </TableContainer>
        </>
    );
}


DataTableCollapsible.propTypes = {
    parentOption: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        action: PropTypes.string,
        label: PropTypes.string.isRequired,
        value: PropTypes.any,
        onTrigger: PropTypes.func.isRequired,
        type: PropTypes.oneOf(['BUTTON', 'SWITCH']),
        icon: PropTypes.any,
    })),
    onRearrangeChildData: PropTypes.func.isRequired,
    onPaginateTableContent: PropTypes.func.isRequired,
    totalRecordsCount: PropTypes.number.isRequired,
};

DataTableCollapsible.defaultProps = {
    onRearrangeChildData: (data, element) => { },
    onPaginateTableContent: (pageNo, rowsCount) => {  }
};