import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PropTypes from 'prop-types';
import quizAnswerCSS from './QuizAnswer.module.css';

const iconStyle = { fill: '#9E9E9E' };

const QuizAnswer = (props) => {
    
    const getAnswerStyle = () => {
        return props.items.isCorrect || props.markAnswer ? quizAnswerCSS.markedAnswer : '';
    }
    return (
        <div className={quizAnswerCSS.quetionContainer}>
            <div className={`${quizAnswerCSS.leftSubContainer} ${getAnswerStyle()} `} onClick={() => { props.onDataChange('MARK_ANSWER', props.items.answer_id) }}>  {props.items.isCorrect || props.markAnswer ? <CheckIcon style={iconStyle} /> : <MoreHorizIcon style={iconStyle} />}</div>
            <div className={ props.enableRemoveIcon ? quizAnswerCSS.contentContainer: quizAnswerCSS.contentContainerPlain}><div>{props.items.answer}</div></div>
            { props.enableRemoveIcon && <div className={quizAnswerCSS.rightSubContainer} onClick={() => { props.onDataChange('REMOVE_ANSWER', props.items.answer_id) }}><ClearIcon style={iconStyle} /></div>}
        </div>
        
    )
}

QuizAnswer.propTypes = {
    enableRemoveIcon:PropTypes.bool.isRequired,
    onDataChange: PropTypes.func.isRequired,
    items: PropTypes.object.isRequired,
};

QuizAnswer.defaultProps = {
    onDataChange: () => { },
    items: {},
    enableRemoveIcon:true
};


export default QuizAnswer