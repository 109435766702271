import { Download } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { LocalizationProvider, MobileDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import useScriptRef from 'hooks/useScriptRef';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { CSVLink } from 'react-csv';
import FileUpload from 'react-material-file-upload';

const templateHeaders = [
    ["Username"],
];

const UserCourseEnrollmentModal = (props) => {
    const [files, setFiles] = useState([]);
    const [courseDueDate, setCourseDueDate] = useState(new Date());
    const templateFileDownloadLink = useScriptRef();
    return (
        <Dialog
            fullWidth={true}
            open={props.showPopup}
            onClose={() => {
                if (props.backdropCloseEnable) {
                    props.onClose();
                }
            }}
            maxWidth="sm"
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                <Typography variant="subtitle1" component="div">
                    Bulk User Course Enrollment
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={() => {
                        props.onClose();
                    }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <Divider></Divider>
            <DialogContent>
                <FileUpload value={files} accept={".csv"} title="Drag 'n' drop csv file here, or click to select files" onChange={(files) => {
                    setFiles(files);
                }} />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker fullWidth
                        label="Enrollment Due Date"
                        value={courseDueDate}
                        minDate={new Date()}
                        onChange={(date) => {
                            setCourseDueDate(date);
                        }}
                        renderInput={(params) => <TextField {...params} sx={{ width: '50%', mt: 3 }} />}
                    />
                </LocalizationProvider>

                <CSVLink data={templateHeaders}
                    filename='user-course-enrollment.csv'
                    className='hidden'
                    ref={templateFileDownloadLink}
                />
                <Divider sx={{ mt: 3 }}></Divider>
            </DialogContent>

            <DialogActions>
                <Button onClick={() => props.onSubmit(files[0], courseDueDate)} disabled={files.length == 0 || (courseDueDate == null)}>SUBMIT</Button>
                <Tooltip title="Download Template" placement="top">
                    <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => {
                        templateFileDownloadLink.current.link.click();
                    }}>
                        <Download />
                    </IconButton>
                </Tooltip>
            </DialogActions>
        </Dialog>
    );
}

UserCourseEnrollmentModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    backdropCloseEnable: PropTypes.bool,
    showPopup: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
};

UserCourseEnrollmentModal.defaultProps = {
    backdropCloseEnable: false
};


export default UserCourseEnrollmentModal;