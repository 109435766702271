import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogTitle, Divider, IconButton, Typography } from "@mui/material";
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { CheckOutlined, ClearOutlined } from '@mui/icons-material';
import { FETCH_USER_ASSIGNMENT_ANSWER_SUMMARY_DETAILS } from '@core/graphql/queries/quiz';
import { graphQLConnectionHandler } from '@core/graphql/graphQLConnectionHandler';
import { useDispatch } from 'react-redux';
import { showUserNotification } from 'utils';

const QuizAnswersSummaryDetailsModal = (props) => {
    const dispatch = useDispatch();
    const [dataRows, setDataRows] = useState([]);

    useEffect(() => {
        loadQuizAnswerSummaryDetails();
    }, []);

    const loadQuizAnswerSummaryDetails = () => {
        let gqlVaribles = {
            variables: {
                course_uuid: props.course_uuid,
                assignment_uuid: props.question.assignment_uuid,
                quiz_uuid: props.question.quiz_uuid
            }
        };
        let mergedRequest = FETCH_USER_ASSIGNMENT_ANSWER_SUMMARY_DETAILS(gqlVaribles);
        graphQLConnectionHandler(dispatch, mergedRequest, (error, res) => {
            if (error || res.userQuizAnswerStatisticalSummary == null) {
                showUserNotification("Fail to users quiz answers summary details", "ERROR");
            } else {
                setDataRows(res.userQuizAnswerStatisticalSummary);
            }
        });
    }

    return (
        <Dialog
            fullWidth={true}
            open={props.showPopup}
            maxWidth="md"
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                <Typography variant="subtitle1" component="p" sx={{ mr: 5 }}>
                    Quiz Answer Summary : <b>{`${props.question.question}`}</b>
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={() => {
                        props.onClose();
                    }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Divider></Divider>
            <DialogContent>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Answer</TableCell>
                                <TableCell align="right">No of Submits</TableCell>
                                <TableCell align="right">Submission Rate(%)</TableCell>
                                <TableCell align="right">Valid Answer</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataRows.map((row) => (
                                <TableRow
                                    key={row.answer_uuid}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.answer}
                                    </TableCell>
                                    <TableCell align="right">{row.submitCount}</TableCell>
                                    <TableCell align="right">{row.submitRate}</TableCell>
                                    <TableCell align="right">{row.isCorrect ? <CheckOutlined style={{ color: '#4caf50' }} /> : <ClearOutlined style={{ color: '#ef5350' }} />}</TableCell>
                                </TableRow>
                            ))}
                            {dataRows.length == 0 &&
                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row" colSpan={4} sx={{ textAlign: 'center' }}>
                                        Sorry, no  records found
                                    </TableCell>
                                </TableRow>

                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
        </Dialog>
    );
}

QuizAnswersSummaryDetailsModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    backdropCloseEnable: PropTypes.bool,
    showPopup: PropTypes.bool,
    question: PropTypes.object.isRequired,
    course_uuid: PropTypes.string.isRequired,
};

QuizAnswersSummaryDetailsModal.defaultProps = {
    backdropCloseEnable: false
};
export default QuizAnswersSummaryDetailsModal;