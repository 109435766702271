import { gridSpacing } from '@core/actions/constant';
import { setCurrentPage } from '@core/actions/customization';
import { graphQLConnectionHandler } from '@core/graphql/graphQLConnectionHandler';
import { LOAD_FILTERED_COURSE_ASSESSMENTS } from '@core/graphql/queries/assessment';
import { FETCH_COURSES_STATISTICS } from '@core/graphql/queries/course';
import { FETCH_USER_ASSIGNMENT_ANSWER_SUMMARY_PAGINATE } from '@core/graphql/queries/quiz';
import { ReadMore } from '@mui/icons-material';
// material-ui
import {
    Autocomplete,
    Box,
    Button,
    Grid, IconButton, TextField, Tooltip, Typography
} from '@mui/material';
import { useSelectedCourseChanged } from 'hooks/useForceUpdate';
import { useMenuItemChanged } from 'hooks/utilityHooks';
import MUIDataTable from 'mui-datatables';
import { useEffect, useState } from 'react';
import GaugeChart from 'react-gauge-chart';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { sideMenuRoutingFactory, useSideMenuRoute } from 'routes/sidemenu';
import MainCard from 'ui-component/cards/MainCard';
// project imports
import { AdminCoursesSideMenuItems, getFormattedDayFromEpoch, showUserNotification } from 'utils';
import { COURSE } from '../../@core/helpers/pageRoutings';
import QuizAnswersSummaryDetailsModal from './modal/QuizAnswersSummaryDetailsModal';

const quizTableColumns = [
    {
        name: "question",
        label: "Question",
        options: {
            filter: false,
            sort: true,
        }
    },
    {
        name: "status",
        label: "Status",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "createdAt",
        label: "Created Date",
        options: {
            filter: false,
            sort: true,
            customBodyRender: (val) => {
                return val == undefined ? "" : getFormattedDayFromEpoch(val);
            }
        }
    },
    {
        name: "updatedAt",
        label: "Updated Date",
        options: {
            filter: false,
            sort: true,
            customBodyRender: (val) => {
                return val == undefined ? "" : getFormattedDayFromEpoch(val);
            }
        }
    }
];

const CourseHome = () => {
    const onPageLoad = useMenuItemChanged();
    const { course_uuid } = useParams();
    const course = useSelector((state) => state.selectedCourse);
    const [courseStats, setCourseStats] = useState({});
    const onSelectedCourseChanged = useSelectedCourseChanged();
    const [assessments, setAssessments] = useState([]);
    const [defaultAssessment, setDefaultAssessment] = useState("");
    const [selectedAssessment, setSelectedAssessment] = useState(null);
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [assessmentFilteredDataCount, setAssessmentFilteredDataCount] = useState(0);
    const [assessmentFilteredData, setAssessmentFilteredData] = useState([]);
    const [tableFilterData, setTableFilterData] = useState(null);
    const [showQuizAnswerSummaryModal, setQuizAnswerSummaryVisibility] = useState(false);
    const [visibleDatatable, setDatatableVisibility] = useState(false);
    const [columnSortData, setColumnSortData] = useState(['question', 'asc']);

    const dispatch = useDispatch();
    useSideMenuRoute(sideMenuRoutingFactory.courseRoute(course_uuid));

    const fetchCourseStats = () => {
        let gqlVaribles = {
            variables: {
                course_uuid: course_uuid,
            }
        };
        graphQLConnectionHandler(dispatch, FETCH_COURSES_STATISTICS(gqlVaribles), (err, res) => {
            if (!err) {
                setCourseStats(res?.courseStats);
            }
        });

    }

    useEffect(() => {
        reloadStatTable();
    }, [selectedAssessment]);

    const loadCourseAssessments = (initialLoad = false, query = '', limit = 10) => {
        graphQLConnectionHandler(dispatch, LOAD_FILTERED_COURSE_ASSESSMENTS({ variables: { course_uuid: course_uuid, search_query: query, limit: limit } }), (err, res) => {
            if (res?.courseAssignmentsSearch == null || err) {
                showUserNotification('Course Assessments are not loaded', 'WARN');
            } else {
                const assessmentList = res.courseAssignmentsSearch.map(value => {
                    return {
                        assignment_uuid: value.assignment_uuid,
                        label: value.title
                    }
                })
                setAssessments(assessmentList);
                if (initialLoad && assessmentList.length > 0) {
                    let assessment = assessmentList[0];
                    setSelectedAssessment(assessment.assignment_uuid ?? undefined,);
                    setDefaultAssessment(assessment.label ?? '');
                }
            }
        });
    };

    const fetchQuizStats = (searchQuery = '', pageNo = 0, rowLimit = 10, filter = []) => {
        if (selectedAssessment == undefined)
            return;
        let gqlVaribles = {
            variables: {
                searchQuery: searchQuery,
                page: pageNo,
                limit: rowLimit,
                filter: filter,
                course_uuid: course_uuid,
                assignment_uuid: selectedAssessment,
                sortData: columnSortData,
            }
        };
        let mergedRequest = FETCH_USER_ASSIGNMENT_ANSWER_SUMMARY_PAGINATE(gqlVaribles);
        graphQLConnectionHandler(dispatch, mergedRequest, (err, res) => {
            if (!err) {
                setAssessmentFilteredDataCount(res.assignmentQuizFilter.count);
                setAssessmentFilteredData(res.assignmentQuizFilter.rows);
            }
        });
    }

    useEffect(() => {
        dispatch(setCurrentPage(COURSE));
        onPageLoad(AdminCoursesSideMenuItems.courseDashboard);
        onSelectedCourseChanged(course_uuid);
        loadCourseAssessments(true);
        let interval = setTimeout(() => {
            setDatatableVisibility(true);
        }, 200);
        return () => {
            if (!interval) {
                clearTimeout(interval);
            }
        }
    }, []);

    useEffect(() => {
        fetchCourseStats();
        let timer = setInterval(() => {
            fetchCourseStats();
        }, 45000);
        return () => clearInterval(timer);
    }, []);

    const getGaugePercentage = (upperValue = 0, flowValue) => {
        try {
            if (upperValue == undefined || flowValue == undefined)
                return 0;
            else if (upperValue == '' || flowValue == '')
                return 0;
            else if (flowValue == 0)
                return 0;
            else {
                let value = parseFloat(upperValue / flowValue).toFixed(2);
                return value;
            }
        } catch (err) {
            return 0;
        }
    }

    const getFilterData = (colums, filterData) => {
        let filters = [];
        for (var i = 0; i < filterData.length; i++) {
            if (filterData[i].length == 1) {
                let data = {
                    id: colums[i].name,
                    value: filterData[i][0]
                };
                filters.push(data);
            }

        }
        return filters;
    }

    const onTableChange = (action, tableState) => {
        let { rowsPerPage, page, searchText, filterList, columns } = tableState;
        let filter = getFilterData(columns, filterList);
        setTableFilterData(tableState);
        fetchQuizStats(searchText, page, rowsPerPage, filter);
    }

    const options = {
        filterType: 'dropdown',
        onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
            setSelectedQuestion(assessmentFilteredData[rowsSelected[0]]);
        },
        filter: true,
        download: false,
        print: false,
        serverSide: true,
        selectableRows: 'single',
        count: assessmentFilteredDataCount,
        onTableChange: (action, tableState) => {
            switch (action) {
                case 'changePage':
                case 'sort':
                case 'filterChange':
                case 'search':
                case 'changeRowsPerPage':
                    onTableChange(action, tableState);
                    break;
                default:
                    console.log('action not handled. ', action);
            }

        },
        customToolbarSelect: () => {
            return (
                <Tooltip title="View Assessment Question Details">
                    <IconButton sx={{ mr: 2 }} onClick={() => setQuizAnswerSummaryVisibility(true)}>
                        <ReadMore />
                    </IconButton>
                </Tooltip>
            );
        },
        onColumnSortChange: (columnName, direction) => {
            setColumnSortData([columnName, direction]);
        }
    };

    const reloadStatTable = () => {
        if (tableFilterData) {
            onTableChange('reload', tableFilterData);
        } else {
            fetchQuizStats();
        }
    }

    useEffect(() => {
        reloadStatTable();
    }, [columnSortData]);

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12} sx={{ mb: 0 }}>
                <Grid item xs={12} m={1}>
                    <Typography variant="h2">{course?.course_name}</Typography>
                </Grid>
                <Grid container justifyContent="center" spacing={8}>
                    <Grid item lg={3} md={3} sm={3} xs={4}>
                        <MainCard sx={{ backgroundColor: '#1C3282' }}
                            darkTitle={true} showDivider={false} headerStyle={{ color: '#fff !important;', textAlign: 'center' }}
                            border={true} content={false} title="Enrolled Users" headerTitleStyle={{ color: '#FFF' }}>
                            <GaugeChart id="course-enrollment-summary"
                                style={{ width: '100%', height: '100px' }}
                                nrOfLevels={25}
                                animate={false}
                                needleColor="#A1D2FA"
                                needleBaseColor="#A1D2FA"
                                colors={['#EA4228', '#F5CD19', '#5BE12C',]}
                                percent={getGaugePercentage(courseStats.total_enrollment, courseStats.total_users) ?? 0}
                                arcPadding={0.02}
                                textColor="#fff"
                            />
                            <Box sx={{ p: 2, color: '#fff' }}>
                                <Typography>{`Total Enrollments   : ${courseStats.total_enrollment}`}</Typography>
                                <Typography>{`Total Registered Users : ${courseStats.total_users}`}</Typography>
                            </Box>
                        </MainCard>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={4}>
                        <MainCard sx={{ backgroundColor: '#1C3282' }} headerTitleStyle={{ color: '#FFF' }}
                            darkTitle={true} showDivider={false} headerStyle={{ color: '#fff !important;', textAlign: 'center' }}
                            border={true} content={false} title="Attempted Users">
                            <GaugeChart id="course-attempt-summary"
                                nrOfLevels={25}
                                style={{ width: '100%', height: '100px' }}
                                animate={false}
                                needleColor="#A1D2FA"
                                needleBaseColor="#A1D2FA"
                                colors={['#EA4228', '#F5CD19', '#5BE12C',]}
                                percent={getGaugePercentage(courseStats.total_attempt, courseStats.total_enrollment) ?? 0}
                                arcPadding={0.02}
                                textColor="#fff"
                            />
                            <Box sx={{ p: 2, color: '#fff' }}>
                                <Typography>{`Total Attempts   : ${courseStats.total_attempt}`}</Typography>
                                <Typography>{`Total Enrollments : ${courseStats.total_enrollment}`}</Typography>
                            </Box>
                        </MainCard>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={4}>
                        <MainCard sx={{ backgroundColor: '#1C3282' }} headerTitleStyle={{ color: '#FFF' }}
                            darkTitle={true} showDivider={false} headerStyle={{ color: '#fff !important;', textAlign: 'center' }}
                            border={true} content={false} title="Completed Users">
                            <GaugeChart id="course-complete-summary"
                                nrOfLevels={25}
                                style={{ width: '100%', height: '100px' }}
                                animate={false}
                                needleColor="#A1D2FA"
                                needleBaseColor="#A1D2FA"
                                colors={['#EA4228', '#F5CD19', '#5BE12C',]}
                                percent={getGaugePercentage(courseStats.total_complete, courseStats.total_enrollment) ?? 0}
                                arcPadding={0.02}
                                textColor="#fff"
                            />
                            <Box sx={{ p: 2, color: '#fff' }}>
                                <Typography>{`Total Completed   : ${courseStats.total_complete}`}</Typography>
                                <Typography>{`Total Enrollments : ${courseStats.total_enrollment}`}</Typography>
                            </Box>
                        </MainCard>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} sx={{ mt: 0 }}>
                <Grid container spacing={2} direction="row" sx={{ mb: 2 }}
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={8} sx={{ mt: 0 }}>
                        <Autocomplete
                            disablePortal
                            id="user-course-assessment-component"
                            multiple={false}
                            defaultValue={defaultAssessment}
                            value={defaultAssessment || ''}
                            onChange={(event, value, reason) => {
                                setSelectedAssessment(value?.assignment_uuid ?? undefined);
                                setDefaultAssessment(value?.label ?? '');
                                fetchQuizStats()
                            }}
                            onInputChange={(event, value, reason) => {
                                loadCourseAssessments(false, value, 10);
                            }}
                            sx={{ mt: 0, float: 'left' }}
                            options={assessments}
                            fullWidth
                            renderInput={(params) => <TextField  {...params} label="Assessments .." />}
                        />
                    </Grid>
                    <Grid item xs={4} sx={{ justifyContent: 'flex-start', display: 'flex' }}>
                        <Tooltip title={"Refresh Table"}>
                            <Button sx={{ mr: 1 }} variant="outlined" onClick={() => reloadStatTable()}>Reload</Button>
                        </Tooltip>
                    </Grid>
                </Grid>
                {visibleDatatable &&
                    <MUIDataTable
                        data={assessmentFilteredData}
                        columns={quizTableColumns}
                        options={options}
                    />
                }
            </Grid>
            {showQuizAnswerSummaryModal &&
                <QuizAnswersSummaryDetailsModal
                    onClose={() => setQuizAnswerSummaryVisibility(false)}
                    backdropCloseEnable={false}
                    course_uuid={course_uuid}
                    question={selectedQuestion}
                    showPopup={showQuizAnswerSummaryModal}
                />
            }
        </Grid>
    );
};

export default CourseHome;
