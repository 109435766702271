import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import config from 'config';
import { useSelector } from 'react-redux';


// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {

    const { isLoggedIn } = useSelector((state) => state.auth);
    const mainRoutes = MainRoutes(isLoggedIn, "ADMIN");
    

    // const routing = useRoutes([mainRoutes, AuthenticationRoutes], config.basename);


    return useRoutes([AuthenticationRoutes, ...mainRoutes, ], config.basename);
}
