// project imports
import { gridSpacing } from '@core/actions/constant';
import { openContentPage, openModule } from '@core/actions/course';
import { setCurrentPage } from '@core/actions/customization';
import { setMessage } from '@core/actions/message';
import { updateSideMenuType } from '@core/actions/sideMenuRoute';
import { graphQLConnectionHandler } from '@core/graphql/graphQLConnectionHandler';
import { FETCH_COURSE_PAGES } from '@core/graphql/queries/course';
import { REARRANGE_COURSE_MODULES } from '@core/graphql/queries/courseModule';
import { REARRANGE_COURSE_CONTENT_PAGES, UPDATE_COURSE_PAGE_STATUS } from '@core/graphql/queries/coursePage';
import { SideMenuTypes } from '@core/helpers/Navigations';
import { COURSE } from '@core/helpers/pageRoutings';
import { Edit, Send } from '@mui/icons-material';
// material-ui
import { Grid, Typography } from '@mui/material';
import { useSelectedCourseChanged } from 'hooks/useForceUpdate';
import { useMenuItemChanged } from 'hooks/utilityHooks';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { sideMenuRoutingFactory, useSideMenuRoute } from 'routes/sidemenu';
import DataTableCollapsible from 'ui-component/dataTable/dataTableCollapsible';
import { AdminCoursesSideMenuItems, showUserNotification } from 'utils';
import { ModuleModal } from './modal';

let currentSubElementOrder = [];
let currentParentElementOrder = [];
const CourseContentPortal = () => {

    const { course_uuid } = useParams();
    const onPageLoad = useMenuItemChanged();
    const [isLoading, setLoading] = useState(true);
    const [moduleModalOpen, setModuleModalOpen] = useState(false);
    const selectedCourse = useSelector((state) => state.selectedCourse);

    const [contentModules, setContentModules] = useState([]);
    const [paginatedModules, setPaginatedContentModules] = useState([]);
    const [selectedModule, setSelectedModule] = useState({});
    const [moduleAction, setModuleAction] = useState('CREATE');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [pageNo, setPageNo] = useState(0);
    const [rowsCount, setRowsCount] = useState(5);

    const onSelectedCourseChanged = useSelectedCourseChanged();
    useSideMenuRoute(sideMenuRoutingFactory.courseRoute(course_uuid));

    useLayoutEffect(() => {
        dispatch(setCurrentPage(COURSE));
        dispatch(updateSideMenuType(SideMenuTypes.COURSE));
        onPageLoad(AdminCoursesSideMenuItems.courseContent);
        return () => dispatch(updateSideMenuType(SideMenuTypes.COURSE));

    }, []);


    const loadCourseDetails = () => {
        graphQLConnectionHandler(dispatch, FETCH_COURSE_PAGES({ variables: { course_uuid: course_uuid, showArchives: true } }), (err, res) => {
            if (res?.coursePages == null) {
                showUserNotification('Course pages not found', 'WARN');
            } else {
                setContentModules(res.coursePages.modules);
                paginateData(res.coursePages.modules);
            }
            setLoading(false);
        });
    };

    useEffect(() => {
        onSelectedCourseChanged(course_uuid);
        loadCourseDetails();
    }, []);

    const paginateData = (details, beginIndex = pageNo * rowsCount, rowsPerPage = rowsCount) => {
        if (details.length < (beginIndex + rowsPerPage)) {
            setPaginatedContentModules(details.slice(beginIndex));
        } else {
            setPaginatedContentModules(details.slice(beginIndex, (beginIndex + rowsPerPage)));
        }

    }
    const onPaginateTableData = (pageNo, rowsCount) => {
        paginateData(contentModules, pageNo * rowsCount, rowsCount);
        setPageNo(pageNo);
        setRowsCount(rowsCount);
    }

    const onReArrangeModuleOrder = (data, pageNo, rowsPerPage) => {
        let orderNumbers = data.map(value => value.order);
        let preSortNumbers = [...orderNumbers];
        if (currentParentElementOrder.length != 0 && preSortNumbers.join(',') === currentParentElementOrder.join(',')) {
            return;
        }
        currentParentElementOrder = [...orderNumbers];
        orderNumbers.sort((a, b) => a - b);
        let reOrderData = [];
        for (var i = 0; i < data.length; i++) {
            let moduleData = data[i];
            reOrderData.push({ module_uuid: moduleData.module_uuid, order: orderNumbers[i] });
        }
        reorderInitialLoadedData(data, pageNo * rowsPerPage);
        graphQLConnectionHandler(dispatch, REARRANGE_COURSE_MODULES({ variables: { modules: reOrderData } }), (err, res) => {
            if (res?.reorderCourseModule == null || res.reorderCourseModule.code != 200) {
                showUserNotification(`An error occurred while rearranging modules.\n Please try again later.`, 'WARN');
            } else {
                showUserNotification(`Modules are successfully rearranged.`, 'SUCCESS');
            }
        });
    }

    const reorderInitialLoadedData = (data, beginIndex) => {
        let loadedContentModules = [...contentModules];
        for (var i = 0; i < data.length; i++) {
            loadedContentModules[beginIndex + i] = data[i];
        }
        setContentModules(loadedContentModules);
    }

    const onReArrangeContentPagesOrder = (data) => {
        let orderNumbers = data.map(value => value.page_order);
        let preSortNumbers = [...orderNumbers];
        if (preSortNumbers.join(',') === currentSubElementOrder.join(',')) {
            return
        }
        currentSubElementOrder = [...orderNumbers];
        orderNumbers.sort((a, b) => a - b);
        if (preSortNumbers.join(',') === orderNumbers.join(',')) {
            return;
        }
        let reOrderData = [];
        for (var i = 0; i < data.length; i++) {
            let coursePage = data[i];
            reOrderData.push({ page_uuid: coursePage.page_uuid, page_order: orderNumbers[i] });
        }
        let queryData = REARRANGE_COURSE_CONTENT_PAGES({ variables: { course_pages: reOrderData } });
        graphQLConnectionHandler(dispatch, queryData, (err, res) => {
            if (res?.reorderCourseContentPages == null || res.reorderCourseContentPages.code != 200) {
                showUserNotification(`An error occurred while rearranging course content pages.\n Please try again later.`, 'WARN');
            } else {
                showUserNotification(`Course content pages are successfully rearranged`, 'SUCCESS');
            }
        });
    }

    const onReArrangeChildComponents = (orderedData, event) => {
        let elementID = event?.module_uuid;
        rearrangeChildElements(orderedData, elementID);
        onReArrangeContentPagesOrder(orderedData);
    }

    const rearrangeChildElements = (arrangedData, eventID) => {
        let rearrangedCoursePages = [...contentModules];
        for (let i = 0; i < rearrangedCoursePages.length; i++) {
            if (rearrangedCoursePages[i].module_uuid === eventID) {
                rearrangedCoursePages[i].course_pages = arrangedData;
                break;
            }
        }
        setContentModules(rearrangedCoursePages);
    }

    const headers = {
        "parent": {
            "title": { "name": "Modules", "isData": true },
            "edit": { "name": "Edit", "isData": false }
        },
        "child": {
            "title": { "name": "Lesson", "isData": true },
            "status": { "name": "Status", "isData": false },
            "navigate": { "name": "Navigate", "isData": false }
        }
    }

    const options = {
        "child": "course_pages",
    }

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <Typography variant="h2">{selectedCourse?.course_name}</Typography>
                <DataTableCollapsible rows={paginatedModules} headers={headers} options={options} onDataRearrange={(data, pageNo, rowsPerPage) => {
                    onReArrangeModuleOrder(data, pageNo, rowsPerPage);
                }}
                    totalRecordsCount={contentModules.length}
                    onRearrangeChildData={onReArrangeChildComponents}
                    onPaginateTableContent={(pageNo, rowsCount) => onPaginateTableData(pageNo, rowsCount)}
                    enableRowActions={true}
                    childOption={[{
                        id: "rowAdd",
                        label: "Add Lesson",
                        onTrigger: (data) => {
                            dispatch(openModule(null));
                            dispatch(openContentPage(null));
                            navigate(`/course/content/${course_uuid}/pages/`)
                            dispatch(openModule(data.module_uuid));
                        }
                    }]}
                    enableParentRowActions={true}
                    parentOption={[{
                        id: "rowAdd",
                        label: "Add Module",
                        onTrigger: () => {
                            setModuleAction('CREATE');
                            setModuleModalOpen(true)
                        }
                    }]}
                    rowActions={[{
                        id: 'action-status',
                        label: 'status',
                        value: ['ACTIVE', "DRAFTED"],
                        onTrigger: (action, data) => {

                            let gqlVaribles = {
                                variables: {
                                    page_uuid: data.page_uuid,
                                    status: data.status
                                }
                            };

                            graphQLConnectionHandler(dispatch, UPDATE_COURSE_PAGE_STATUS(gqlVaribles), (error) => {
                                if (!error) {
                                    let actionType = data.status
                                    if (data.status == "ACTIVE") {
                                        actionType = actionType + "D"
                                    }

                                    showUserNotification(
                                        'Course content page has been ' + actionType + ' successfully',
                                        'SUCCESS'
                                    );

                                } else {
                                    setMessage(error.message);
                                }
                            });

                        },
                        type: 'SWITCH',
                    }, {
                        id: 'action-navigate',
                        label: 'Navigate',
                        value: '',
                        onTrigger: (action, data) => {
                            dispatch(openModule(data.module_uuid));
                            dispatch(openContentPage(data.page_uuid));
                            navigate(`/course/content/${course_uuid}/pages/`)
                        },
                        type: 'BUTTON',
                        icon: <Send />
                    }
                    ]}

                    parentRowActions={[{
                        id: 'edit-module',
                        label: 'Edit',
                        value: '',
                        action: 'EDIT',
                        onTrigger: (action, data) => {
                            setSelectedModule(data);
                            setModuleAction('UPDATE');
                            setModuleModalOpen(true)
                        },
                        type: 'BUTTON',
                        icon: <Edit />
                    }]}
                    tableDataFilterPlaceholder="Search Course Pages..."

                />
            </Grid>
            {moduleModalOpen && <ModuleModal
                open={true}
                actionType={"create"}
                courseUUID={course_uuid}
                moduleData={selectedModule}
                action={moduleAction}
                onModuleModalClose={() => setModuleModalOpen(!moduleModalOpen)}
                onModuleRefresh={() => {
                    setModuleModalOpen(false)
                    loadCourseDetails()
                }
                }
            />}
        </Grid>
    );
};

export default CourseContentPortal;
