import { toast } from 'react-toastify';

const defaultToastNotificationConfiguration = {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
}
export const showUserNotification = (message = '', type = "", configs = {}) => {
    configs = { ...defaultToastNotificationConfiguration, ...configs };
    type = type.toUpperCase();
    switch (type) {
        case 'INFO':
            toast.info(message, configs);
            break;
        case 'WARN':
            toast.warn(message, configs);
            break;
        case 'SUCCESS':
            toast.success(message, configs);
            break;
        case 'ERROR':
            toast.error(message, configs);
            break;
        default:
            toast(message, configs);
            break;

    }
}

export const showPromiseNotification = (promise, pendingMsg = 'Your request is processing ...', defaultErrorMsg = 'Something Went wrong', configs = {}) => {
    configs = { ...defaultToastNotificationConfiguration, ...configs };
    toast.promise(promise,
        {
            pending: pendingMsg,
            success: {
                render({ data }) {
                    return data;
                },
            },
            error: {
                render({ error }) {
                    return error ?? defaultErrorMsg;
                }
            }
        }, configs);
}