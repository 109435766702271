import dashboard from './dashboard';
import courseInfo from './pages';
import profileSettings from './profile';
import administrativeSideMenu from './admin'

// ==============================|| MENU ITEMS ||============================== //

const courseSideMenuItems = [dashboard, courseInfo];

export const adminUserSideMenu = [administrativeSideMenu];

export default courseSideMenuItems;
